export function generatePaymentInstructionRequest(billInformation, type, mode, selectedClient, wireInstruction){
    switch(type){
        case 'CHECK': {
            return generateCheckPmtInst(billInformation)
        }
        
        case 'ACH': {
            return generateAchPmtInst(billInformation);
        }

        case 'WIRE': {
            return generateWirePmtInst(billInformation, mode, selectedClient, wireInstruction);
        }

        case 'INVITE': {
            return generateInvitePmtInst(billInformation);
        }

        default :{
            break;
        }
    }
}

function generateCheckPmtInst(billInformation){
    return {
        "vendorParentId": billInformation && billInformation.vendorparentid ? billInformation.vendorparentid : '',
        "description": '',
        "nameOnCheck": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.paytoname ? billInformation.paymentinfo.paytoname: ''
    }
}

function generateAchPmtInst(billInformation){
    
}

function generateWirePmtInst(billInformation, mode, selectedClient, wireInstruction){
    return {
        "Mode": mode,
        "OrgId": selectedClient.orgId,
        "Id": mode === 'UPDATE'? (wireInstruction ? wireInstruction.id : null ) : null,
        "AccNumber": billInformation &&  billInformation.vendorbasics && billInformation.vendorbasics.accountnumberwithvendor ? billInformation.vendorbasics.accountnumberwithvendor : "",
        "AccountType": billInformation &&  billInformation.vendorbasics && billInformation.vendorbasics.vendortypevalue ? billInformation.vendorbasics.vendortypevalue : "0",
        "Name": billInformation &&  billInformation.vendorbasics && billInformation.vendorbasics.vendorname ? billInformation.vendorbasics.vendorname : "",
        "PaymentTermId": billInformation && billInformation.vendorpaytermrefcode ? billInformation.vendorpaytermrefcode: '',
        "wireInstruction": {
          "IsThirdParty": billInformation && billInformation.paymentinfo ? billInformation.paymentinfo.isThirdParty: '',
          "BankAcctFromBankIdType": null, //Not Required
          "BankAcctFromBankId": null,   // Not Required
          "BankAcctFromAcctId": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.accountNumber ? billInformation.paymentinfo.accountNumber : '',
          "BankAcctFromAcctType": null, //Not required
          "BankAcctFromName": null, //Not Required
          "BeneficiaryBankRoutingType": "A",
          "BeneficiaryBankRoutingId": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.routingNumber ? billInformation.paymentinfo.routingNumber :'',
          "BeneficiaryBankName": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.bankName ? billInformation.paymentinfo.bankName :'',
          "BeneficiaryAcctId": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.beneficiaryDetailsVendorname ? billInformation.paymentinfo.beneficiaryDetailsAccountNumber : '',
          "BeneficiaryAcctType": null,
          "BeneficiaryName": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.beneficiaryDetailsVendorname ? billInformation.paymentinfo.beneficiaryDetailsVendorname : '',
          "BeneficiaryNameAddrLine1": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.beneficiaryDetailsVendorname ? billInformation.paymentinfo.beneficiaryDetailsAddressline1 : '',
          "BeneficiaryNameAddrLine2": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.beneficiaryDetailsVendorname ? billInformation.paymentinfo.beneficiaryDetailsAddressline2 : '',
          "BeneficiaryNameAddrLine3": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.beneficiaryDetailsVendorname ? billInformation.paymentinfo.beneficiaryDetailsAddressline3 : '',
          "BeneficiaryNameAddrLine4": null,
          "FurtherCredit1_AccountNumber": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCreditAccountNumber ? billInformation.paymentinfo.furtherCreditAccountNumber: '',
          "FurtherCredit1_NameLine1": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCreditName ? billInformation.paymentinfo.furtherCreditName :"",
          "FurtherCredit1_NameLine2": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCreditAddressLine1 ? billInformation.paymentinfo.furtherCreditAddressLine1 : "",
          "FurtherCredit1_NameLine3": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCreditAddressLine2 ? billInformation.paymentinfo.furtherCreditAddressLine2 : "",
          "FurtherCredit1_NameLine4": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCreditAddressLine3 ? billInformation.paymentinfo.furtherCreditAddressLine3 : "",
          "FurtherCredit2_AccountNumber": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCredit2AccountNumber ? billInformation.paymentinfo.furtherCredit2AccountNumber: '',
          "FurtherCredit2_NameLine1": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCredit2Name ? billInformation.paymentinfo.furtherCredit2Name :"",
          "FurtherCredit2_NameLine2": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCredit2AddressLine1 ? billInformation.paymentinfo.furtherCredit2AddressLine1 : "",
          "FurtherCredit2_NameLine3": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCredit2AddressLine2 ? billInformation.paymentinfo.furtherCredit2AddressLine2 : "",
          "FurtherCredit2_NameLine4": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.furtherCredit2AddressLine3 ? billInformation.paymentinfo.furtherCredit2AddressLine3 : "",
          "WireDisbursementDetail1": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.line1 ? billInformation.paymentinfo.line1 : "",
          "WireDisbursementDetail2": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.line2 ? billInformation.paymentinfo.line2 : "",
          "WireDisbursementDetail3": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.line3 ? billInformation.paymentinfo.line3 : "",
          "WireDisbursementDetail4": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.line4 ? billInformation.paymentinfo.line4 : "",
          "DestinationCountry": billInformation && billInformation.paymentinfo && billInformation.paymentinfo.country ? billInformation.paymentinfo.country : "",
          "DisplayName": ""
        }
    }

}

function generateInvitePmtInst(billInformation){
    
}


export const isVendorNameExist = (name, vendorlist) => {
    let isVendorNameExist = false;

    if(vendorlist && Array.isArray(vendorlist) && vendorlist.length > 0){
        for(let i=0; i< vendorlist.length; i++){
            if(vendorlist[i].name && name && vendorlist[i].name.toLowerCase() === name.toLowerCase()){
                isVendorNameExist = true;
                break;
            }
        }
    }

    return isVendorNameExist;
}