import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Spinner } from 'reactstrap';
import { getSubAccountsGridColumn } from './SubAccountsColumns';
import { MsgForNoData, arrayFilterByValue } from '../../common/constants';
import { getNestedObject } from '../../common/common';
import SubAccountsAddEdit from './subAccountAddEdit';
import {
    getSubAccountListService, getSubAccountListServiceCancellationToken,
    addEditSubAccountService, addEditSubAccountServiceCancellationToken,
    fetchSubAccountCategories, linkSubAccountService,
    linkSubAccountServiceCancellationToken, unlinkSubAccountService,
    unlinkSubAccountServiceCancellationToken, validateABA, validateABACancellationToken, setContextForSourceDataTransactions
} from '../../services/accountingServices/accountingService';
import ConfirmationPopup from './confirmationPopup';
import { animateViewTable } from '../../common/animation';
import ClientListPopup from './clientListPopup';
import { BackTop, message } from 'antd';
import { UpdateSelectedSubAccount } from '../../actions/accountingActions';
const SubAccounts = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [activeAccount, setActiveAccount] = useState({});

    const [mode, setMode] = useState("");
    const [showNinthWaveWidget, setShowNinthWaveWidget] = useState(false);
    const [openNinthwaveModelPopup, setOpenNinthwaveModelPopup] = useState(false);
    const [disableLinkedAccount, setDisableLinkedAccount] = useState(false);
    const [subAccountsList, setSubAccountsList] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [filterKey, setFilterKey] = useState("");
    const [categoryLevel1Type, setCategoryLevel1Type] = useState([]);
    const [categoryLevel2Type, setCategoryLevel2Type] = useState([]);
    const [activeClient, setActiveClient] = useState({});
    const [isLinkAccountDisabled, setIsLinkAccountDisabled] = useState(true);

    const [openConfirmationModelPopup, setOpenConfirmationModelPopup] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationPopupSource, setConfirmationPopupSource] = useState('');
    const [changedCategoryInfo, setChangedCategoryInfo] = useState({});
    const [deleteAccountKey, setDeleteAccountKey] = useState('');
    const [bankRoutingNumberErrorMsg, setBankRoutingNumberErrorMsg] = useState('');
    const [isClientListPopupOpen, setIsClientListPopupOpen] = useState(false);
    const [contextKey, setContextKey] = useState('')
    const [openTransactionPopup, setOpenTransactionPopup] = useState(false);
    //useEffect to listen Event Listner for ninth wave account
    const dispatch = useDispatch(); 
    const features = useSelector(state => getNestedObject(state,['app','faFeatures']))
    const selectedClient = useSelector(state => getNestedObject(state, ['dashboard', 'selectedClient']));

    useEffect(() => {
        setActiveClient(prevActiveClient => { return { ...selectedClient } });
        window.addEventListener('NW_ENROLLED_ACCTS', handleNinthWaveAccountAdd);
        return () => {
            window.removeEventListener('NW_ENROLLED_ACCTS', handleNinthWaveAccountAdd);
            if (getSubAccountListServiceCancellationToken !== null && getSubAccountListServiceCancellationToken !== undefined) {
                getSubAccountListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (addEditSubAccountServiceCancellationToken !== null && addEditSubAccountServiceCancellationToken !== undefined) {
                addEditSubAccountServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (linkSubAccountServiceCancellationToken !== null && linkSubAccountServiceCancellationToken !== undefined) {
                linkSubAccountServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (unlinkSubAccountServiceCancellationToken !== null && unlinkSubAccountServiceCancellationToken !== undefined) {
                unlinkSubAccountServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (validateABACancellationToken !== null && validateABACancellationToken !== undefined) {
                validateABACancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }

        }
    }, []);
    useEffect(() => {

        //call api and set categories
        fetchSubAccountCategories().then(res => {
            //let item = { name: '', code: '', canAggregate: 'N' };

            setCategoryLevel1Type([...res.category1]);
            setCategoryLevel2Type([...res.category2]);
        })
            .catch(err => {
                console.error(err);
            })

    }, []);
    useEffect(() => {
        if (activeClient && activeClient.pKid) {
            getSubAccountList();
            setFilterKey(pervFilterKey => "");
        }
    }, [activeClient]);
    useEffect(() => {
        if (activeAccount.isSave || activeAccount.delink) {
            save();
        } else
            checkRequiredFields();
    }, [activeAccount]);

    useEffect(() => {
        setActiveClient(prevActiveClient => { return { ...selectedClient } });
    }, [selectedClient]);

    const getSubAccountList = () => {
        setShowOverlay(prevShowOverlay => true);
        setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => '');
        getSubAccountListService(activeClient.pKid).then(res => {
            
            //console.log("SubaccountsList: " + JSON.stringify(res));
            if (res.status.code === '000000') {
                if (res && res.subAcctList && res.subAcctList && res.subAcctList !== null && Array.isArray(res.subAcctList)) {
                    let data = res.subAcctList;
                    setSubAccountsList(data);
                    setGridData(data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get account list, you can try again.");
                console.error("Error Occured while fetching account");
            }
            setShowOverlay(prevShowOverlay => false);

        })
            .catch(err => {
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable to get account list, you can try again.");
                console.error("Error Occured while fetching account");
            })
    }
    const handleFilter = (e) => {
        let value = e.target.value
        let filteredAcct = arrayFilterByValue(subAccountsList, value);
        setFilterKey(pervFilterKey => value);
        setGridData(filteredAcct);
    }
    const clearFilter = () => {
        let filteredAcct = arrayFilterByValue(subAccountsList, "");
        setFilterKey(pervFilterKey => "");
        setGridData(filteredAcct);
    }
    const handleNinthWaveAccountAdd = useCallback((event) => {
        //console.log("event data: " + JSON.stringify(event));
        if (event && event.detail && event.detail.accounts && event.detail.accounts !== undefined
            && event.detail.accounts.length !== 0) {
            let account = (event && event.detail && event.detail.accounts && event.detail.accounts && event.detail.accounts.length > 0 &&
                event.detail.accounts[0]) ? event.detail.accounts[0] : {};
            let reqJson = {
                "profileid": "",
                "aggregatedAccounts": [{
                    "number": (account && account.fullAccountNumber) ? window.btoa(account.fullAccountNumber) : "",
                    "key": (account && account.accountKey) ? account.accountKey : "",
                    "status": (account && account.accountStatus) ? account.accountStatus : "",
                    "accttype": (account && account.accountType) ? account.accountType : "",
                    "routingTransitNumber": (account && account.routingTransitNumber) ? account.routingTransitNumber : "",
                    "nickname": (account && account.accountNumber) ? window.btoa(account.accountNumber) : "",
                    "finame": (event && event.detail ) ? event.detail.fiName : '',
                    "fiid": (event && event.detail) ? event.detail.fiid : '',
                }]
            };
            linkSubAccountService(reqJson).then(res => {
                if (res.value !== 0) {
                    setActiveAccount(prevActiveAccount => {
                        return {
                            ...prevActiveAccount, bankName: event.detail.fiName,
                            financialInstitutionKey: event.detail.fiid, bankAccount: account.fullAccountNumber,
                            accountId: parseInt(res.value), isAggregated: 'Y', isSave: true, bankRoutingNumber: '', accountKey: account.accountKey
                        }
                    });
                } else {
                    animateViewTable();
                    message.error("We are unable to link account, you can try again.");
                }
            })
                .catch(err => {
                    animateViewTable();
                    message.error("We are unable to link account, you can try again.");

                    console.error("Error Occured while linking account");

                })
        } else {
            animateViewTable();
            message.error("We are unable to enroll account, you can try again.");
        }
        setOpenNinthwaveModelPopup(prevOpenNinthwaveModelPopup => !prevOpenNinthwaveModelPopup);
        setDisableLinkedAccount(true);

    }, []);
    const toggleNinthwaveModelPopup = () => {
        setOpenNinthwaveModelPopup(prevOpenNinthwaveModelPopup => !prevOpenNinthwaveModelPopup)
    }
    const toggleClientListPopup = () => {
        setIsClientListPopupOpen(prevIsClientListPopupOpen => !prevIsClientListPopupOpen);
    }
    const toggleTransactionsModalPopup = () => {
        setOpenTransactionPopup(prevOPenTransactionPopup => !prevOPenTransactionPopup);
    }
    const openConfirmationPopup = (source) => {
        let confirmationMessage = "";

        switch (source) {
            case "ninthwave-link": {
                if ((activeAccount.bankName !== null && activeAccount.bankName !== "") || (activeAccount.bankAccount !== null && activeAccount.bankAccount !== "")) {
                    confirmationMessage = 'The Custodian/Bank and the Account # will be replaced with the linked account information, are you sure?';
                    setConfirmationMessage(prevConfirmationMessage => confirmationMessage);
                    setOpenConfirmationModelPopup(prevOpenConfirmationModelPopup => true);
                    setConfirmationPopupSource(prevConfirmationPopupSource => source);
                } else {
                    setOpenNinthwaveModelPopup(prevOpenNinthwaveModelPopup => true);
                }
                break;
            }

            case "ninthwave-delink": {
                confirmationMessage = 'Are you sure you want to unlink the account? This will story transaction feeds from the linked account. It does not impact previous transactions received';
                setConfirmationMessage(prevConfirmationMessage => confirmationMessage);
                setOpenConfirmationModelPopup(prevOpenConfirmationModelPopup => true);
                setConfirmationPopupSource(prevConfirmationPopupSource => source);
                break;
            }
            case "categorylevel2-change": {
                confirmationMessage = 'You have a linked account into this custodian, are you sure you want to break the link';
                setConfirmationMessage(prevConfirmationMessage => confirmationMessage);
                setOpenConfirmationModelPopup(prevOpenConfirmationModelPopup => true);
                setConfirmationPopupSource(prevConfirmationPopupSource => source);
                break;
            }
        }


    }
    const toggleConfirmationModelPopup = () => {
        setConfirmationMessage(prevConfirmationMessage => '');
        setOpenConfirmationModelPopup(prevOpenConfirmationModelPopup => !prevOpenConfirmationModelPopup);
        setConfirmationPopupSource(prevConfirmationPopupSource => '');
    }
    const confirmAction = (source) => {
        setOpenConfirmationModelPopup(prevOpenConfirmationModelPopup => !prevOpenConfirmationModelPopup);
        if (source === 'ninthwave-link') {
            setOpenNinthwaveModelPopup(prevOpenNinthwaveModelPopup => true);
        } else if (source === 'ninthwave-delink' || source === 'categorylevel2-change') {
            setDeleteAccountKey(prevDeleteAccountKey => activeAccount.accountKey);
            unlinkSubAccount(source);
        }
    }
    const unlinkSubAccount = (source) => {
        //setDeleteAccountKey(prevDeleteAccountKey => activeAccount.accountKey);
        if (source === 'categorylevel2-change') {
            setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
            setActiveAccount(prevActiveAccount => {
                return {
                    ...prevActiveAccount, bankName: '', financialInstitutionKey: '', bankAccount: '', accountKey: '',
                    accountId: null, isAggregated: 'N', delink: true, bankRoutingNumber: '', categoryCode2: changedCategoryInfo.name
                }
            });
        } else {
            setActiveAccount(prevActiveAccount => {
                return {
                    ...prevActiveAccount, bankName: '', financialInstitutionKey: '', bankAccount: '',
                    accountId: null, isAggregated: 'N', delink: true, bankRoutingNumber: '', accountKey: ''
                }
            });
        }
    }
    const editSubAccount = row => {
        setActiveAccount(prevActiveAccount => { return { ...row } });
        setMode("edit");
        let bankType = categoryLevel2Type.filter(category => category.name === row.categoryCode2);
        if (bankType && bankType.length > 0 && bankType[0].canAggregate === 'Y')
            setShowNinthWaveWidget(prevShowNinthWaveWidget => true);
        else
            setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
        setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => '');
    }

    const handleTransactions = row => {
        setActiveAccount(prevActiveAccount => { return { ...row } });
        updateSubAccount(row);
        props.history.push(`${props.matchUrl}/subAccount/transactions`);
    }

    const updateSubAccount = useCallback(
        (row) => dispatch(UpdateSelectedSubAccount(row)),
        [dispatch]
    )
    const cancel = () => {
        setActiveAccount({});
        setMode("");
        setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
        setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => '');
    }
    const save = () => {
        setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => '');
        if (activeAccount.delink !== undefined && !activeAccount.delink) {
            setShowOverlay(prevShowOverlay => true);
        }
        let reqJson = { ...activeAccount };
        if (activeAccount.shortName === null) {
            reqJson.shortName = '';
        }
        addEditSubAccountService(reqJson).then(res => {

            if (res && res.status && res.status.code && res.status.code === "000000") {
                if (activeAccount.delink) {
                    deLinkSubAccount();
                    getSubAccountList();
                } else if (activeAccount.isSave) {
                   
                    setActiveAccount(prevActiveAccount => {
                        return { ...prevActiveAccount, delink: false, isSave: false }
                    });
                    getSubAccountList();
                } else {
                    getSubAccountList();
                    setActiveAccount({});
                    setMode(prevMode => "");
                    setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
                }

            } else {
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable save account, you can try again.");
            }
        })
            .catch(err => {
                console.error(err);
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable save account, you can try again.");
            })

    }

    //const setContext = (key) => {
    //    setShowOverlay(prevShowOverlay => true);
    //    setContextForSourceDataTransactions(key).then(res => {
    //        console.log("response="+res)
    //        if (res && res.status && res.status=== 200) {
    //            console.log(res);
    //            setShowOverlay(prevShowOverlay => false);
    //            setContextKey(res.data);
    //        } else {
    //            setShowOverlay(prevShowOverlay => false);
    //            animateViewTable();
    //            message.error("We are unable to set Context, you can try again.");
    //        }
    //    })
    //        .catch(err => {
    //            console.error(err);
    //            setShowOverlay(prevShowOverlay => false);
    //            animateViewTable();
    //            message.error("We are unable to set Context, you can try again.");
    //        })

    //}
    const deLinkSubAccount = () => {

        let reqJson = {
            "extassetaggregationid": "f9b43c74-3d16-416b-906c-0895e24947a0",
            "accountkey": (deleteAccountKey),
            "isremove": true
        }
        unlinkSubAccountService(reqJson).then(res => {
            if (res && res.data && res.data.value && res.data.value.length > 0 && res.data.value[0].status && res.data.value[0].status.code === "0") {
                //setActiveAccount(prevActiveAccount => {
                //    return { ...prevActiveAccount, delink: false }
                //});
                if (confirmationPopupSource === "categorylevel2 - change") {
                    setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
                    setConfirmationPopupSource(prevConfirmationPopupSource => "");
                } else {
                    setConfirmationPopupSource(prevConfirmationPopupSource => "");
                }
                setDeleteAccountKey(prevDeleteAccountKey => '');
                getSubAccountList();
            } else {
                animateViewTable();
                message.error("We are unable to unlink account, you can try again.");
                console.error("Error Occured while unlinking account");
            }
        })
            .catch(err => {
                animateViewTable();
                message.error("We are unable to unlink account, you can try again.");
                console.error("Error Occured while unlinking account");
            })
        setActiveAccount(prevActiveAccount => {
            return { ...prevActiveAccount, delink: false, isSave: false }
        });
    }
    const add = () => {
        setMode("add");
        setActiveAccount({
            //id: '',
            shortName:'',
            orgId: activeClient.orgId,
            pKid: activeClient.pKid,
            number: '',
            name: '',
            //description: '',
            categoryCode1: '',
            categoryCode2: '',
            canaggregate: 'N',
            bankName: '',
            financialInstitutionKey: '',
            bankAccount: '',
            accountId: null,
            isAggregated: 'N',
            accountKey: '',
            bankRoutingNumber: ''
        });
        setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
    }
    const handleFormChanges = evt => {
        let value = evt.target.value;
        if (evt.target.name === "name") {
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, name: value } });
            //} else if (evt.target.name === 'description') {
            //    setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, description: value } });
        } else if (evt.target.name === 'number') {
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, shortName: value } });
        } else if (evt.target.name === 'custodianname') {
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankName: value } });
        } else if (evt.target.name === 'linkedaccountnumber') {
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankAccount: value } });

        } else if (evt.target.name === 'bankRoutingNumber') {
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankRoutingNumber: value } });
        }

    }
    const handleDropdownChange = (category, source) => {
        if (source === 'categorylevel1') {
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, categoryCode1: category.name } });

        } else if (source === 'categorylevel2') {
            if (activeAccount.isAggregated === 'Y') {
                let changedBankType = categoryLevel2Type.filter(categoryType => categoryType.name === category.name);
                if ((changedBankType && changedBankType.length > 0 && changedBankType[0].canAggregate === 'N') &&
                    ((activeAccount.bankName !== null && activeAccount.bankName !== "") || (activeAccount.bankAccount !== null && activeAccount.bankAccount !== ""))) {
                    setChangedCategoryInfo({ ...category });
                    openConfirmationPopup('categorylevel2-change');
                } else {
                    setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, categoryCode2: category.name } });
                    if (category.canAggregate === 'Y') {
                        setShowNinthWaveWidget(prevShowNinthWaveWidget => true);

                    } else {
                        setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
                    }
                }
            } else {
                setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, categoryCode2: category.name } });
                if (category.canAggregate === 'Y') {
                    setShowNinthWaveWidget(prevShowNinthWaveWidget => true);

                } else {
                    setShowNinthWaveWidget(prevShowNinthWaveWidget => false);
                }
            }
        }
    }

    const checkRequiredFields = () => {
        if (showNinthWaveWidget === true && (activeAccount.id !== null && activeAccount.id !== '')
            //&& (activeAccount.shortName !== null || activeAccount.shortName !== '')
            && (activeAccount.name !== null && activeAccount.name !== '')) {
            setIsLinkAccountDisabled(prevIsLinkAccountDisabled => false);
        } else {
            setIsLinkAccountDisabled(prevIsLinkAccountDisabled => true);
        }
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        onSelect: (row) => editSubAccount(row)
    };
    const goToDashBoard = () => {
        props.history.push('/');
    }
    const getBankName = (evt) => {
        //let errorMsg = "";
        setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => '');
        if (evt.target.value !== undefined
            && evt.target.value !== "") {
            let inputABA = evt.target.value;
            //this.setState({ isLoading: true }, () => {
            validateABA(inputABA)
                .then(data => {
                    if (data.ABANumbers !== null
                        && data.ABANumbers !== undefined
                        && Array.isArray(data.ABANumbers)
                        && data.ABANumbers.length > 0) {
                        let bankname = "";
                        for (let aba of data.ABANumbers) {
                            if (aba.BankCode !== null && aba.BankCode !== undefined && aba.BankCode === inputABA) {
                                if (aba.BankName !== null && aba.BankName !== undefined) {
                                    bankname = aba.BankName;
                                    setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankName: bankname } });
                                    //setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => '');
                                }
                                break;
                            }
                        }

                        //check if we did not receive any bank name
                        if (bankname === "") {

                            setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => "Please provide valid ABA Routing number");
                            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankName: "" } });
                        }
                    } else {
                        setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => "Please provide valid ABA Routing number");
                        setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankName: "" } });
                    }
                })
                .catch(error => {
                    setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => "Please provide valid ABA Routing number");
                    setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankName: "" } });

                });
            // });
        } else {
            setBankRoutingNumberErrorMsg(prevBankRoutingNumberErrorMsg => "Please provide valid ABA Routing number");
            setActiveAccount(prevActiveAccount => { return { ...prevActiveAccount, bankName: "" } });
        }

    }

    return (
        <>
            <div className="RCM_Toast RCM_subAccount_toast">
                {
                    //showOverlay && (<OverlayProcessing />)
                    showOverlay ? <div align="center" className="mt-4">Loading Sub Accounts
                                        &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                    </div>
                        : <>
                            <BackTop />
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_subAccounts_subHeading">
                                    Select {
                                        activeClient === undefined || activeClient === null || activeClient === "" || activeClient.pKid === undefined ? " a " : ' another '}
                                    <span className="RCM_Table_link" onClick={() => toggleClientListPopup()}>client</span>.
                                </div>
                            </div>
                            {
                                activeClient === undefined || activeClient === null || activeClient === "" || activeClient.pKid === undefined
                                    ? <></>
                                    :

                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                                            
                                            <div className="RCM_subAccounts_subHeading">
                                                LIST OF SUB ACCOUNTS - {activeClient && activeClient.name}
                                            </div>  
                                            <div className="RCM_Rules_Search_wrapper" >
                                                <input
                                                    type="text"
                                                    className="RCM_form_control RCM_form_control_search"
                                                    placeholder="Search for Sub Account"
                                                    onChange={(e) => handleFilter(e)}
                                                    value={filterKey}

                                                />
                                                <button className="RCM_Textbox_close-icon"
                                                    type="reset"
                                                    onClick={() => clearFilter()}
                                                ></button>
                                            </div> 
                                            <div>
                                                {
                                                    subAccountsList ?
                                                        <div id="RCM_SubAccounts_Table" className="RCM_SubAccounts_Table RCM_table_scorllable_body">
                                                            <BootstrapTable
                                                                keyField='id'
                                                                data={gridData}
                                                                columns={getSubAccountsGridColumn(editSubAccount, add, handleTransactions, features)}
                                                                headerClasses="row-bg"
                                                                bordered={false}
                                                                classes="RCM_two_level_table1"
                                                                rowClasses="RCM_tow_level_table1_row_animation"
                                                                noDataIndication={MsgForNoData}
                                                                selectRow={selectRow}
                                                            />
                                                        </div>
                                                        : <div key="ASDropdown22">{MsgForNoData}</div>

                                                }
                                            </div>


                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8">
                                            <SubAccountsAddEdit
                                                activeAccount={activeAccount}
                                                mode={mode}
                                                showNinthWaveWidget={showNinthWaveWidget}
                                                categoryLevel1Type={categoryLevel1Type}
                                                categoryLevel2Type={categoryLevel2Type}
                                                isLinkAccountDisabled={isLinkAccountDisabled}
                                                handleFormChanges={handleFormChanges}
                                                handleDropdownChange={handleDropdownChange}
                                                cancel={cancel}
                                                save={save}
                                                openNinthwaveModelPopup={openNinthwaveModelPopup}
                                                toggleNinthwaveModelPopup={toggleNinthwaveModelPopup}
                                                setOpenNinthwaveModelPopup={setOpenNinthwaveModelPopup}
                                                disableLinkedAccount={disableLinkedAccount}
                                                openConfirmationPopup={openConfirmationPopup}
                                                getBankName={getBankName}
                                                // bankRoutingNumber={bankRoutingNumber}
                                                bankRoutingNumberErrorMsg={bankRoutingNumberErrorMsg}

                                            />
                                        </div>

                                    </div>
                            }
                        </>
                }
            </div>
            <ConfirmationPopup
                openConfirmationModelPopup={openConfirmationModelPopup}
                toggleConfirmationModelPopup={toggleConfirmationModelPopup}
                confirmationMessage={confirmationMessage}
                confirmationPopupSource={confirmationPopupSource}
                confirmAction={confirmAction}
            />
            <ClientListPopup
                isClientListPopupOpen={isClientListPopupOpen}
                toggleClientListPopup={toggleClientListPopup}
            />
           
        </>
    )

};

export default  SubAccounts;