import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';
import PostingRules from './postingRules';
import PostingRulesAllClients from './postingRulesAllClients';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

class PostingRulesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ActiveKey: '1'
        }

        this.onTabChanges = this.onTabChanges.bind(this);
    }

    //getActiveTab = () => {
    //    const currentRoute = this.props.history.location.pathname;
    //    let activeKey = "1";
    //    if (currentRoute === `${this.props.match.url}` || currentRoute === `${this.props.match.url}/allclientpayees`)
    //        activeKey = "1";
    //    else if (currentRoute === `${this.props.match.url}/specificclientpayees`)
    //        activeKey = "2";
    //    return activeKey;
    //}

    onTabChanges(activetabKey) {
       
        this.setState(prevState => ({
            ActiveKey: activetabKey
        }));

    }

    render() {
     
        return (
            <div>
                <div>
                    <Tabs defaultActiveKey={this.state.ActiveKey} animated={false} onChange={this.onTabChanges} type="card">
                        <TabPane tab="ALL CLIENTS" key="1">
                            <PostingRulesAllClients ActiveKey={this.state.ActiveKey} history={this.props.history} />
                        </TabPane>
                        <TabPane tab="CLIENT SPECIFIC" key="2">
                            <PostingRules ActiveKey={this.state.ActiveKey} history={this.props.history} />
                        </TabPane>
                       
                    </Tabs>
                  
                </div>

            </div>
        )
    }
}

export default withRouter(PostingRulesContainer);


