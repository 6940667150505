import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import SourceData from './sourceData';
import AccountingData from './accountingData';
import { getNestedObject } from '../../../common/common';
const { TabPane } = Tabs;
class Transactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ActiveKey:"1"
        }

        this.onTabChanges = this.onTabChanges.bind(this);
        this.goBack = this.goBack.bind(this);
        //this.getActiveTab = this.getActiveTab.bind(this);
    }
    goBack = () => {
        this.props.history.push(`${this.props.matchUrl}/subAccount`);
    }

    onTabChanges(activetabKey) {
        this.setState(prevState => ({
            ActiveKey: activetabKey
        }));

    }

    render() {
        return (
            <>
                {
                    <div className="RCM_Toast RCM_subAccount_toast">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label class="RCM_uploadInvoice_label">TRANSACTIONS</label>
                            </div>

                        </div>

                        {this.props.selectedClient && this.props.selectedClient.name && this.props.selectedSubAccount && this.props.selectedSubAccount.accountKey ?
                            <div className="row mt-2">
                                <div className="col-sm-10 col-xs-10 col-md-10 col-lg-10">
                                    <label for="type" className="RCM_uploadInvoice_label">{"Client Name : " + (this.props.selectedClient && this.props.selectedClient.name)}</label>
                                    <label for="type" className="RCM_uploadInvoice_label">{"Sub Account : " + (this.props.selectedSubAccount && this.props.selectedSubAccount.name + ' (' + (this.props.selectedSubAccount && this.props.selectedSubAccount.shortName)) + ')'}</label></div>
                                <div className="col-sm-2 col-xs-2 col-md-2 col-lg-2" align=" right">
                                    <div className="RCM_billpay_back_btn_container" align="right">
                                        <button type="button" className="RCM_billpay_btn" onClick={() => this.goBack()}>BACK</button>
                                    </div>
                                </div> </div>
                            :
                            <div className="row ">
                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                    <div className="RCM_billpay_back_btn_container" align="right">
                                        <button type="button" className="RCM_billpay_btn" onClick={() => this.goBack()}>BACK</button>
                                    </div></div></div>}

                        {this.props.selectedClient && this.props.selectedClient.name && this.props.selectedSubAccount && this.props.selectedSubAccount.accountKey ?
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <Tabs defaultActiveKey={this.state.activeKey} animated={false} onChange={this.onTabChanges}>
                                        <TabPane tab="SOURCE DATA" key="1">
                                            <SourceData ActiveKey={this.state.activeKey} />
                                        </TabPane>
                                        <TabPane tab="ACCOUNTING DATA" key="2">
                                            <AccountingData ActiveKey={this.state.activeKey} />
                                    </TabPane>

                                    </Tabs>
                                </div>
                            </div> : ''}
                    </div>
                }


            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        selectedClient: state.dashboard.selectedClient,
        selectedSubAccount: state.accounting.selectedSubAccount,
    };
};
const enhancement = compose(
    connect(mapStateToProps)
)
export default enhancement(Transactions);
//const Transactions = (props) => {
//    const [activeClient, setActiveClient] = useState({});
//    const [activeKey, setActiveKey] = useState("1");
//    const [selectedClient, setSelectedClient] = useState({ name: "test"})
//    const [selectedSubAccount, setSelectedSubAccount] = useState({ accountKey: "1234" })
//   // const user = useSelector(state => getNestedObject(state, ['app', 'user']));
//    //const selectedClient = useSelector(state => getNestedObject(state, ['dashboard', 'selectedClient']));
//    //const selectedSubAccount = useSelector(state => getNestedObject(state, ['accounting', 'selectedSubAccount']));

//    const onTabChanges = activetabKey => {
//        setActiveKey(prevActiveKey => activetabKey);
//    }

//    //useEffect(() => {
//    //    setActiveClient(prevActiveClient => { return { ...selectedClient } });
//    //}, []);
//    const goBack = () => {
//       props.history.push('/subAccount');
//    }
//    return (
//        <>
//            {
//                <div className="RCM_Toast">
//                    <div className="row">
//                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                            <label class="RCM_tile_heading RCM_details_heading RCM_Toast_heading RCM_transaction_heading">Transactions</label>
//                        </div>

//                    </div>

//                    {selectedClient && selectedClient.name && selectedSubAccount && selectedSubAccount.accountKey ?
//                        <div className="row ">
//                            <div className="col-sm-8 col-xs-8 col-md-8 col-lg-8">
//                                <label for="type" className="RCM_uploadInvoice_label">Client Name:</label>
//                                <label for="type" className="RCM_uploadInvoice_label">{selectedClient && selectedClient.name}</label>
//                                <label for="type" className="RCM_uploadInvoice_label">Sub Account:</label>
//                                <label for="type" className="RCM_uploadInvoice_label">{selectedSubAccount && selectedSubAccount.accountKey}</label> </div> 
//                        <div className="col-sm-4 col-xs-4 col-md-8 col-lg-4" align=" right">
//                            <div className="RCM_billpay_back_btn_container" align="right">
//                                <button type="button" className="RCM_billpay_btn" onClick={()=>goBack()}>BACK</button>
//                            </div>
//                            </div> </div>
//                        :
//                        <div className="row ">
//                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
//                                <div className="RCM_billpay_back_btn_container" align="right">
//                                    <button type="button" className="RCM_billpay_btn" onClick={() => goBack()}>BACK</button>
//                                </div></div></div>}

//                        {selectedClient && selectedClient.name && selectedSubAccount && selectedSubAccount.accountKey ?
//                        <div className="row">
//                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                                <Tabs defaultActiveKey={activeKey} animated={false} onChange={onTabChanges}>
//                                    <TabPane tab="SOURCE DATA" key="1">
//                                        <SourceData ActiveKey={activeKey}  />
//                                    </TabPane>
//                                    <TabPane tab="ACCOUNTING DATA" key="2">
//                                        <AccountingData ActiveKey={activeKey}  />)} />
//                                    </TabPane>

//                                </Tabs>
//                            </div>
//                        </div>:''}
//                    </div>
//                    }


//        </>
//    )
//}
//export default  Transactions;