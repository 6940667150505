import React, { useState } from 'react';
import { Form, FormGroup, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";

const PostingRulesView = ({ activeRule, mode, toggleAddEditPopup, save, activeClient, isAddEditOpen, handleFormChanges, handleDropdownChange,
    toggleSelectValuesPopup}) => {
    const [isContainsOpen, setIsContainsOpen] = useState(false);
    //const [isTransactionTypeOpen, setIsTransactionTypeOpen] = useState(false);

    const containsOptions = [
         'Contains',
         'Exact Match'
    ];
    
    return (
        <>
            <Modal isOpen={isAddEditOpen} toggle={toggleAddEditPopup}
                className="RCM_CM_modal RCM_Posting_rules_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                zIndex='9999'
                centered
                size='lg'>
                <ModalHeader toggle={toggleAddEditPopup} className="RCM_externalAssetModel_header RCM_manage_group_header">

                    <label className="RCM_tile_heading">{mode === 'add' ? 'ADD ' : 'EDIT '} RULE</label>
                </ModalHeader>
                <ModalBody>
                    <>
                        <div>
                            <Form>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <label className="RCM_Rules_label">Selected Client: {activeClient && activeClient.name}</label>
                                    </div>
                                </div>

                                <div className="row RCM_rule_section">
                                    <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                                        <FormGroup className="RCM_Rules_FormGroup">
                                            <label className="RCM_Rules_label">Rule Name</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="rulename"
                                                className="RCM_form_control RCM_Rules_control"
                                                value={activeRule.rulename}
                                                onChange={evt => handleFormChanges(evt)}

                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5">
                                        <FormGroup className="RCM_Rules_FormGroup RCM_rules_lbl_fields_wrapper">
                                            <div>
                                                <label className="RCM_Rules_label">Institution</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="rulename"
                                                    className="RCM_form_control RCM_Rules_control RCM_Rules_control_text RCM_Rules_control_notDisabled"
                                                    value={activeRule.institutionname}
                                                    disabled
                                                />
                                                
                                            </div>
                                            <div className="RCM_rules_lbl_fields_btn_wrapper">
                                                <button type="button" className="RCM_billpay_btn" onClick={() => toggleSelectValuesPopup('Institution')}>SELECT</button>
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                                        <FormGroup className="RCM_Rules_FormGroup RCM_rules_lbl_fields_wrapper">
                                            <div>
                                            <label className="RCM_Rules_label">Source Main Account</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="sourcecoaaccountno"
                                                    className="RCM_form_control RCM_Rules_control RCM_Rules_control_text RCM_Rules_control_notDisabled"
                                                    value={activeRule.sourcecoaaccountno + (activeRule.sourcecoaaccountname !== undefined? (" - "+ activeRule.sourcecoaaccountname):'')}
                                                onChange={evt => handleFormChanges(evt)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="RCM_rules_lbl_fields_btn_wrapper">
                                                <button type="button" className="RCM_billpay_btn" onClick={() => toggleSelectValuesPopup('MainAccounts','SourceMainAccount')}>SELECT</button>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row RCM_rule_section">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <FormGroup className="RCM_Rules_FormGroup">
                                            <label className="RCM_selectLabel RCM_rule_checkbox RCM_Rules_label">
                                                <input type="checkbox"
                                                    name="isTransactionType"
                                                    checked={activeRule.isTransactionType ? activeRule.isTransactionType : false}
                                                    onChange={evt => handleFormChanges(evt)} />
                                                <span className="profile-font-color"></span>
                                            </label>
                                            <label className="RCM_Rules_label">Transaction Type</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="transactionname"
                                                className={"RCM_form_control RCM_Rules_control RCM_Rules_control_text" + (activeRule.isTransactionType ? " RCM_Rules_control_notDisabled" : '')}
                                                value={activeRule.transactionname && activeRule.transactioncode && activeRule.transactionname !== null && activeRule.transactioncode !== null
                                                    ? (activeRule.transactioncode + ' : ' + activeRule.transactionname)
                                                    : ((activeRule.vendortrantypeid && activeRule.vendortrantypeid !== null) ? activeRule.vendortrantypeid : '')}
                                                disabled
                                            />
                                                
                                            
                                        <div className="RCM_rules_lbl_fields_btn_wrapper">
                                                <button type="button" className="RCM_billpay_btn" onClick={() => toggleSelectValuesPopup('Transaction')} disabled={!activeRule.isTransactionType}>SELECT</button>
                                        </div>
                                            {/*<label className="RCM_Rules_label">Transaction Type</label>
                                            <Dropdown name="contains" required
                                                isOpen={isTransactionTypeOpen}
                                                toggle={() => setIsTransactionTypeOpen(prevIsTransactionTypeOpen => (activeRule.isTransactionType) ? !prevIsTransactionTypeOpen : prevIsTransactionTypeOpen)}

                                                className={"RCM_form_control RCM_select_box RCM_mm_select_box RCM_Rules_control" + (activeRule.isTransactionType ? '' : ' RCM_link_disabled')}>
                                                <DropdownToggle caret>{activeRule.transactionname && activeRule.transactioncode && activeRule.transactionname !== null && activeRule.transactioncode !== null
                                                    ? (activeRule.transactioncode + ' : ' + activeRule.transactionname)
                                                    : ((activeRule.vendortrantypeid && activeRule.vendortrantypeid !== null) ? activeRule.vendortrantypeid : '')}</DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        <div>
                                                            {transactions && transactions !== null && transactions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => handleDropdownChange(item, 'transaction')}>
                                                                    <div>{item.trancode + ' : ' +item.tranname}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    }
                                                </DropdownMenu>
                                            </Dropdown>*/}
                                        </FormGroup>

                                    </div>
                                </div>
                                <div className="row RCM_rule_section">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <label className="RCM_selectLabel RCM_rule_checkbox RCM_Rules_label">
                                            <input type="checkbox"
                                                name="iscondition"
                                                checked={activeRule.iscondition}
                                                onChange={evt => handleFormChanges(evt)} />
                                            <span className="profile-font-color"></span>
                                        </label>
                                        <label className="RCM_Rules_label">CONDITION</label>
                                    </div>

                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <FormGroup className="RCM_Rules_FormGroup">
                                            <label className="RCM_Rules_label">Transaction description</label>
                                            <Dropdown name="contains" required
                                                isOpen={isContainsOpen}
                                                toggle={() => setIsContainsOpen(prevIsContainsOpen => (activeRule.iscondition) ? !prevIsContainsOpen : prevIsContainsOpen)}

                                                className={"RCM_form_control RCM_select_box RCM_mm_select_box RCM_Rules_control" + (activeRule.iscondition ? '' :' RCM_link_disabled')}>
                                                <DropdownToggle caret>{activeRule.condition !== null ? activeRule.condition :''}</DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        <div>
                                                            {containsOptions && containsOptions !== null && containsOptions.map((item, index) =>
                                                                <DropdownItem key={index} onClick={() => handleDropdownChange(item, 'condition')}>
                                                                    <div>{item}</div>
                                                                </DropdownItem>
                                                            )}
                                                        </div>
                                                    }
                                                </DropdownMenu>
                                            </Dropdown>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="description"
                                                className="RCM_form_control RCM_Rules_control_text"
                                                value={activeRule.description}
                                                onChange={evt => handleFormChanges(evt)}
                                                disabled={!activeRule.iscondition}
                                            />
                                            <label className="RCM_Rules_label">then</label>
                                        </FormGroup>
                                    </div>

                                </div>
                                <div className="row RCM_rule_section">
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <label className="RCM_Rules_label">ACTION</label>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <label className="RCM_selectLabel RCM_rule_checkbox RCM_Rules_label">
                                            <input type="checkbox"
                                                name="isexclude"
                                                checked={activeRule.isexclude}
                                                onChange={evt => handleFormChanges(evt)} />
                                            <span className="profile-font-color">Exclude Transaction</span>
                                        </label>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <FormGroup className="RCM_Rules_FormGroup RCM_payee">
                                            
                                            <label className="RCM_Rules_label">Post to</label>
                                            <label className="RCM_Rules_label">Main Account</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="coaaccountno"
                                                className={"RCM_form_control RCM_Rules_control" + (activeRule.isexclude ? ' RCM_link_disabled' : '')}
                                                value={activeRule.coaaccountno + (activeRule.coaaccountname !== undefined ? (" - " + activeRule.coaaccountname) : '')}
                                                onChange={evt => handleFormChanges(evt)}
                                                disabled
                                            />
                                            <div className="RCM_rules_lbl_fields_btn_wrapper">
                                                <button type="button"
                                                    className="RCM_billpay_btn"
                                                    onClick={() => toggleSelectValuesPopup('MainAccounts', 'MainAccount')}
                                                    disabled={activeRule.isexclude}
                                                >
                                                    SELECT
                                                </button>
                                            </div>
                                            <label className="RCM_Rules_label">Sub Account</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="subaccountno"
                                                className={"RCM_form_control RCM_Rules_control" + (activeRule.isexclude ? ' RCM_link_disabled' : '')}
                                                value={activeRule.subaccountno + (activeRule.subaccountname !== undefined ? (" - " + activeRule.subaccountname) : '')}
                                                onChange={evt => handleFormChanges(evt)}
                                                disabled
                                            />
                                            <div className="RCM_rules_lbl_fields_btn_wrapper">
                                                <button type="button"
                                                    className="RCM_billpay_btn"
                                                    onClick={() => toggleSelectValuesPopup('SubAccounts')}
                                                    disabled={activeRule.isexclude}
                                                >
                                                    SELECT
                                                </button>
                                            </div>

                                            <label className="RCM_Rules_label">Payee</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="rulename"
                                                className={"RCM_form_control RCM_Rules_control" + (activeRule.isexclude ? ' RCM_link_disabled' : '')}
                                                value={activeRule.vendorid + ((activeRule.vendorname !== undefined && activeRule.vendorname !== '') ? (" - " + activeRule.vendorname) : '')}
                                                disabled
                                            />
                                            <div className="RCM_rules_lbl_fields_btn_wrapper">
                                                <button type="button"
                                                    className="RCM_billpay_btn"
                                                    onClick={() => toggleSelectValuesPopup('Payee')}
                                                    disabled={activeRule.isexclude}
                                                >
                                                    SELECT
                                                </button>
                                            </div>

                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                        <FormGroup className="RCM_Rules_FormGroup">
                                            <label className="RCM_selectLabel RCM_rule_checkbox RCM_Rules_label">
                                                <input type="checkbox"
                                                    name="isrename"
                                                    checked={activeRule.isrename}
                                                    onChange={evt => handleFormChanges(evt)}/>
                                                <span className="profile-font-color"></span>
                                            </label>
                                            <label className="RCM_Rules_label">And rename the description to this </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                name="renameddesc"
                                                className='RCM_form_control RCM_Rules_control_text'
                                                value={activeRule.renameddesc}
                                                onChange={evt => handleFormChanges(evt)}
                                                disabled={!activeRule.isrename}
                                            />
                                        </FormGroup>
                                    </div>

                                </div>
                            </Form>
                        </div>         

                    </>

                </ModalBody>
                <ModalFooter className="RCM_manage_group_footer">
                    <div align="right">
                        <button type="button" className="btn btn-btn RCM_button_secondary RCM_popup_btn" onClick={() => toggleAddEditPopup()}>CANCEL</button> &nbsp;
                        <button type="button" className="btn btn-btn RCM_button_primary RCM_popup_btn" onClick={() => save()}>
                            {mode === "edit" ? 'SAVE' : 'ADD'}
                        </button>
                    </div>

                </ModalFooter>    
            </Modal>

        </>
        
    )

};

export default PostingRulesView;