import { UPDATE_SELECTED_CLIENT } from "../actions/actionTypes";

const initialState = {
    selectedClient: null,
}

export function DashBoardReducer(state=initialState, action){

    switch(action.type)
    {

        case UPDATE_SELECTED_CLIENT:
        {
            return {
                ...state, 
                selectedClient : action.payload,
            }
        }

        default:
        return state;
    }
}