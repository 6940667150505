import React from 'react';
import NavMenu from './NavMenu';

import 'antd/dist/antd.css';
import { Layout, Divider } from 'antd';
import packageJson from '../../package.json';

const { Content, Footer } = Layout;


export default props => (
    <Layout>
      <NavMenu />
      <Content style={{marginTop: '6rem'}}>
        <div style={{minHeight: "calc(100vh - 11.5rem)" }}>
            {props.children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', position: 'relative' }}>
          Payment Hub @Copyrights Rockefeller Capital Management <Divider type="vertical" /> {packageJson.buildNumber}
      </Footer>
    </Layout>
);
