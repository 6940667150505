import React, {Component} from 'react';
import DiscretionDistributionList from './discretiondistributionlist';
import DiscretionDistributionCompletedList from './discretiondistributioncompletedlist';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

class DiscrtionaryDistribution extends Component {

    constructor(props) {
        super(props);
        this.state = {  };

    }

    render() {
        return (
            <Tabs defaultActiveKey="1" animated={false}>
                <TabPane tab="PENDING/DRAFT DISTRIBUTIONS" key="1">
                   <DiscretionDistributionList></DiscretionDistributionList>
                </TabPane>
                <TabPane tab="COMPLETED DISTRIBUTION" key="2">
                    <DiscretionDistributionCompletedList></DiscretionDistributionCompletedList>
                </TabPane>
            </Tabs>
        );
    }
}

export default DiscrtionaryDistribution;