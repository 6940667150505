import React, { Component } from 'react';
import { getWiresListColumn } from './wiresListColumns';
import { MsgForNoData, dateFormat } from '../../../common/constants';
import { getWiresList } from '../../../services/adminServices/wiresListService';
import BootstrapTable from 'react-bootstrap-table-next';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import OverlayProcessing from '../../../common/overlayProcessing';
import * as moment from "moment";
import { connect } from 'react-redux';
import { arrayFilterByValue } from '../../../common/utilities';
import { Label } from 'reactstrap';


class WiresList extends Component {

    constructor(props){
        super(props);

        this.state = {
            showOverlay: false,
            searchText: '',
            wiresList: [],
            filterdWiresList: [],
            fromDate: moment().subtract(30, 'days'),
            toDate: moment()
        }

        this.defaultWiresListSorted = [{
            dataField: 'createdTime',
            order: 'desc'
        }]

        this.onToDateChange = this.onToDateChange.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
    }

    componentDidMount(){
        this.fetchWiresList();
    }

    componentDidUpdate(prevProps){
        if (prevProps.refresh !== this.props.refresh) {
            this.setState({fromDate: moment().subtract(30, 'days'), toDate: moment()}, () => {
                this.fetchWiresList();
            })
        }

        if(prevProps.ActiveKey !== this.props.ActiveKey && this.props.ActiveKey === '2'){
            this.setState({fromDate: moment().subtract(30, 'days'), toDate: moment()}, () => {
                this.fetchWiresList();
            })
        }
    }

    fetchWiresList(){
        this.setState({showOverlay: true}, () => {
            //call api here
            let fromDate = this.state.fromDate ? this.state.fromDate.format('L') : "";
            let toDate = this.state.toDate ? this.state.toDate.format('L') : "";
            getWiresList(fromDate, toDate).then(res => {
                let wires = res.response_data && Array.isArray(res.response_data) ? res.response_data : [];
                this.setState({wiresList: wires, filterdWiresList: wires, showOverlay: false});
            })
            .catch(err => {
                this.setState({showOverlay: false});
            })
        });
    }

    handleOnSearchChange(evt){
        let searchString = evt.target.value;
        if (searchString) {
            var updatedWiresList = [...this.state.wiresList];
            let searchKeyList = ['createdTime', 'entity', 'vendorname', 'amount', 'paymentType', 'paymentStatus', 'approvalStatus', 'isWireSent'];
            updatedWiresList = arrayFilterByValue(updatedWiresList, searchString, searchKeyList);
            this.setState({ filterdWiresList: updatedWiresList, searchText: searchString });
        } else {
            this.setState({ filterdWiresList: this.state.wiresList, searchText: searchString });
        }
    }

    onFromDateChange(date){
        this.setState({fromDate: date}, () => {
            this.fetchWiresList();
        });
    }

    onToDateChange(date){
        this.setState({toDate: date}, () => {
            this.fetchWiresList();
        });
    }

    render() {
        return (
            <div className="RCM_Toast">
                {
                    this.state.showOverlay && (<OverlayProcessing />)
                }
                <div className="row">
                   <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
                        <Label className="RCM_Form_Label">FROM DATE: &nbsp; 
                        <DatePicker value={this.state.fromDate} 
                            format={dateFormat} 
                            showToday={false} 
                            allowClear={false}
                            onChange={this.onFromDateChange} /></Label>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
                        <Label className="RCM_Form_Label">TO DATE: &nbsp; 
                            <DatePicker value={this.state.toDate} 
                                format={dateFormat} 
                                allowClear={false}
                                showToday={false} 
                                onChange={this.onToDateChange} /></Label>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mt-1" align="right">
                        <input 
                            type="text" 
                            placeholder="Search Payments"
                            value={this.state.searchText} 
                            className="RCM_form_control" onChange={(e) => this.handleOnSearchChange(e)}/>
                    </div>
                </div>
                <div className="row mt-1">
                <div className="col-sm-12 col-md-12 col-lg-12" id="admin_wireslist">
                 <BootstrapTable
                        keyField='billId'
                        data={this.state.filterdWiresList}
                        columns={getWiresListColumn()}
                        headerClasses="row-bg"
                        bordered={false}
                        classes="RCM_two_level_table1"
                        rowClasses='RCM_tow_level_table1_row_animation'
                        noDataIndication={MsgForNoData}
                        defaultSorted={this.defaultWiresListSorted}
                    />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (store) => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
    };
}

const mapDispatchToProps = {
    
}
export default connect(mapStateToProps, mapDispatchToProps)(WiresList);

