import { billPayURL, mmSummaryUrl, referenceAPIURL, apiBaseUrl, getVendorsListUrl, 
    invoiceUploadUrl, createUpdateVendorWirePaymentInstruction, getWireInstructionUrl, 
    getLookUpValue, fetchMatrixContexualMenuItemsUrl, billDotComSsoLogUrl } from "../serviceConstant";
import {  errorMsg} from '../../common/constants';
import { Post, Get, PostMultiPart } from "../servicecalls";
import axios from "axios";

export var addVendorCancellationToken = null;
export var getVendorCancellationToken = null;
export var getHolidayListCancellationToken = null;
export var getCountryStateCancellationToken = null;
export var createVendorPaymentInstructionForWireCancellationToken = null;
export var GetPaymentListServiceCancellationToken = null;
export var GetWireInstructionCancellationToken = null;
export var GetPayTermsCancellationToken = null;
export var GetContextualMenuItemsCancellationToken = null;
export var BillDotComSsoLogCancellationToken = null;

export const addVendorService = (vendorDetails, token, contextJson) => {

    //Check if cancellation token has value;
    if (addVendorCancellationToken !== null && addVendorCancellationToken !== undefined) {
        //Cancel the existing call
        addVendorCancellationToken.cancel('Cancelling the add vendor request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addVendorCancellationToken = cancelToken.source();

    let addVendorPromise = new Promise(
        function (resolve, reject) {
            let addVendorurl = apiBaseUrl() + "/" + billPayURL + "/createVendor";
            Post(addVendorurl, vendorDetails, token, contextJson, addVendorCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Bill Pay Service : Add Vendor ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Bill Pay Service : Add Vendor :: ", error);
                       reject(errorMsg);
                    }
                });
        });
    return addVendorPromise;
}

export const getVendorService = () => {

    //Check if cancellation token has value;
    if (getVendorCancellationToken !== null && getVendorCancellationToken !== undefined) {
        //Cancel the existing call
        getVendorCancellationToken.cancel('Cancelling the get vendor request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getVendorCancellationToken = cancelToken.source();

    let getVendorPromise = new Promise(
        function (resolve, reject) {
            let getVendorurl = apiBaseUrl() + "/" + billPayURL + "/getVendors";
            Post(getVendorurl, "", getVendorCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Bill Pay Service : get Vendor ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Bill Pay Service : get Vendor :: ", error);
                         reject(errorMsg);
                    }
                });
        });
    return getVendorPromise;
}

export const getHolidayList = (token, contextJson, profileJson) => {

    //Check if cancellation token has value;
    if (getHolidayListCancellationToken !== null && getHolidayListCancellationToken !== undefined) {
        //Cancel the existing call
        getHolidayListCancellationToken.cancel('Cancelling the existing holiday list request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getHolidayListCancellationToken = cancelToken.source();


    let getHolidayListPromise = new Promise(
        function (resolve, reject) {
            let holidayList = [];
            let holidayListUrl = `${mmSummaryUrl}?SearchBy=Holiday&DateRange=Years,1`;
            Get(holidayListUrl, token, contextJson, profileJson, getHolidayListCancellationToken).then(res => {
                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {

                    //get the valid response 
                    console.log("Money Movement Service : Holiday List::", res.data.value[0]);
                    let response = res.data.value[0];
                    if (response.holidays !== null
                        && response.holidays !== undefined
                        && Array.isArray(response.holidays)
                        && response.holidays.length > 0
                    ) {
                        holidayList = response.holidays;
                        resolve(holidayList);
                    } else {
                        resolve(holidayList);
                    }
                } else {
                    reject(errorMsg);
                }

            })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        console.log("Payments service :: getHolidayList ", error);
                        reject(errorMsg);
                    }
                });



        });

    return getHolidayListPromise;
}
export const getCountryStateService = (value, token, contextJson, profileJson ) => {

    //Check if cancellation token has value;
    if (getCountryStateCancellationToken !== null && getCountryStateCancellationToken !== undefined) {
        //Cancel the existing call
        getCountryStateCancellationToken.cancel('Cancelling the get country/state request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getCountryStateCancellationToken = cancelToken.source();

    let getCountryStatePromise = new Promise(
        function (resolve, reject) {
            let getCountryStateurl = apiBaseUrl() + "/" + referenceAPIURL + "/CommonReference('" + value + "')";
  
            Get(getCountryStateurl, token, contextJson, profileJson, getCountryStateCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Bill Pay Service : get Country State ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Bill Pay Service : get Country State :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return getCountryStatePromise;
}
export const CreateVendorPaymentInstructionForWireService = (vendorPaymentInstructionForWireDetails, token, contextJson) => {

    //Check if cancellation token has value;
    if (createVendorPaymentInstructionForWireCancellationToken !== null && createVendorPaymentInstructionForWireCancellationToken !== undefined) {
        //Cancel the existing call
        createVendorPaymentInstructionForWireCancellationToken.cancel('Cancelling the create Vendor Payment Instruction For Wire request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    createVendorPaymentInstructionForWireCancellationToken = cancelToken.source();

    let createVendorPaymentInstructionForWirePromise = new Promise(
        function (resolve, reject) {
            let createVendorPaymentInstructionForWireurl = `${apiBaseUrl()}/${createUpdateVendorWirePaymentInstruction}`;
            Post(createVendorPaymentInstructionForWireurl, vendorPaymentInstructionForWireDetails, token, contextJson, createVendorPaymentInstructionForWireCancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Bill Pay Service : create Vendor Payment Instruction For Wire ::", res);
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Bill Pay Service : create Vendor Payment Instruction For Wire :: ", error);
                        reject(errorMsg);
                    }
                });
        });
    return createVendorPaymentInstructionForWirePromise;
}

export const GetPaymentListService = (user) => {

    //userid = "TThaveesaengsiri@rockco.com";
    //Check if cancellation token has value;
    if (GetPaymentListServiceCancellationToken !== null && GetPaymentListServiceCancellationToken !== undefined) {
        //Cancel the existing call
        GetPaymentListServiceCancellationToken.cancel('Cancelling the get vendor list request to prevent memory leaks.');
    }

    let userid = (user.userName)? user.userName : '';
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetPaymentListServiceCancellationToken = cancelToken.source();

    let getVendorsListPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${getVendorsListUrl}?rcmUser=${userid}`;
            Post(url, '', GetPaymentListServiceCancellationToken).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Bill Pay Service : Error get vendors list : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return getVendorsListPromise;
}

export const uploadInvoices = (file, id, email, onUploadProgress) => {

    var data = new FormData();
    data.append("fileContent", file);  
    data.append("fileName", file.name);
    data.append("orgid", id);
    data.append("id", email);

    let uploadFilePromise = new Promise(function (resolve, reject) {

        let url = `${apiBaseUrl()}/${invoiceUploadUrl}`;

        PostMultiPart(url, data, null, onUploadProgress).then(
            docUploadRes => {
                if(docUploadRes && docUploadRes.data && docUploadRes.data.response_status === 0)
                {
                    resolve(docUploadRes);
                }else{
                    reject('Error While uploading the file ');
                }

            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Uploading document", err);
                reject(err);
            }
        })
    });

    return uploadFilePromise;

}


export const getWireInstructions = (vendorid, orgid) => {
    //Check if cancellation token has value;
    if (GetWireInstructionCancellationToken !== null && GetWireInstructionCancellationToken !== undefined) {
        //Cancel the existing call
        GetWireInstructionCancellationToken.cancel('Cancelling the create Vendor Payment Instruction For Wire request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetWireInstructionCancellationToken = cancelToken.source();

    let getWireInstructionPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${getWireInstructionUrl}?vendorId=${vendorid}&orgId=${orgid}`;
            Get(url, GetWireInstructionCancellationToken).then(res => {
                // get the response in case of successful call.
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel()) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure.
                    console.error("Vendor Service : get wire instruction for vendor", error);
                    reject(errorMsg);
                }
            });
        });
    return getWireInstructionPromise;
 }


 export const getPayTerms = (groupCode, orgId) => {

    //Check if cancellation token has value;
    if (GetPayTermsCancellationToken !== null && GetPayTermsCancellationToken !== undefined) {
        //Cancel the existing call
        GetPayTermsCancellationToken.cancel('Cancelling the create Vendor Payment Instruction For Wire request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetPayTermsCancellationToken = cancelToken.source();

    let getPayTermsPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${getLookUpValue}?groupCode=${groupCode}&orgId=${orgId}`;
            Get(url, GetPayTermsCancellationToken).then(res => {
                // get the response in case of successful call.
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel()) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure.
                    console.error("Vendor Service : get wire instruction for vendor", error);
                    reject(errorMsg);
                }
            });
        });
    return getPayTermsPromise;


 }


 export const getContextualMenuItems = () => {

    //Check if cancellation token has value;
    if (GetContextualMenuItemsCancellationToken !== null && GetContextualMenuItemsCancellationToken !== undefined) {
        //Cancel the existing call
        GetContextualMenuItemsCancellationToken.cancel('Cancelling request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetContextualMenuItemsCancellationToken = cancelToken.source();

    let getPayTermsPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${fetchMatrixContexualMenuItemsUrl}`;
            Get(url, GetContextualMenuItemsCancellationToken).then(res => {
                // get the response in case of successful call.
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel()) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure.
                    console.error("Payments Service : get contexual menu items", error);
                    reject(errorMsg);
                }
            });
        });
    return getPayTermsPromise;


 }

 export const billDotComTrackingSsoLogService = () => {

    //Check if cancellation token has value 

    if (BillDotComSsoLogCancellationToken !== null && BillDotComSsoLogCancellationToken !== undefined) {
        //Cancel the existing call
        BillDotComSsoLogCancellationToken.cancel('Cancelling request to prevent memory leaks.');
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    BillDotComSsoLogCancellationToken = cancelToken.source();

    let billDotComSsoLogPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${billDotComSsoLogUrl}`;
            Get(url, BillDotComSsoLogCancellationToken)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                if (axios.isCancel()) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure.
                    console.error("Payments Service : while logging bill.com sso tracking info", error);
                    reject(errorMsg);
                }
            });
        });
    return billDotComSsoLogPromise;

 }