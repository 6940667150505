import { searchVendorListUrl, apiBaseUrl} from "../serviceConstant";
import { errorMsg} from "../../common/constants";
import { Get } from "../servicecalls";
import axios from "axios";


export var searchVendorListCancellationToken = null;


export const searchVendorList = (orgId) => {

    if (searchVendorListCancellationToken !== null && searchVendorListCancellationToken !== undefined) {
        searchVendorListCancellationToken.cancel("Cancel existing get vendor list to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    searchVendorListCancellationToken = cancelToken.source();



    let searchVendorListPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${searchVendorListUrl}?orgId=${orgId}`;
            Get(url, searchVendorListCancellationToken).then(res => {
                // get the response in case of successful call.
                if(res && res.data && res.data.response_status === 0){
                    resolve(res.data.response_data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel()) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure.
                    console.error("Vendor Service : get vendor list for client ", error);
                    reject(errorMsg);
                }
            });

        });

    return searchVendorListPromise;
};
