import axios from './servicecallsInterceptor';
import { store } from '..';

export function Get(url, cancellationSource) {
    let token = store.getState().app.token;
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        //"version": "v1",
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "Authorization": `Bearer ${token}`
    };

   return axios({
        method: 'get',
        url: url,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    }); 
}

export function Post(url, requestBody, cancellationSource) {
    let token = store.getState().app.token;
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        //"version": "v1",
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "Authorization": `Bearer ${token}`
    };

    return axios({
        method: 'post',
        url: url,
        data: requestBody,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    })
    
}

export function PostMultiPart(url, requestBody, cancellationSource, onUploadProgress){
    let token = store.getState().app.token;
    
    let headers = {
        //Note:  Ask apim to have allowed header * to upload multipart files
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
    }

    return axios({
        method: 'post',
        url: url,
        data: requestBody,
        headers: headers,
        onUploadProgress: onUploadProgress? (progressEvent) => { onUploadProgress(progressEvent) }:null,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    })
}


export function Delete(url, requestBody, cancellationSource) {
    let token = store.getState().app.token;
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        //"version": "v1",
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "Authorization": `Bearer ${token}`
    };

    return axios({
        method: 'delete',
        url: url,
        data: requestBody,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    })
    
}


export function ninthwavesGet(url, appId, token, cancellationSource){

    let headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `${token}`,
        "ApplicationId": `${appId}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600
    };

   return axios({
        method: 'get',
        url: url,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    }); 

}

export function ninthwavesPost(url, requestBody, appId, token, cancellationSource){

    let headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `${token}`,
        "ApplicationId": `${appId}`,
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600
    };

   return axios({
        method: 'post',
        url: url,
        headers: headers,
        data: requestBody,
        cancelToken: (cancellationSource && cancellationSource.token ) ? cancellationSource.token : null
    }); 

}

export function GetForGraph(url, token, cancellationSource) {
    //let token = store.getState().app.token;
    let headers = {
        "Content-Type": "application/json; charset=UTF-8; odata=verbose",
        "Access-Control-Allow-Origin": "*",
        //"version": "v1",
        "Cache-Control": "No-Store",
        "Access-Control-Max-Age": 600,
        "Authorization": `Bearer ${token}`
    };

    return axios({
        method: 'get',
        url: url,
        headers: headers,
        cancelToken: (cancellationSource && cancellationSource.token) ? cancellationSource.token : null
    });
}
