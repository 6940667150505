import React from 'react';
import { formatInCurrency } from '../../../common/utilities';

export function getWiresListColumn(){
    const column = [
        {
            dataField: 'createdTime',
            text: 'Payment Date',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => {
                try{
                    if (order === 'asc') {
                        return new Date(b) - new Date(a);
                    }
                    return new Date(a) - new Date(b); // desc
               }catch(err){
                     console.error(err);
                    if(order === 'asc'){
                        return b - a;
                    }
                     return a - b;
               }
            }
            
        },
        {
            dataField: 'entity',
            text: 'Entity Number',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'vendorname',
            text: 'Vendor Name',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'amount',
            text: 'Amount',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                  return b - a;
                }
                return a - b; // desc
            },
            formatter: (cell, row) => {
                return(
                    <div align="right">
                        {formatInCurrency(cell)}
                    </div>
                )
            }
        },
        {
            dataField: 'paymentType',
            text: 'Payment Type',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'paymentStatus',
            text: 'Payment Status',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'approvalStatus',
            text: 'Approval Status',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'isWireSent',
            text: 'Wire Sent',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}