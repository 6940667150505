import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import ClientVisibility from './admin/clientVisibility/clientVisibility';
import DiscrtionaryDistribution from './discretionarydistrequest/discrtionarydistribution';
import { getPreferenceValue } from '../common/utilities';
import {UPDATE_SELECTED_MENU_VALUE} from '../actions/appActions';
import DashBoardContainer from './dashboard/DashBoardContainer';

class Home extends Component {

    constructor(props){
        super(props)
        this.currentComponet = [];

        if(getPreferenceValue(this.props.faFeatures, 'DASHBOARD') === 'Y'){
            this.currentComponet.push(<DashBoardContainer id='dashboard'/>);
            this.props.UPDATE_SELECTED_MENU_VALUE('1');
        }else if(getPreferenceValue(this.props.faFeatures, 'ADMIN') === 'Y'){
            this.currentComponet.push(<ClientVisibility id='admin'/>);
            this.props.UPDATE_SELECTED_MENU_VALUE('2');
        }else if(getPreferenceValue(this.props.faFeatures, 'DISCRETIONARY_DISTRIBUTION') === 'Y'){
            this.currentComponet.push(<DiscrtionaryDistribution id='discretionarydistribution'/>)
            this.props.UPDATE_SELECTED_MENU_VALUE('3');
        }
    }


    render() {
        return (
            <>
                {this.currentComponet.map((comp, index) => {
                    return (
                        <div key={index}>{comp}</div> 
                     )
                })
            }
            </>
        )
    }
}


const mapStateToProps = (store) => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
        env: store.app.env,
        faFeatures : store.app.faFeatures
    };
}

const mapDispatchToProps = {
    UPDATE_SELECTED_MENU_VALUE
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
