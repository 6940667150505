import React from 'react';
import { MsgForNoData } from '../../common/constants';
import Messages from '../../common/messages.jsx';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from 'reactstrap';
import GenericMessageModal from '../../common/genericMsgModal';

export function SetupBillsView(
    props
) {
    let setupbillsview = (
        <div className="RCM_Toast">
            {/* <div className="row">
                <div className="col-sm-12" key="setupbillsheading" className="RCM_tile_heading RCM_payments_toast_heading">
                    <label key='setupbillsheading'>SETUP BILLS</label>
                </div>
            </div> */}
            <div className="row ">
                <div className="col-sm-8 col-xs-8 col-md-8 col-lg-8">
                    <Label for="type" className="RCM_uploadInvoice_label">Client Name:</Label>
                    <Label for="type" className="RCM_uploadInvoice_label">{props.selectedClient && props.selectedClient.name ? props.selectedClient.name : ''}</Label> </div>
                <div className="col-sm-4 col-xs-4 col-md-8 col-lg-4">
                    <div className="RCM_billpay_back_btn_container" align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => props.backFromVendors()}>BACK</button>
                    </div>
                </div>
            </div>

            {props && props.state ?
                <div >
                    <div className="RCM_asset_tab_container row  RCM_Billpay_NavTab_container" id="RCM_mortgage_form">
                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12" >
                            <div activeTab={props.state.activeTab} className="RCM_asset_form_container RCM_billPay_from_container">
                                <div className="RCM_billpay_form_box">


                                    <div className="RCM_billpay_form_content">
                                        <Form>
                                            {(props.state.billInformation.vendorpaymenttype === "WIRE") &&
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 no_padding">
                                                        <div className="row">
                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="RCM_tile_heading RCM_details_heading RCM_form_subheading">
                                                                    VENDOR BASICS
                                                                 </div>
                                                                <div>

                                                                    <div className="row">
                                                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                            <FormGroup controlId="formGridTotal Value">
                                                                                <div className="RCM_label_wrapper">
                                                                                    <Label for="vendorname">Vendor Name *</Label>
                                                                                </div>
                                                                                <input type="text" 
                                                                                        className="RCM_form_control"
                                                                                        onBlur={props.handleVendorNameOnBlur}
                                                                                        value={props.state.billInformation.vendorbasics.vendorname}
                                                                                        onChange={(s) => props.handleVendorNameChange(s)}
                                                                                 />

                                                                                {
                                                                                    (props && props.state && props.state.messages && props.state.messages.vendorNameErr && props.state.messages.vendorNameErr !== '')
                                                                                    && <Messages messages={[{'text': props.state.messages.vendorNameErr, 'class': 'RCM_validation_error_text'}]} containerClass={"RCM_validation_error_container"} />
                                                                                } 
                                                                            </FormGroup>
                                                                        </div>
                                                                        {
                                                                            props.state.isLargeVendor ||
                                                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                                <FormGroup controlId="formGridTotal Value">
                                                                                    <div className="RCM_label_wrapper">
                                                                                        <Label for="type">Vendor Type *</Label>
                                                                                    </div>
                                                                                    <Dropdown name="type" required
                                                                                        isOpen={props.state.isTypesOfVendorOpen}
                                                                                        toggle={props.typesOfVendorToggle}
                                                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                                                        <DropdownToggle caret>{props.state.billInformation.vendorbasics.vendortype}</DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            {
                                                                                                <div>
                                                                                                    {props.state.typesOfVendors.map((item, index) =>
                                                                                                        <DropdownItem key={index} onClick={() => props.typesOfVendorChange(item)}>
                                                                                                            <div>{item && item.name ? item.name : ''}</div>
                                                                                                        </DropdownItem>
                                                                                                    )}
                                                                                                </div>
                                                                                            }
                                                                                        </DropdownMenu>
                                                                                    </Dropdown>

                                                                                    {
                                                                                    (props && props.state && props.state.messages && props.state.messages.vendorTypeErr && props.state.messages.vendorTypeErr !== '')
                                                                                        && <Messages messages={[{'text': props.state.messages.vendorTypeErr, 'class': 'RCM_validation_error_text'}]} containerClass={"RCM_validation_error_container"} />
                                                                                    } 
                                                                                </FormGroup>
                                                                            </div>
                                                                        }
                                                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                            <FormGroup controlId="formGridTotal Value">
                                                                                <div className="RCM_label_wrapper">
                                                                                    <Label for="custodianname">Account Number With Vendor</Label>
                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    placeholder=""
                                                                                    name="accountnumberwithvendor"
                                                                                    className="RCM_form_control"
                                                                                    value={props.state.billInformation.vendorbasics.accountnumberwithvendor}
                                                                                    onChange={props.handleFormChanges}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                                <FormGroup controlId="formGridTotal Value">
                                                                                    <div className="RCM_label_wrapper">
                                                                                        <Label for="payterm">Payment Term</Label>
                                                                                    </div>
                                                                                    <Dropdown name="payterm" required
                                                                                        isOpen={props.state.isPayTermOpen}
                                                                                        toggle={props.payTermToggle}
                                                                                        className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                                                        <DropdownToggle caret>{props.state.billInformation.vendorpayterm}</DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            {
                                                                                                <div>
                                                                                                    {props.state.payTermsList.map((item, index) =>
                                                                                                        <DropdownItem key={index} onClick={() => props.handlePayTermChange(item)}>
                                                                                                            <div>{item && item.value ? item.value : ''}</div>
                                                                                                        </DropdownItem>
                                                                                                    )}
                                                                                                </div>
                                                                                            }
                                                                                        </DropdownMenu>
                                                                                    </Dropdown>
                                                                                </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="RCM_billpay_form_divider">
                                                        <div className="row">
                                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_tile_heading RCM_details_heading RCM_form_subheading">
                                                                    WIRE PAYMENT INFORMATION
                                                                </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                <FormGroup controlId="formGridTotal Value">
                                                                    <Label for="custodianname">Account Number</Label>
                                                                    {
                                                                    props.state.billInformation.vendorpayterm !== 'NFS Wire' ?
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        name="accountNumber"
                                                                        className="RCM_form_control"
                                                                        required
                                                                        value={props.state.billInformation.paymentinfo.accountNumber}
                                                                        onChange={props.handleFormChanges}
                                                                    />
                                                                    :
                                                                        <Dropdown name="fromAccount" required
                                                                            isOpen={props.state.isFromAcctOpen}
                                                                            toggle={props.fromAcctToggle}
                                                                            className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                                            <DropdownToggle caret>{props.state.billInformation.paymentinfo.accountNumber}</DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {
                                                                                    <div>
                                                                                        {props.state.clientFromAccts.map((acct, index) =>
                                                                                            <DropdownItem key={index} onClick={() => props.handleFromAcctChange(acct)}>
                                                                                                <div>{acct && acct.accountNo ? acct.accountNo : ''}</div>
                                                                                            </DropdownItem>
                                                                                        )}
                                                                                    </div>
                                                                                }
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    }
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                <FormGroup controlId="formGridTotal Value">
                                                                    <Label for="custodianname">Party Type *</Label>
                                                                <Dropdown name="type" required
                                                                    isOpen={props.state.isTypesOfPartyOpen}
                                                                    toggle={props.typesOfPartyToggle}
                                                                    className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                                                    <DropdownToggle caret>{props.state.billInformation.paymentinfo.partydisplayvalue}</DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {
                                                                            <div>
                                                                                {props.state.typesOfParties.map((item, index) =>
                                                                                    <DropdownItem key={index} onClick={() => props.typesOfPartyChange(item)}>
                                                                                        <div>{item && item.name ? item.name : ''}</div>
                                                                                    </DropdownItem>
                                                                                )}
                                                                            </div>
                                                                        }
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                                </FormGroup>
                                                                {
                                                                    (props && props.state && props.state.messages && props.state.messages.countryErr && props.state.messages.countryErr !== '')
                                                                       && <Messages messages={[{'text': props.state.messages.countryErr, 'class': 'RCM_validation_error_text'}]} containerClass={"RCM_validation_error_container"} />
                                                                }
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                <FormGroup controlId="formGridTotal Value">
                                                                    <Label for="custodianname">Country *</Label>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        name="paymentinfoCountry"
                                                                        className="RCM_form_control"
                                                                        maxLength={2}
                                                                        required
                                                                        value={props.state.billInformation.paymentinfo.country}
                                                                        onChange={props.handleFormChanges}
                                                                        onBlur={props.handleOnBlur}
                                                                    />
                                                                </FormGroup>
                                                                {
                                                                    (props && props.state && props.state.messages && props.state.messages.countryErr && props.state.messages.countryErr !== '')
                                                                       && <Messages messages={[{'text': props.state.messages.countryErr, 'class': 'RCM_validation_error_text'}]} containerClass={"RCM_validation_error_container"} />
                                                                }
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                <FormGroup controlId="formGridTotal Value">

                                                                    <div className="RCM_label_wrapper">
                                                                        <Label for="type">Bank Routing Number *</Label>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        name="routingNumber"
                                                                        className="RCM_form_control"
                                                                        required
                                                                        value={props.state.billInformation.paymentinfo.routingNumber}
                                                                        onChange={props.handleFormChanges}
                                                                        onBlur={props.getBankName}
                                                                    />
                                                                </FormGroup>
                                                                {
                                                                    (props && props.state && props.state.messages && props.state.messages.routingNumErr && props.state.messages.routingNumErr !== '')
                                                                            && <Messages messages={[{'text': props.state.messages.routingNumErr, 'class': 'RCM_validation_error_text'}]} containerClass={"RCM_validation_error_container"} />
                                                                }
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                <FormGroup controlId="formGridTotal Value">
                                                                    <Label for="custodianname">Bank Name</Label>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        name="bankName"
                                                                        className="RCM_form_control"
                                                                        required
                                                                        value={props.state.billInformation.paymentinfo.bankName}
                                                                        disabled="disabled"
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="RCM_billpay_form_divider">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_tile_heading RCM_details_heading RCM_form_subheading">
                                                                    BENEFICIARY DETAILS
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Account Number</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="beneficiaryDetailsAccountNumber"
                                                                            className="RCM_form_control"
                                                                            required
                                                                            value={props.state.billInformation.paymentinfo.beneficiaryDetailsAccountNumber}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Name</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="beneficiaryDetailsVendorname"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.beneficiaryDetailsVendorname}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 1</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder="Number and street, P.O. Box, etc."
                                                                            name="beneficiaryDetailsAddressline1"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.beneficiaryDetailsAddressline1}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 2</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder="Unit, Apt, Ste, etc."
                                                                            name="beneficiaryDetailsAddressline2"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.beneficiaryDetailsAddressline2}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 3</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="beneficiaryDetailsAddressline3"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.beneficiaryDetailsAddressline3}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 no_padding">
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_tile_heading RCM_details_heading RCM_form_subheading">
                                                                    DETAILS
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Line 1</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="line1"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.line1}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Line 2</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="line2"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.line2}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Line 3</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="line3"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.line3}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Line 4</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="line4"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.line4}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="RCM_billpay_form_divider">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_tile_heading RCM_details_heading RCM_form_subheading">
                                                                    FURTHER CREDIT 1
                                                            </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Account Number</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCreditAccountNumber"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCreditAccountNumber}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Name</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCreditName"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCreditName}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 1</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCreditAddressLine1"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCreditAddressLine1}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 2</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCreditAddressLine2"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCreditAddressLine2}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 3</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCreditAddressLine3"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCreditAddressLine3}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="RCM_billpay_form_divider">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_tile_heading RCM_details_heading RCM_form_subheading">
                                                                    FURTHER CREDIT 2 
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Account Number</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCredit2AccountNumber"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCredit2AccountNumber}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Name</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCredit2Name"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCredit2Name}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 1</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCredit2AddressLine1"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCredit2AddressLine1}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 2</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCredit2AddressLine2"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCredit2AddressLine2}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                                                    <FormGroup controlId="formGridTotal Value">
                                                                        <Label for="custodianname">Address Line 3</Label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            name="furtherCredit2AddressLine3"
                                                                            className="RCM_form_control"
                                                                            value={props.state.billInformation.paymentinfo.furtherCredit2AddressLine3}
                                                                            onChange={props.handleFormChanges}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                props.mode && props.mode === 'VIEW' || (
                                                    <div className="row">
                                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 RCM_asset_form_btn_container">
                                                            <button type="button" class="btn btn-secondary RCM_button_secondary RCM_manageAsset_btn" onClick={() => props.backFromVendors()}>CANCEL</button>
                                                            <button type="button" class="btn btn-primary RCM_button_primary RCM_manageAsset_btn"
                                                                onClick={props.save}
                                                            //disabled={props.isSaveDisabled()}
                                                            >SUBMIT</button>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                : <div key="ASDropdown22">{MsgForNoData}</div>
            }

            <GenericMessageModal 
                selectClientModalState={props.state.selectClientModalState} 
                message={props.state.genericModalMsg} />

        </div>
    )
    return setupbillsview
}