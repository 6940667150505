import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Upload, Icon } from 'antd';
import { UploadDiscretionaryDocument } from '../../../services/discritionarydistributionservice/discritionarydistributionservice';
const { Dragger } = Upload;

export default class UploadDiscretionaryDistributionDocs extends Component {

    constructor(props){
        super(props);
        this.state = {

        }

        
    }

    onFileUpload({ onSuccess, onError, onProgress, file }){

        UploadDiscretionaryDocument(this.props.distributionID, file, this.props.userEmail,  (progressEvent) => this.onUploadProgress(progressEvent, file, onProgress))
        .then(res => {
            onSuccess(null, file);
        })
        .catch(err => {
            onError();
        })

    }

    onUploadProgress(progressEvent, file, onProgress){
        onProgress({ percent: Math.round(progressEvent.loaded / progressEvent.total * 100).toFixed(2) }, file)
      }

    onFileUploadStatusChange(info){
        const { status } = info.file;
        if (status !== 'uploading') {
          //do something 
        }
        if (status === 'done') {
          //message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          //message.error(`${info.file.name} file upload failed.`);
        }
    }

    render() {

        const uploadProps = {
            name: 'file',
            accept:'.pdf',
            multiple: true,
            customRequest: ({ onSuccess, onError, onProgress, file }) => this.onFileUpload({ onSuccess, onError, onProgress, file }),
            onChange: (info) => this.onFileUploadStatusChange(info),
            listType: 'text'
        };

        return (
            <div>
                <div className="row">
                    <div className="col-sm-12 RCM_tile_heading RCM_payments_toast_heading">
                         UPLOAD ADDITIONAL DOCUMENTS
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                            <Dragger {...uploadProps} className="RCM_File_Drop">
                                <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                                <p className="ant-upload-text">Click or drag file(s) to this area to upload documents </p>
                            </Dragger>
                    </div>
                </div>
            </div>
        )
    }
}
