import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MsgForNoData } from '../../common/constants';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Spinner } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { GetPaymentListService, GetPaymentListServiceCancellationToken } from '../../services/billpayservice/billpayservices';
import { UpdateSelectedClient } from '../../actions/dashboardAction';
import { getNestedObject } from '../../common/common';

const ClientListPopup = ({ isClientListPopupOpen, data, toggleClientListPopup }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [clientsList, setClientsList] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector(state => getNestedObject(state, ['app', 'user']));

    const updateActiveClient = useCallback(
        (row) => dispatch(UpdateSelectedClient(row)),
        [dispatch]
    )
    useEffect(() => {

        fetchClientList();
        return () => {
            if (GetPaymentListServiceCancellationToken !== null && GetPaymentListServiceCancellationToken !== undefined) {
                GetPaymentListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
        }
    }, []);
    const fetchClientList = () => {
        if (clientsList.length === 0 && user && user !== null) {
            setShowOverlay(prevShowOverlay => true);
            GetPaymentListService(user).then(res => {
                if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                    setClientsList(res.data);
                }
                //setIsClientListPopupOpen(prevIsClientListPopupOpen => true);
                setShowOverlay(prevShowOverlay => false);
            })
                .catch(err => {

                    console.error("Error Occured while fetching the user vendor list");
                })
        } 
    }
    const handleSelectClient = row => {
        updateActiveClient(row);
        toggleClientListPopup();
    }
    const getClientGridColumn = (handleSelectClient) => {
        const column = [
            {
                dataField: 'name',
                text: 'Client Name',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                   return <div>{cell}</div>;
                }
            },

            {
                dataField: '',
                text: '',
                sort: false,
                isDummyField: true,
                headerAlign: 'right',
                headerClasses: 'RCM_noWrap',
                
                formatter: (cell, row) => {
                    return (<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => handleSelectClient(row)}>SELECT</button>
                    </div>);
                }
            }
        ]

        return column;
    }
    function customCaretSort(order, column) {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }
    return (
        <>
            <Modal isOpen={isClientListPopupOpen} toggle={toggleClientListPopup}
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                zIndex='9999'
                centered >
                <ModalHeader toggle={toggleClientListPopup} className="RCM_externalAssetModel_header RCM_manage_group_header">

                    <label className="RCM_tile_heading">SELECT CLIENT</label>
                </ModalHeader>
                <ModalBody>
                    {
                        showOverlay ? <div align="center" className="mt-4">Loading Clients
                                        &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                        </div>
                            : <>

                                {
                                    clientsList ?
                                        <div id="RCM_SubAccounts_Table" className="RCM_SubAccounts_Table RCM_table_scorllable_body">
                                            <BootstrapTable
                                                keyField='orgId'
                                                data={clientsList}
                                                columns={getClientGridColumn(handleSelectClient)}
                                                headerClasses="row-bg"
                                                bordered={false}
                                                classes="RCM_two_level_table1"
                                                rowClasses="RCM_tow_level_table1_row_animation"
                                                noDataIndication={MsgForNoData}
                                            />
                                        </div>
                                        : <div key="ASDropdown22">{MsgForNoData}</div>

                                }
                            </>
                    }
                </ModalBody>
                <ModalFooter className="RCM_manage_group_footer">
                    <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={() => toggleClientListPopup()}>CANCEL</button>
                    
                </ModalFooter>
            </Modal>

        </>
    )
}
export default ClientListPopup;