
import axios from 'axios';
import {
    fetchSubAccountCategoriesUrl, getSubAccountListUrl, apiBaseUrl, addEditSubAccountUrl, linkSubAccountUrl, unlinkSubAccountUrl, validateABAUrl,
    getVendorListUrl, addEditVendorUrl, addRuleUrl, updateRuleUrl, getRuleListUrl, getInstitutionListUrl, getAccountSubaccountListUrl,
    getMainAccountListUrl, getTransactionListUrl, getTransactionsSourceDataUrl, getTransactionsAccountingDataUrl
} from '../serviceConstant';
import { Get, Post } from '../servicecalls';
import { MsgForFailedData } from '../../common/constants';

export var fetchSubAcctCategoriesCancellationToken = null;

export const fetchSubAccountCategories= () => {
    
    if (fetchSubAcctCategoriesCancellationToken !== null && fetchSubAcctCategoriesCancellationToken !== undefined) {
        fetchSubAcctCategoriesCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchSubAcctCategoriesCancellationToken = cancelToken.source();

    let getUsersListPromise = new Promise(function (resolve, reject) {
        let url = `${fetchSubAccountCategoriesUrl}`;
        Get(url,'', fetchSubAcctCategoriesCancellationToken).then(
            catResponse => {
                //console.log(wiresListRes);
                if(catResponse.data ){
                    resolve(catResponse.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching sub account categories data", err);
                reject(err);
            }
        })
    });


    return getUsersListPromise;


}

export var getSubAccountListServiceCancellationToken = null;

export const getSubAccountListService = (pkid) => {

    if (getSubAccountListServiceCancellationToken !== null && getSubAccountListServiceCancellationToken !== undefined) {
        getSubAccountListServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getSubAccountListServiceCancellationToken = cancelToken.source();

    let getSubAccountListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getSubAccountListUrl}?orgId=${pkid}`;
        //let url = `${getSubAccountListUrl}` + '?orgId=' + orgId;
        Post(url, getSubAccountListServiceCancellationToken).then(
            catResponse => {
                if (catResponse.data) {
                    resolve(catResponse.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching sub account list.", err);
                reject(err);
            }
        })
    });


    return getSubAccountListPromise;


}

export var addEditSubAccountServiceCancellationToken = null;

export const addEditSubAccountService = (account) => {

    if (addEditSubAccountServiceCancellationToken !== null && addEditSubAccountServiceCancellationToken !== undefined) {
        addEditSubAccountServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addEditSubAccountServiceCancellationToken = cancelToken.source();

    let addEditSubAccountPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${addEditSubAccountUrl}`;
        //let url = `${getSubAccountListUrl}` + '?orgId=' + orgId;
        Post(url, account, addEditSubAccountServiceCancellationToken).then(
            catResponse => {
                if (catResponse.data) {
                    resolve(catResponse.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While adding sub account list.", err);
                reject(err);
            }
        })
    });


    return addEditSubAccountPromise;


}
export var linkSubAccountServiceCancellationToken = null;

export const linkSubAccountService = (accountsListsJson) => {

    if (linkSubAccountServiceCancellationToken !== null && linkSubAccountServiceCancellationToken !== undefined) {
       linkSubAccountServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    linkSubAccountServiceCancellationToken = cancelToken.source();

    let linkSubAccountPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${linkSubAccountUrl}`;
        
        Post(url, accountsListsJson, linkSubAccountServiceCancellationToken).then(
            response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While adding sub account list.", err);
                reject(err);
            }
        })
    });


    return linkSubAccountPromise;


}

export var unlinkSubAccountServiceCancellationToken = null;

export const unlinkSubAccountService = (deleteAccount) => {

    if (unlinkSubAccountServiceCancellationToken !== null && unlinkSubAccountServiceCancellationToken !== undefined) {
        unlinkSubAccountServiceCancellationToken.cancel("Cancel existing get aggregated account request to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    unlinkSubAccountServiceCancellationToken = cancelToken.source();

    let deleteExternalAccountPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${unlinkSubAccountUrl}`;
        
        Post(url, deleteAccount, unlinkSubAccountServiceCancellationToken).then(data => {
            resolve(data);
        })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log(err);
                } else {
                    console.log("Error Occured While adding sub account list.", err);
                    reject(err);
                }
            })
    });

    return deleteExternalAccountPromise;
}

export var validateABACancellationToken = null;

export const validateABA = (abaNumber) => {

    if (validateABACancellationToken !== null && validateABACancellationToken !== undefined) {
        validateABACancellationToken.cancel("Cancel existing validate ABA request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    validateABACancellationToken = cancelToken.source();

    let validateABAPromise = new Promise(
        function (resolve, reject) {
            let url = apiBaseUrl() + "/" + validateABAUrl.replace(/ABANumber/g, abaNumber);
            let errorMsg = "Not able to validate ABA Number";
            Get(url, validateABACancellationToken).then(res => {
                // get the response in case of successful call.
                
                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {
                    let abaResponse = res.data.value[0];
                    if (abaResponse.status !== null
                        && abaResponse.status !== undefined
                        && abaResponse.status.Code !== null
                        && abaResponse.status.Code !== undefined
                        && abaResponse.status.Code === "000000") {
                        resolve(res.data.value[0]);
                    } else {
                        reject(errorMsg);
                    }

                } else {
                    console.log("Sub Account : Validate ABA :: " + errorMsg);
                    reject(errorMsg);
                }

            })
                .catch((error) => {
                    if (axios.isCancel()) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure.
                        console.error("Sub Account : Validate ABA ::  ", error);
                        reject(errorMsg);
                    }
                });

        });
    return validateABAPromise;
};

export var getVendorListServiceCancellationToken = null;

export const getVendorListService = (entityId) => {

    if (getVendorListServiceCancellationToken !== null && getVendorListServiceCancellationToken !== undefined) {
        getVendorListServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getVendorListServiceCancellationToken = cancelToken.source();

    let getVendorListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getVendorListUrl}${entityId}`;
        
        Get(url, getVendorListServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching vendor list.", err);
                reject(err);
            }
        })
    });


    return getVendorListPromise;


}

export var addEditVendorServiceCancellationToken = null;

export const addEditVendorService = (reqPayload) => {

    if (addEditVendorServiceCancellationToken !== null && addEditVendorServiceCancellationToken !== undefined) {
        addEditVendorServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addEditVendorServiceCancellationToken = cancelToken.source();

    let addEditVendorPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${addEditVendorUrl}`;
        
        Post(url, reqPayload, addEditVendorServiceCancellationToken).then(
            response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While adding Vendor.", err);
                reject(err);
            }
        })
    });


    return addEditVendorPromise;


}

export var getRuleServiceCancellationToken = null;

export const getRuleListService = (clientId) => {

    if (getRuleServiceCancellationToken !== null && getRuleServiceCancellationToken !== undefined) {
        getRuleServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getRuleServiceCancellationToken = cancelToken.source();

    let getRuleListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getRuleListUrl}?clientid=${clientId}`;

        Get(url, getRuleServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching rule list.", err);
                reject(err);
            }
        })
    });


    return getRuleListPromise;


}

export var addEditRuleServiceCancellationToken = null;

export const addEditRuleService = (reqPayload, mode) => {

    if (addEditRuleServiceCancellationToken !== null && addEditRuleServiceCancellationToken !== undefined) {
        addEditRuleServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addEditRuleServiceCancellationToken = cancelToken.source();

    let addEditRulePromise = new Promise(function (resolve, reject) {
        let serviceUrl = (mode === 'add') ? addRuleUrl : updateRuleUrl;
       
        let url = `${apiBaseUrl()}/${serviceUrl}`;

        Post(url, reqPayload, addEditRuleServiceCancellationToken).then(
            response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While adding Rule.", err);
                reject(err);
            }
        })
    });


    return addEditRulePromise;


}

export var getInstitutionServiceCancellationToken = null;

export const getInstitutionService = (clientId) => {

    if (getInstitutionServiceCancellationToken !== null && getInstitutionServiceCancellationToken !== undefined) {
        getInstitutionServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getInstitutionServiceCancellationToken = cancelToken.source();

    let getInstitutionListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getInstitutionListUrl}clientid=${clientId}`;

        Get(url, getInstitutionServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching institution list.", err);
                reject(err);
            }
        })
    });


    return getInstitutionListPromise;


}

//export var getInstitutionServiceCancellationToken = null;

//export const getInstitutionService = (clientOrgId) => {

//    if (getInstitutionServiceCancellationToken !== null && getInstitutionServiceCancellationToken !== undefined) {
//        getInstitutionServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
//    }

//    //Create a new cancellation token
//    const cancelToken = axios.CancelToken;
//    getInstitutionServiceCancellationToken = cancelToken.source();

//    let getInstitutionListPromise = new Promise(function (resolve, reject) {
//        let url = `${apiBaseUrl()}/${getInstitutionListUrl}`;

//        Get(url, getInstitutionServiceCancellationToken).then(
//            response => {
//                if (response) {
//                    resolve(response);
//                } else {
//                    reject(MsgForFailedData);
//                }
//            }
//        ).catch(err => {
//            if (axios.isCancel(err)) {
//                console.log(err);
//            } else {
//                console.log("Error Occured While Fetching institution list.", err);
//                reject(err);
//            }
//        })
//    });


//    return getInstitutionListPromise;


//}

export var getAccountSubaccountServiceCancellationToken = null;

export const getAccountSubaccountService = (clientId) => {

    if (getAccountSubaccountServiceCancellationToken !== null && getAccountSubaccountServiceCancellationToken !== undefined) {
        getAccountSubaccountServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getAccountSubaccountServiceCancellationToken = cancelToken.source();

    let getAccountSubaccountListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getAccountSubaccountListUrl}?clientid=${clientId}`;

        Get(url, getAccountSubaccountServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching Subaccount list.", err);
                reject(err);
            }
        })
    });


    return getAccountSubaccountListPromise;
}

export var getMainAccountServiceCancellationToken = null;

export const getMainAccountService = () => {

    if (getMainAccountServiceCancellationToken !== null && getMainAccountServiceCancellationToken !== undefined) {
        getMainAccountServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getMainAccountServiceCancellationToken = cancelToken.source();

    let getMainAccountListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getMainAccountListUrl}`;

        Get(url, getMainAccountServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching main account list.", err);
                reject(err);
            }
        })
    });


    return getMainAccountListPromise;

}

export var getTransactionListServiceCancellationToken = null;

export const getTransactionListService = () => {

    if (getTransactionListServiceCancellationToken !== null && getTransactionListServiceCancellationToken !== undefined) {
        getTransactionListServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getTransactionListServiceCancellationToken = cancelToken.source();

    let getTransactionListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getTransactionListUrl}`;

        Get(url, getTransactionListServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching transaction list.", err);
                reject(err);
            }
        })
    });


    return getTransactionListPromise;

} 

export var getTransactionsSourceDataServiceCancellationToken = null;

export const getTransactionsSourceDataService = (Account) => {

    if (getTransactionsSourceDataServiceCancellationToken !== null && getTransactionsSourceDataServiceCancellationToken !== undefined) {
        getTransactionsSourceDataServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getTransactionsSourceDataServiceCancellationToken = cancelToken.source();

    let getTransactionsSourceDataPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getTransactionsSourceDataUrl}?AccountList=${Account}&DateRange=Last,90`;
        //let url = `https://api.dev.rockco.com/activityapi/odata/activitysummary?AccountList=${Account}&DateRange=Last,90`;
        Get(url, getTransactionsSourceDataServiceCancellationToken).then(
            res => {
                if (res.data) {
                    resolve(res.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching Transactions for Source Data.", err);
                reject(err);
            }
        })
    });


    return getTransactionsSourceDataPromise;


}

export var getTransactionsAccountingDataServiceCancellationToken = null;

export const getTransactionsAccountingDataService = (id,subaccount) => {

    if (getTransactionsAccountingDataServiceCancellationToken !== null && getTransactionsAccountingDataServiceCancellationToken !== undefined) {
        getTransactionsAccountingDataServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getTransactionsAccountingDataServiceCancellationToken = cancelToken.source();

    let getTransactionsAccountingDataPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getTransactionsAccountingDataUrl}?id=${id}&subaccount=${subaccount}`;
        //let url = `https://api.dev.rockco.com/trustapi/api/Transactions/GetProcessedTransactions?id=test&subaccount=test`;
        Get(url, getTransactionsSourceDataServiceCancellationToken).then(
            res => {
                if (res.data) {
                    resolve(res.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching Transactions for Source Data.", err);
                reject(err);
            }
        })
    });


    return getTransactionsAccountingDataPromise;


}
