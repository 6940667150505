import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import RCMNinthWaveWidget, {ThemeType, AccountSelectionMode} from 'rcmninthwavewidget'
import { useSelector } from 'react-redux';
const NinthWavePopup = ({ openNinthwaveModelPopup, toggleNinthwaveModelPopup}) => {

    const {token} = useSelector(store => store.app)
    const {REACT_APP_API_BASEURL, REACT_APP_NW_DbUrl, APPINSIGHTS_INSTRUMENTATIONKEY} = useSelector(store => store.app.env)
    const {userName, name} =  useSelector(store => store.app.user)
    const splittedName = name ? name.split(',') : []
    const firstName = splittedName[0] ? splittedName[0]: ''
    const lastName = splittedName[1] ? splittedName[1] : ''
    return (
        <>
            <Modal isOpen={openNinthwaveModelPopup} toggle={toggleNinthwaveModelPopup} key="manageAlerts1"
                backdrop={false}
                size='lg'
                zIndex='9999'
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                centered>
                <ModalHeader toggle={toggleNinthwaveModelPopup} charCode="X" key="manageAlerts2">
                    <label className="RCM_tile_heading"  key="manageAlerts3">LINK ACCOUNT
                    </label>
                </ModalHeader>
                <ModalBody className="RCM_ninthWave_popup" key="manageAlerts4">
                    <RCMNinthWaveWidget
                        firstname={firstName}
                        lastname={lastName}
                        theme={ThemeType.DARK}
                        emailId={userName}
                        token={token}
                        azureAdId=''
                        nwDbUrl={REACT_APP_NW_DbUrl}
                        apiBaseUrl={REACT_APP_API_BASEURL}
                        acctSelectionMode={AccountSelectionMode.SINGLE}
                        appInsightKey={APPINSIGHTS_INSTRUMENTATIONKEY}
                    />
                </ModalBody>
            </Modal> 
        </>
    )
}
export default NinthWavePopup;