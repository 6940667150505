
import { ADD_WIRE_INSTRUCTION, UPDATE_CLIENT_VENDORLIST } from "./actionTypes";

export const ViewWireInstructions = (row, mode) => (dispatch) => {
    return Promise.resolve(
         dispatch({
             type: ADD_WIRE_INSTRUCTION,
             payload: row,
             mode: mode
         })
     )
 }

 export const UpdateClientVendorsList = (list) => (dispatch) => {
    return Promise.resolve(
        dispatch({
            type: UPDATE_CLIENT_VENDORLIST,
            payload: list
        })
    )
 }