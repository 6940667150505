import React, { useState, useEffect } from 'react';
import { MsgForNoData } from '../../../common/constants';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Spinner } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { arrayFilterByValue } from './../../../common/constants';

const SelectValuesPopup = ({ isSelectValuesPopupOpen, toggleSelectValuesPopup, handleValueChange, selectValuesPopupData,isTransactionType }) => {
    const [filterKey, setFilterKey] = useState(""); 
    const [gridData, setGridData] = useState([]); 
    useEffect(() => {
        setGridData(selectValuesPopupData.data);
        setFilterKey(prevFilterKey => "");
    }, [selectValuesPopupData]);
    const handleAccountFilter = (e) => {
        let value = e.target.value
        let filteredAcct = arrayFilterByValue(selectValuesPopupData.data, value);
        setFilterKey(pervFilterKey => value);
        setGridData(filteredAcct);
    }
    const clearAccountFilter = () => {
        let filteredAcct = arrayFilterByValue(selectValuesPopupData.data, "");
        setFilterKey(pervFilterKey => "");
        setGridData(filteredAcct);
    }
    const getGridColumn = (handleValueChange) => {
        const institutionColumn = [
            {
                dataField: 'institutionname',
                text: 'Institution Name',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: '',
                text: '',
                sort: false,
                isDummyField: true,
                headerAlign: 'right',
                headerClasses: 'RCM_noWrap',

                formatter: (cell, row) => {
                    return (<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => handleValueChange(row)}>SELECT</button>
                    </div>);
                }
            }
        ];
        const mainAccountColumn = [
            {
                dataField: 'mainaccountid',
                text: 'Main Account #',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: 'name',
                text: 'Main Account Name',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: 'description',
                text: 'Description',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            }, 
            {
                dataField: '',
                text: '',
                sort: false,
                isDummyField: true,
                headerAlign: 'right',
                headerClasses: 'RCM_noWrap',

                formatter: (cell, row) => {
                    return (<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => handleValueChange(row)}>SELECT</button>
                    </div>);
                }
            }
        ];
        const subAccountColumn = [
            {
                dataField: 'subaccountno',
                text: 'Sub Account #',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: 'subaccountname',
                text: 'Sub Account Name',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: '',
                text: '',
                sort: false,
                isDummyField: true,
                headerAlign: 'right',
                headerClasses: 'RCM_noWrap',

                formatter: (cell, row) => {
                    return (<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => handleValueChange(row)}>SELECT</button>
                    </div>);
                }
            }
        ];
        const payeeColumn = [
            {
                dataField: 'rcmPayeeId',
                text: 'Payee Id',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: 'name',
                text: 'Payee Name',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: '',
                text: '',
                sort: false,
                isDummyField: true,
                headerAlign: 'right',
                headerClasses: 'RCM_noWrap',

                formatter: (cell, row) => {
                    return (<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => handleValueChange(row)}>SELECT</button>
                    </div>);
                }
            }
        ];
        const transactionColumn = [
            {
                dataField: 'trancode',
                text: 'Transaction code',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: 'tranname',
                text: 'Name',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return <div>{cell}</div>;
                }
            },
            {
                dataField: '',
                text: '',
                sort: false,
                isDummyField: true,
                headerAlign: 'right',
                headerClasses: 'RCM_noWrap',

                formatter: (cell, row) => {
                    return (<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => handleValueChange(row)}>SELECT</button>
                    </div>);
                }
            }
        ];

        if (selectValuesPopupData.source === 'Institution')
            return institutionColumn;
        else if (selectValuesPopupData.source === 'MainAccounts')
            return mainAccountColumn;
        else if (selectValuesPopupData.source === 'SubAccounts')
            return subAccountColumn;
        else if (selectValuesPopupData.source === 'Transaction')
            return transactionColumn;
        else 
            return payeeColumn;
    }
    function customCaretSort(order, column) {
        if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
        else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
        else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
        return null;
    }
    const getKey = (selectValuesPopupData)=>{
        if (selectValuesPopupData.source === 'Institution') return 'institutionname';
        else if (selectValuesPopupData.source === 'MainAccounts') return 'mainaccountid';
        else if (selectValuesPopupData.source === 'SubAccounts') return 'subaccountno';
        else if (selectValuesPopupData.source === 'Payee') return 'rcmPayeeId';
        else if (selectValuesPopupData.source === 'Transaction') return 'trancode';
        else return 'institutionname';
    }
    return (
        <>
            <Modal isOpen={isSelectValuesPopupOpen} toggle={toggleSelectValuesPopup}
                className={"RCM_CM_modal" + ((selectValuesPopupData.source === 'SubAccounts' || selectValuesPopupData.source === 'Payee')
                    ? ' RCM_CM_modal_subaccounts ' : '')}
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                zIndex='9999'
                size={selectValuesPopupData.source === 'MainAccounts' ? 'lg' : ''}
                centered >
                <ModalHeader toggle={toggleSelectValuesPopup} className="RCM_externalAssetModel_header RCM_manage_group_header">
                    {selectValuesPopupData.source === 'Institution' ?
                        <label className="RCM_tile_heading">SELECT BANK/CUSTODIAN</label>
                        : (selectValuesPopupData.source === 'MainAccounts') ? <label className="RCM_tile_heading">SELECT MAIN ACCOUNT</label>
                            : (selectValuesPopupData.source === 'SubAccounts') ? <label className="RCM_tile_heading">SELECT SUB ACCOUNT </label>
                                : (selectValuesPopupData.source === 'Payee') ? <label className="RCM_tile_heading">SELECT PAYEE </label>
                                    : (selectValuesPopupData.source === 'Transaction') ? <label className="RCM_tile_heading">SELECT TRANSACTION </label>
                                        : ''
                    }
                </ModalHeader>
                <ModalBody>
                    <>
                        <div className="RCM_Rules_Search_wrapper" >
                            <input
                                type="text"
                                className="RCM_form_control RCM_form_control_search"
                                placeholder={selectValuesPopupData.source === 'Institution' ? "Search for your Bank/Custodian"
                                    : selectValuesPopupData.source === 'Payee' ? "Search for your Payee"
                                        : selectValuesPopupData.source === 'Transaction' ? "Search for your Transaction" : "Search for your Account"}
                                onChange={(e) => handleAccountFilter(e)}
                                value={filterKey}
                                
                            />
                            <button className="RCM_Textbox_close-icon"
                                type="reset"
                                onClick={() => clearAccountFilter()}
                            ></button>
                        </div>

                        {
                            gridData ?
                                <div id="RCM_SubAccounts_Table" className="RCM_SubAccounts_Table RCM_table_scorllable_body">
                                    <BootstrapTable
                                        keyField={getKey(selectValuesPopupData)}
                                        data={gridData}
                                        columns={getGridColumn(handleValueChange)}
                                        headerClasses="row-bg"
                                        bordered={false}
                                        classes="RCM_two_level_table1"
                                        rowClasses="RCM_tow_level_table1_row_animation"
                                        noDataIndication={MsgForNoData}
                                    />
                                </div>
                                : <div key="ASDropdown22">{MsgForNoData}</div>

                        }
                    </>
                </ModalBody>
                <ModalFooter className="RCM_manage_group_footer">
                    <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={() => toggleSelectValuesPopup()}>CANCEL</button>

                </ModalFooter>
            </Modal>

        </>
    )
}
export default SelectValuesPopup;