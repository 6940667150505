import { ApplicationInsights } from  '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { store } from '..';
import { getNestedObject } from './common';

export class TelemetryService {
    constructor() {
         let sessionInstrumentationKey =   getNestedObject(store.getState(), ['app', 'env', 'APPINSIGHTS_INSTRUMENTATIONKEY']);
         if(sessionInstrumentationKey){
           this.instrumentationKey = sessionInstrumentationKey;
         }
        const browserHistory = createBrowserHistory({ basename: '' });
        this.reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: true,
                disableAjaxTracking: true,
                isStorageUseDisabled: true
            },
            extensions: [this.reactPlugin],
            extensionConfig: {
                [this.reactPlugin.identifier]: { history: browserHistory }
            }
        });
        this.appInsights.loadAppInsights();
       
    }
}
//export let ai = new TelemetryService();