import React from 'react';
import { Steps } from 'antd';
import 'antd/dist/antd.css';

const { Step } = Steps;

export default function DiscretionarydistributionSubmitProgress(props) {
    return (

        <div className="RCM_CM_Overlay">
            <div className="RCM_CM_Overlay_body" align="center">
                <div className="RCM_CM_Overlay_spinner mb-2"></div>
                <Steps current={props.currentProcess} status={props.currentProcessStatus} >
                    <Step title="Add/Update Distribution"  />
                    <Step title="Generate Document"  />
                    <Step title="Generate Docusign id" />
                    <Step title="Finished" />
                </Steps>
            </div>
        </div>
    )
}
