import { INSERT_TOKEN, INSERT_ENV_VARIABLES, REFRESH, ADD_FA, INSERT_FA_PREFERENCE, UPDATE_SELECTED_MENU, UPDATE_SSO_WINDOW } from "../actions/actionTypes";

const initialState = {
    token: null,
    env: [],
    refresh:false,
    user: {},
    defaultSelectedMenu: null,
    ssoWindow: null
}

export function AppReducer(state=initialState, action){

    switch(action.type)
    {

        case INSERT_TOKEN:
        {
            return {
                ...state, 
                token : action.payload
            }
        }

        case INSERT_ENV_VARIABLES:{
            return {
                ...state,
                env: action.payload
            }
        }
        case REFRESH: {
            return {
                ...state,
                refresh: action.payload
            }
        }
        case ADD_FA: {
            return{
                ...state,
                user: action.payload
            }
        }

        case INSERT_FA_PREFERENCE:{
            return {
                ...state,
                faFeatures: action.payload
            }
        }

        case UPDATE_SELECTED_MENU:{
            return {
                ...state,
                defaultSelectedMenu: action.payload
            }
        }

        case UPDATE_SSO_WINDOW:{
            return {
                ...state,
                ssoWindow: action.payload
            }
        }

        

        default:
        return state;
    }
}