import React, { Component } from 'react'
import { Route, Switch } from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';
import WiresList from './wiresdetails/wireslist'
import ClientVisibility from './clientVisibility/clientVisibility';
import Accounting from './../accounting/accounting';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import FailedPayments from './failedpayments/failedpayments';
import PaymentApprovers from './paymentapprovers/paymentApprovers';
import PaymentAgreement from './paymentagreement/paymentAgreement';
import Notfound from '../../common/notfound';

const { TabPane } = Tabs;

class Admininstrator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ActiveKey: this.getActiveTab()
        }

        this.onTabChanges = this.onTabChanges.bind(this);
        this.getActiveTab = this.getActiveTab.bind(this);
    }

    getActiveTab = () => {
        const currentRoute = this.props.history.location.pathname;
        let activeKey = "1";
        if (currentRoute === `${this.props.match.url}/clientVisibility` || currentRoute === `${this.props.match.url}`)
            activeKey = "1";
        else if (currentRoute === `${this.props.match.url}/payments`)
            activeKey = "2";
        else if (currentRoute === `${this.props.match.url}/accounting` 
                || currentRoute === `${this.props.match.url}/accounting/subAccount` 
                || currentRoute === `${this.props.match.url}/accounting/payees` 
                || currentRoute === `${this.props.match.url}/accounting/postingRules` 
                || currentRoute === `${this.props.match.url}/accounting/transactions`)
            activeKey = "3";
        else if (currentRoute === `${this.props.match.url}/failedpayments`)
            activeKey = "4";
        else if (currentRoute === `${this.props.match.url}/paymentApprovers`)
            activeKey = "5";  
        else if (currentRoute === `${this.props.match.url}/clientSettings`)
            activeKey = "6";  
        return activeKey;
    }

    onTabChanges(activetabKey) {
        switch (activetabKey) {
            case "1": {
                this.props.history.push(this.props.match.url + '/clientVisibility');
                break;
            }

            case "2": {
                this.props.history.push(this.props.match.url + '/payments');
                break;
            }
            case "3": {
                this.props.history.push(this.props.match.url + '/accounting');
                break;
            }
            case "4": {
                this.props.history.push(this.props.match.url + "/failedpayments");
                break;
            }
            case "5": {
                this.props.history.push(this.props.match.url + "/paymentApprovers");
                break; 
            }
            case "6": {
                this.props.history.push(this.props.match.url + "/clientSettings");
                break;
            }
            default: {
                break;
            }
        }
    }

    componentDidMount() {
        this.unregisterRouteListner = this.props.history.listen(() => {
            const activeKeyTab = this.getActiveTab()
            this.setState({...this.state, ActiveKey: activeKeyTab})
        })
    }

    componentWillUnmount() {
        if(this.unregisterRouteListner) {
            this.unregisterRouteListner();
        }
    }

    render() {
        return (
            <div>
                <Tabs activeKey={this.state.ActiveKey} animated={false} onChange={this.onTabChanges} >
                    <TabPane tab="CLIENT VISIBILITY" key="1">

                    </TabPane>
                    <TabPane tab="PAYMENTS" key="2">

                    </TabPane>
                    <TabPane tab="ACCOUNTING" key="3">

                    </TabPane>
                    <TabPane tab="PAYMENTS FOR ACTION" key="4" >

                    </TabPane>
                    <TabPane tab="PAYMENT APPROVERS" key="5" >

                    </TabPane>
                    <TabPane tab="CLIENT SETTINGS" key="6" >

                    </TabPane>
                </Tabs>
                {
                    <Switch>
                        <Route exact path={this.props.match.url} render={() => <Redirect to={`${this.props.match.url}/clientVisibility`} />} />
                        <Route exact path={`${this.props.match.url}/clientVisibility`} component={() => (<ClientVisibility id='clientvisibility' ActiveKey={this.state.ActiveKey} />)} />
                        <Route exact path={`${this.props.match.url}/payments`} component={() => (<WiresList id="paymentlist" ActiveKey={this.state.ActiveKey} />)} />
                        <Route exact={false} path={`${this.props.match.url}/accounting`} component={() => (<Accounting ActiveKey={this.state.ActiveKey} history={this.props.history} />)} />
                        <Route exact path={`${this.props.match.url}/failedPayments`} component={() => (<FailedPayments ActiveKey={this.state.ActiveKey} history={this.props.history} />)} />
                        <Route exact path={`${this.props.match.url}/paymentApprovers`} component={() => (<PaymentApprovers ActiveKey={this.state.ActiveKey} history={this.props.history} />)} />
                        <Route exact path={`${this.props.match.url}/clientSettings`} component={() => (<PaymentAgreement ActiveKey={this.state.ActiveKey} history={this.props.history} />)} />
                        <Route path="*" component={Notfound} />
                    </Switch>
                }
            </div>
        )
    }
}

export default withRouter(Admininstrator);