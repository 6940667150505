import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Switch } from 'react-router';
import { ADD_ENV_VARIABLES, ADD_TOKEN, ADD_FADetail, UPDATE_SELECTED_MENU_VALUE } from './actions/appActions';
import { getfaFeatures } from './services/appServices/appService';
import 'antd/dist/antd.css';
import "./App.css";
import ErrorBoundary from './components/error/errorBoundary';
import packageJson from '../package.json';
import Layout from './components/Layout';
import Home from './components/Home';
import { Drawer } from 'antd';
import { Spinner } from 'reactstrap';
import { INSERT_FA_FEATURES } from './actions/appActions';
import { _logInformation, _logException } from './common/_logging';
import Notfound from './common/notfound';
import { Router } from 'react-router-dom';
import ClientOnlineMsalProvider, {
    getLoggedInUser,
    isTokenRefreshRequired
  } from "./system/MsalUtilities";
import { getApplicationRoutes } from './AppRoutes';

  export var clientOnlineProvider = null;
export var authProvider = null;
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: false,
            showAuthError: false,
            showTaskPlanner: false,
            routeList: [],
            userEmail: ''
        }
        this.loginUser = this.loginUser.bind(this);
        this.showDrawer = this.showDrawer.bind(this);
        this.setTokenRefreshListner = this.setTokenRefreshListner.bind(this)
    }


    componentDidMount() {
        window.addEventListener('showUserTaskPlanner', this.showDrawer);
        console.log(`Code Version : ${packageJson.version}`);
        this.props.ADD_ENV_VARIABLES(this.props.environment).then(afterEnv => {
            _logInformation(this.props.env)
            setTimeout(() => {
                this.loginUser();
            }, 500);
            this.setTokenRefreshListner()
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.faFeatures !== prevProps.faFeatures) {
            //set the state to refresh screen
            this.setState({ reRender: true });
        }
    }

    setTokenRefreshListner() {
      let intervalTime = 5 * 60 * 1000;
      setInterval(() => {
        if (isTokenRefreshRequired(this.props.token, intervalTime)) {
          console.log("Token is about to expire, renewing the token");
          this.silentLogin(this.state.userEmail);
        }
      }, intervalTime);
    }

  //create a function to login FA's
  loginUser() {
    //create UserAgent

    clientOnlineProvider = new ClientOnlineMsalProvider();
    authProvider = clientOnlineProvider.getMsalInstance(this.props.env);
    authProvider
      .handleRedirectPromise()
      .then((res) => {
        if (res) {
          this.processAfterAquiringToken(res);
        } else {
          const accounts = authProvider.getAllAccounts();
          if (accounts && accounts.length > 0) {
            console.log("User exist in cache");
            this.setState(prevState=> ({...prevState, userEmail: accounts[0].username}))
            authProvider
              .acquireTokenSilent({
                account: accounts[0],
                forceRefresh: true,
                scopes: [this.props.env.REACT_APP_AZURE_APISCOPE],
              })
              .then((silentres) => {
                this.processAfterAquiringToken(silentres);
              })
              .catch((err) => {
                console.error("silent failed", err);
                this.silentLogin(accounts[0].username);
              });
          } else {
            //user is coming first time
            console.log("User is coming first time");
            clientOnlineProvider.interactionBasedLogin(undefined, [
              this.props.env.REACT_APP_AZURE_APISCOPE,
            ]);
          }
        }
      })
      .catch((err) => {
        //show error page
        console.error(err);
        _logException(err);
        if (err.name === "InteractionRequiredAuthError") {
          clientOnlineProvider.interactionBasedLogin(
            undefined,
            [this.props.env.REACT_APP_AZURE_APISCOPE],
            "select_account"
          );
        } else {
          alert(
            "We are expirencing some difficulty to load our application, please try after sometime."
          );
        }
      });
  }

  silentLogin(loginHint) {
    clientOnlineProvider
      .ssoLogin(loginHint, [this.props.env.REACT_APP_AZURE_APISCOPE])
      .then((silentRes) => {
        this.processAfterAcquiringToken(silentRes);
      })
      .catch((err) => {
        console.log("Silent login Error", err);
        _logException(err);
        clientOnlineProvider.interactionBasedLogin(
          loginHint,
          [this.props.env.REACT_APP_AZURE_APISCOPE]
        );
      });
  }

  processAfterAquiringToken(res) {
    const user = getLoggedInUser(res.accessToken);
    const faEmail = user.userName ? user.userName : "";
    this.props.ADD_TOKEN(res.accessToken);
    this.props.ADD_FADetail(user);
    this.fetchPrefernces(faEmail);
  }

  fetchPrefernces = (emailId) => {
    getfaFeatures(emailId)
      .then((res) => {
        let featuredata = res && res.data ? res.data : [];
        _logInformation(featuredata);
        this.props.INSERT_FA_FEATURES(featuredata);
        let routes = getApplicationRoutes(featuredata);
        this.setState({ loginSuccess: true, routeList: routes });
      })
      .catch((err) => {
        console.error(err);
      });
  };


    showDrawer() {
        this.setState({ showTaskPlanner: true });
    }

    onCloseTaskPlanner() {
        this.setState({ showTaskPlanner: false });
    }

    render() {
        return this.state.loginSuccess ? (
          <ErrorBoundary>
            <Router history={this.props.history}>
              <Suspense
                fallback={
                  <div align="center">Loading Rockefeller Payment Hub...</div>
                }
              >
                <Layout>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    {this.state.routeList.map((appRoute) => {
                      return appRoute;
                    })}
                    <Route path="*" component={Notfound} />
                  </Switch>
                </Layout>
              </Suspense>
            </Router>
            <Drawer
              className="RCM_TaskPlanner_Drawer"
              placement="right"
              closable={true}
              destroyOnClose={true}
              visible={this.state.showTaskPlanner}
              onClose={() => this.onCloseTaskPlanner()}
              width={
                window.innerWidth <= 800 ? window.innerWidth : window.innerWidth / 2
              }
            >
              <div className="mt-4">Show user related information</div>
            </Drawer>
          </ErrorBoundary>
        ) : this.state.showAuthError ? (
          <div align="center">
            You are not authorized to access, please contact support
          </div>
        ) : (
          <div align="center">
            <div id="RCM_Loading_Logo" className="RCM_Loading_Logo">
              <span>
                R
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="info"
                  className="RCM_Loading_Logo_Spinner"
                ></Spinner>
              </span>
            </div>
          </div>
        );
      }
    }

const mapStateToProps = store => {
    return {
        env: store.app.env,
        token: store.app.token
    };
};

const mapDispatchToProps = {
    ADD_ENV_VARIABLES,
    ADD_TOKEN,
    ADD_FADetail,
    INSERT_FA_FEATURES,
    UPDATE_SELECTED_MENU_VALUE
}

const enhancement = compose(
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhancement(App);