import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DashBoardView from './DashBoardView';
import { GetPaymentListService, GetPaymentListServiceCancellationToken, getContextualMenuItems, billDotComTrackingSsoLogService } from '../../services/billpayservice/billpayservices';
import cloneDeep from 'lodash/cloneDeep';
import { UpdateSelectedClient } from '../../actions/dashboardAction';
import { UPDATE_SSO_WINDOW_INSTANCE, UPDATE_SELECTED_MENU_VALUE } from '../../actions/appActions';
import { animateViewTable } from '../../common/animation';
import { arrayFilterByValue } from '../../common/utilities';
import { Spinner } from 'reactstrap';
import { BackTop, message } from 'antd';

class DashBoard extends Component {

    constructor(props) {
        super(props);
        this.viewInvoice = this.viewInvoice.bind(this);
        this.uploadInvoice = this.uploadInvoice.bind(this);
        this.goToVendors = this.goToVendors.bind(this);
        this.goToSubAccounts = this.goToSubAccounts.bind(this);

        this.state = {
            openClientsPayments: false,
            searchString: "",
            clientsList: [],
            filteredClientsList: [],
            showloading: false,
            openSubAccounts: false,
            activeClient: {},
            contextualMenu: []
        };

        this.fetchPaymentList = this.fetchPaymentList.bind(this);
        this.openInboxWindow = this.openInboxWindow.bind(this);
    }

    componentDidMount() {
        this.fetchPaymentList();
        this.fetchContextualMenuItems();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refresh !== this.props.refresh) {
            this.fetchPaymentList();
        }
    }

    componentWillUnmount() {
        if (GetPaymentListServiceCancellationToken !== null && GetPaymentListServiceCancellationToken !== undefined) {
            GetPaymentListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
        }
    }

    viewInvoice = rows => {
        alert("in viewInvoice function");
    }

    uploadInvoice = row => {

        this.props.UpdateSelectedClient(row).then(res => {
            if (this.props.history && row && row.orgId) {
                this.props.history.push(`/uploadInvoice/${row.orgId}`)
            }
        });
    }

    backFromUploadInvoice = () => {
        this.setState({
            openUploadInvoice: false
        })
    }

    goToVendors = row => {
        this.props.UpdateSelectedClient(row).then(res => {
            if (this.props && this.props.history && row && row.orgId) {
                this.props.history.push(`/vendors/${row.orgId}`)
            }
        });
    }

    goToPayments = rows => {
        this.setState({
            openClientsPayments: true, activeClient: rows
        })

    }

    backFromPayments = () => {
        this.setState({
            openClientsPayments: false
        })
    }

    handleFormChanges = (evt) => {
        let searchString = evt.target.value;
        let updatedClientList = cloneDeep(this.state.clientsList);
        let searchKeyList = ['name', 'inboxCnt', 'pendingApprovalCnt', 'pendingPayCnt'];
        updatedClientList = arrayFilterByValue(updatedClientList, searchString, searchKeyList);
        this.setState({ filteredClientsList: updatedClientList, searchString: searchString });
    }

    openInboxWindow(deeplinkUrl, ssoUrl) {

        //Have async logging call 
        billDotComTrackingSsoLogService()
        .catch(err => {
            console.log(err)
        });

        let windowObj = this.props.ssoWindow;

        if (windowObj) {
            if (windowObj.closed) {
                windowObj = window.open(ssoUrl, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width=' + window.screen.availWidth + ",height=" + window.screen.availHeight);
                this.props.UPDATE_SSO_WINDOW_INSTANCE(windowObj);
            }
            else {
                windowObj = window.open(deeplinkUrl, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width=' + window.screen.availWidth + ",height=" + window.screen.availHeight);
                this.props.UPDATE_SSO_WINDOW_INSTANCE(windowObj);
            }
        } else {
            windowObj = window.open(ssoUrl, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width=' + window.screen.availWidth + ",height=" + window.screen.availHeight);
            this.props.UPDATE_SSO_WINDOW_INSTANCE(windowObj);
        }


        if (windowObj) {
            windowObj.focus();
        }

    }




    fetchPaymentList() {
        this.setState({ showloading: true }, () => {
            //gsap.to('.RCM_two_level_table1', {duration: 0 , opacity: 0});
            setTimeout(() => {
                GetPaymentListService(this.props.user).then(res => {
                    if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {

                        this.setState({ clientsList: res.data, filteredClientsList: res.data, showloading: false }, () => {
                            animateViewTable();
                        });
                    } else {
                        this.setState({ showloading: false }, () => {
                            animateViewTable();
                        })
                    }
                })
                    .catch(err => {
                        this.setState({ showloading: false, clientsList: [], filteredClientsList: [] }, () => {
                            animateViewTable();
                            message.error("We are unable to fetch payments information, you can try again by using refresh option.");
                        })
                        console.error("Error Occured while fetching the user vendor list");
                    })
            }, 500);
        });
    }

    fetchContextualMenuItems() {
        getContextualMenuItems().then(res => {
            this.setState({contextualMenu: res})
        })
        .catch(err => {
            console.error(err)
        })
    }

    goToSubAccounts = rows => {
        this.state.activeClient = { ...rows };
        this.props.UpdateSelectedClient(rows).then(res => {
            this.props.UPDATE_SELECTED_MENU_VALUE("2").then(() => {
                if (this.props.history && rows && rows.orgId) {
                    this.props.history.push(`/subAccount`)
                }
            })
        });

    }

    backSubAccounts = () => {
        this.setStat(prevState => ({
            openSubAccounts: false
        }));
    }

    render() {

        if (this.state.showloading) {
            return (
                <div align="center" className="mt-4">Loading Payment DashBoard
                &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                </div>);
        }
        else {
            return (
                <div id="dashboard">
                    <BackTop />
                    <DashBoardView state={this.state}
                        viewInvoice={this.viewInvoice}
                        goToVendors={this.goToVendors}
                        uploadInvoice={this.uploadInvoice}
                        backFromPayments={this.backFromPayments}
                        goToPayments={this.goToPayments}
                        handleFormChanges={this.handleFormChanges}
                        openInboxWindow={this.openInboxWindow}
                        goToSubAccounts={this.goToSubAccounts}
                        contextualMenu={this.state.contextualMenu}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
        ssoWindow: store.app.ssoWindow,
        env: store.app.env
    };
}

const mapDispatchToProps = {
    UpdateSelectedClient,
    UPDATE_SSO_WINDOW_INSTANCE,
    UPDATE_SELECTED_MENU_VALUE
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoard));