import axios from 'axios';
import { MsgForFailedData } from '../../common/constants';
import { apiBaseUrl, getFailedPaymentListUrl, updateFailedPaymentUrl } from '../serviceConstant';
import { Get, Post } from '../servicecalls';

export var getFailedPaymentsCancellationToken = null;
export var retryFailedPaymentsCancellationToken = null;

export const getFailedPaymentList = () => {
    
    if (getFailedPaymentsCancellationToken !== null && getFailedPaymentsCancellationToken !== undefined) {
        getFailedPaymentsCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getFailedPaymentsCancellationToken = cancelToken.source();

    let getFailedPaymentPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getFailedPaymentListUrl}?pkid=1`;
        Get(url, getFailedPaymentsCancellationToken).then(
            failedPaymentRes => {
                //console.log(wiresListRes);
                if(failedPaymentRes.data ){
                    resolve(failedPaymentRes.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching failed payment data", err);
                reject(err);
            }
        })
    });


    return getFailedPaymentPromise;


}


export const updateFailedPayment = (row) => {
    if (retryFailedPaymentsCancellationToken !== null && retryFailedPaymentsCancellationToken !== undefined) {
        retryFailedPaymentsCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    retryFailedPaymentsCancellationToken = cancelToken.source();

    let retryFailedPaymentPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${updateFailedPaymentUrl}?action=${row && row.nextActions && Array.isArray(row.nextActions) && row.nextActions.length > 0 && row.nextActions[0].actionCode ? row.nextActions[0].actionCode : ''}`;
        const data = {
            clientId: row && row.clientId ? row.clientId : undefined,
            id: row && row.id ? row.id : undefined,
            StatusCode: row && row.statusCode ? row.statusCode : undefined,
            StatusDesc: row && row.statusDesc ? row.statusDesc : undefined,
            Processor: row && row.processor ? row.processor : undefined,
            ConfirmationType: row && row.confirmationType ? row.confirmationType : undefined,
            ConfirmationId: row && row.confirmationId ? row.confirmationId : undefined,
            CreatedOn: row && row.createdOn ? row.createdOn : undefined,
            UpdatedOn: row && row.updatedOn ? row.updatedOn : undefined,
            NextActions: row && row.nextActions && Array.isArray(row.nextActions) ? 
                         row.nextActions.map(action => {
                             return {
                                StatusCode: action && action.statusCode ? action.statusCode : undefined,
                                StatusName: action && action.statusName ? action.statusName : undefined,
                                ActionCode: action && action.actionCode ? action.actionCode : undefined,
                                ActionName: action && action.actionName ? action.actionName : undefined                    
                             }
                         })
                         : undefined
        }
        Post(url, data ,retryFailedPaymentsCancellationToken).then(
            updatePaymentRes => {
                //console.log(wiresListRes);
                if(updatePaymentRes.data ){
                    resolve(updatePaymentRes.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Updating failed payment data", err);
                reject(err);
            }
        })
    });


    return retryFailedPaymentPromise;

}