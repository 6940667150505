import React from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import AgreementDragDrop from './agreementDragDrop'

const PaymentAgreementLoaUploadPopup = ({selectedClient, fetchClientList, modalState, toggleModal, children}) => {
    return (
        <div>
            <Modal
                isOpen={modalState || false}
                toggle={toggleModal}
                backdrop={false}
                size='extralg'
                zIndex='9999'
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                centered>
                <ModalHeader toggle={toggleModal} charCode="X" ></ModalHeader>
                <ModalBody>
                    <>
                        {
                            children
                        }
                        {
                            <AgreementDragDrop 
                                selectedClient={selectedClient}  
                                fetchClientList={fetchClientList} />
                        }
                    </>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PaymentAgreementLoaUploadPopup
