import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { BackTop, message } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import { MsgForNoData, arrayFilterByValue } from '../../../common/constants';
import { getRuleGridColumn } from './ruleListColumns';
import PostingRulesView from './postingRulesView';
import { getNestedObject } from '../../../common/common';
import ClientListPopup from '../clientListPopup';
import SelectValuesPopup from './selectValuesPopup';

import {
    getVendorListService, getVendorListServiceCancellationToken,
    addEditRuleService, addEditRuleServiceCancellationToken,
    getRuleListService, getRuleServiceCancellationToken,
    getInstitutionService, getInstitutionServiceCancellationToken,
    getAccountSubaccountService, getAccountSubaccountServiceCancellationToken,
    getMainAccountService, getMainAccountServiceCancellationToken,
    getTransactionListService, getTransactionListServiceCancellationToken
} from './../../../services/accountingServices/accountingService';
import { animateViewTable } from '../../../common/animation';

const PostingRules = (props) => {
    const [ruleList, setRuleList] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [filterKey, setFilterKey] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);
    const [activeClient, setActiveClient] = useState({});
    const [isClientListPopupOpen, setIsClientListPopupOpen] = useState(false);
    const [activeRule, setActiveRule] = useState({});
    const [vendorList, setVendorList] = useState([]);
    const [mode, setMode] = useState("");
    const [isAddEditOpen, setIsAddEditOpen] = useState(false);
    const [isSelectValuesPopupOpen, setIsSelectValuesPopupOpen] = useState(false);
    const [institutionList, setInstitutionList] = useState([]);
    const [subAccountList, setSubAccountList] = useState([]);
    const [mainAccountList, setMainAccountList] = useState([]);
    const [selectValuesPopupData, setSelectValuesPopupData] = useState({});
    const selectedClient = useSelector(state => getNestedObject(state, ['dashboard', 'selectedClient']));
    const [modified, setModified] = useState(false);
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
        setActiveClient(prevActiveClient => { return { ...selectedClient } });
        return () => {
            if (getVendorListServiceCancellationToken !== null && getVendorListServiceCancellationToken !== undefined) {
                getVendorListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (addEditRuleServiceCancellationToken !== null && addEditRuleServiceCancellationToken !== undefined) {
                addEditRuleServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (getRuleServiceCancellationToken !== null && getRuleServiceCancellationToken !== undefined) {
                getRuleServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (getInstitutionServiceCancellationToken !== null && getInstitutionServiceCancellationToken !== undefined) {
                getInstitutionServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (getAccountSubaccountServiceCancellationToken !== null && getAccountSubaccountServiceCancellationToken !== undefined) {
                getAccountSubaccountServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (getMainAccountServiceCancellationToken !== null && getMainAccountServiceCancellationToken !== undefined) {
                getMainAccountServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (getTransactionListServiceCancellationToken !== null && getTransactionListServiceCancellationToken !== undefined) {
                getTransactionListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            } 
        }
    }, []);

    useEffect(() => {
        if (activeClient && activeClient.pKid) {
            setShowOverlay(prevShowOverlay => true);
            getRuleList(activeClient.pKid);
            getVendorList(activeClient.pKid);
            getInstitutionList();
            getSubAccountsList();
            getMainAccountsList();
            getTransactionList();
        }
    }, [activeClient]);

    useEffect(() => {
        setActiveClient(prevActiveClient => { return { ...selectedClient } });
        setFilterKey(pervFilterKey => "");
    }, [selectedClient]);
    useEffect(() => {
        if (activeRule.rulename !== undefined)
            saveRule();
    }, [modified]);

    const getRuleList = (pKid) => {
       
        getRuleListService(pKid).then(res => {

            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    let data = res.data;
                    setRuleList(data);
                    setGridData(data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get rule list, you can try again.");
                console.error("Error Occured while fetching vendors");
            }
            setShowOverlay(prevShowOverlay => false);

        })
            .catch(err => {
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable to get rule list, you can try again.");
                console.error("Error Occured while fetching vendors");
            })
    }
    const handleFilter = (e) => {
        let value = e.target.value
        let filteredRule = arrayFilterByValue(ruleList, value);
        setFilterKey(pervFilterKey => value);
        setGridData(filteredRule);
    }
    const clearFilter = () => {
        let filteredRule = arrayFilterByValue(ruleList, "");
        setFilterKey(pervFilterKey => "");
        setGridData(filteredRule);
    }
    const getVendorList = (entityId) => {
       
        getVendorListService(entityId).then(res => {

            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    let data = [{
                        legalEntityId: "",
                        name: "",
                        rcmPayeeId: '',
                        sid: ""
                    }, ...res.data];
                    setVendorList(data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get vendor list, you can try again.");
                console.error("Error Occured while fetching vendors");
            }

        })
            .catch(err => {
                animateViewTable();
                message.error("We are unable to get vendor list, you can try again.");
                console.error("Error Occured while fetching vendors");
            })
    }
    const getInstitutionList = () => {
        getInstitutionService(activeClient.pKid).then(res => {

            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    setInstitutionList(res.data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get institution list, you can try again.");
                console.error("Error Occured while fetching institutions");
            }

        })
            .catch(err => {
                animateViewTable();
                message.error("We are unable to get institution list, you can try again.");
                console.error("Error Occured while fetching institutions");
            })
    }
    const getSubAccountsList = () => {
        getAccountSubaccountService(activeClient.pKid).then(res => {

            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    setSubAccountList(res.data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get sub account list, you can try again.");
                console.error("Error Occured while fetching accounts");
            }

        })
            .catch(err => {
                animateViewTable();
                message.error("We are unable to get sub account list, you can try again.");
                console.error("Error Occured while fetching accounta");
            })
    }
    const getMainAccountsList = () => {
        getMainAccountService().then(res => {

            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    setMainAccountList(res.data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get main account list, you can try again.");
                console.error("Error Occured while fetching accounts");
            }

        })
            .catch(err => {
                animateViewTable();
                message.error("We are unable to get main account list, you can try again.");
                console.error("Error Occured while fetching accounts");
            })
    }
    const getTransactionList = () => {
        getTransactionListService().then(res => {

            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    setTransactions(res.data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get transaction list, you can try again.");
                console.error("Error Occured while fetching transactions");
            }

        })
            .catch(err => {
                animateViewTable();
                message.error("We are unable to get transactions list, you can try again.");
                console.error("Error Occured while fetching transactions");
            })
    }
    const toggleClientListPopup = () => {
        setIsClientListPopupOpen(prevIsClientListPopupOpen => !prevIsClientListPopupOpen);
    }

    const toggleAddEditPopup = () => {
        setIsAddEditOpen(prevAddEditOpen => !prevAddEditOpen);
        setActiveRule({});
        setMode("");
    }
    const toggleSelectValuesPopup = (source, mainSource="") => {
        setIsSelectValuesPopupOpen(prevIsSelectValuesPopupOpen => !prevIsSelectValuesPopupOpen);
        let data = [];
        if (source === 'Institution') {
            data = [...institutionList];
        } else if (source === 'SubAccounts') {
            data = [...subAccountList];
        } else if (source === 'MainAccounts') {
            data = [...mainAccountList];
        } else if (source === 'Transaction') {
            data = [...transactions];
        } else {
            data = [...vendorList];
        }
        let selectValuesPopupData = { source: source, data: data, mainSource: mainSource};
        setSelectValuesPopupData(selectValuesPopupData);
    }
    
    const handleValueChange = (selectedrow) => {
        if (selectValuesPopupData.source === 'Institution') {
            if (selectedrow.institutionname === 'All Institutions') {
                setActiveRule(prevActiveRule => {
                    return {
                        ...prevActiveRule, accountinstitutionid: null,
                        institutionname: selectedrow.institutionname,
                        isallinstitutions: (selectedrow.institutionname === 'All Institutions') ? 'Y' : 'N'
                    }
                });
            } else {
                setActiveRule(prevActiveRule => {
                    return {
                        ...prevActiveRule, accountinstitutionid: selectedrow.accountinstitutionid,
                        institutionname: selectedrow.institutionname,
                        isallinstitutions: (selectedrow.institutionname === 'All Institutions') ? 'Y' : 'N'
                    }
                });
            }
        }
        else if (selectValuesPopupData.source === 'SubAccounts') {
            setActiveRule(prevActiveRule => {
                return {
                    ...prevActiveRule,
                    subaccountid: selectedrow.subaccountid,
                    subaccountno: selectedrow.subaccountno,
                    subaccountname: selectedrow.subaccountname
                }
            });
        } else if (selectValuesPopupData.source === 'Payee') {
            setActiveRule(prevActiveRule => {
                return {
                    ...prevActiveRule,
                    vendorid: selectedrow.rcmPayeeId,
                    vendorname: selectedrow.name
                }
            });
        } else if (selectValuesPopupData.source === 'Transaction') {
            setActiveRule(prevActiveRule => {
                return {
                    ...prevActiveRule,
                    transactionname: selectedrow.tranname,
                    transactioncode: selectedrow.trancode,
                    vendortrantypeid: selectedrow.vendortrantypid
                }
            });
        }
        else if (selectValuesPopupData.source === 'MainAccounts') {
            if (selectValuesPopupData.mainSource === 'SourceMainAccount') {
                setActiveRule(prevActiveRule => {
                    return {
                        ...prevActiveRule, sourcecoaaccountno: selectedrow.mainaccountid,
                        sourcecoaaccountname: selectedrow.name
                    }
                });
            } else {
                setActiveRule(prevActiveRule => {
                    return {
                        ...prevActiveRule, coaaccountno: selectedrow.mainaccountid,
                        coaaccountname: selectedrow.name
                    }
                });
            }
        }
        setIsSelectValuesPopupOpen(prevIsSelectValuesPopupOpen => !prevIsSelectValuesPopupOpen);
        setSelectValuesPopupData({});
    }
    
    const handleFormChanges = evt => {
        let value = evt.target.value;
        if (evt.target.name === "rulename") {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, rulename: value } });
        } else if (evt.target.name === 'description') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, description: value } });
        } else if (evt.target.name === 'isrename') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, isrename: !prevActiveRule.isrename } });
            //if (!activeRule.isrename) {
            //    setActiveRule(prevActiveRule => { return { ...prevActiveRule, isrename: !prevActiveRule.isrename } });
            //} else {
            //    setActiveRule(prevActiveRule => { return { ...prevActiveRule, isrename: !prevActiveRule.isrename, renameddesc:''} });
            //}
        } else if (evt.target.name === 'renameddesc') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, renameddesc: value } });
        } else if (evt.target.name === 'coaaccountno') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, coaaccountno: value } });
        } else if (evt.target.name === 'subaccountno') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, subaccountno: value } });
        } else if (evt.target.name === 'sourcecoaaccountno') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, sourcecoaaccountno: value } });
        } else if (evt.target.name === 'iscondition') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, iscondition: !prevActiveRule.iscondition  } });
        } else if (evt.target.name === 'isTransactionType') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, isTransactionType: !prevActiveRule.isTransactionType } });
        } else if (evt.target.name === 'isexclude') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, isexclude: !prevActiveRule.isexclude } });
        }
    }
    const handleDropdownChange = (item, source) => {
        if (source === 'condition') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, condition: item } });
        } else if (source === 'vendorid') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, vendorid: item.rcmPayeeId, vendorname: item.name } });
        } else if (source === 'transaction') {
            setActiveRule(prevActiveRule => { return { ...prevActiveRule, transactionname: item.tranname, transactioncode: item.trancode, vendortrantypeid: item.vendortrantypid} });
        } 
    }
    const add = () => {
        setActiveRule({
            clientorgid: activeClient.orgId,
            clientid: activeClient.pKid,
            rulename: '',
            description: '',
            condition: null,
            coaaccountno: '',
            coaaccountname: '',
            subaccountid:'',
            subaccountno: '',
            subaccountname:'',
            vendorid: '',
            vendorname: '',
            renameddesc: '',
            isrename: false,
            isallinstitutions : 'N',
            sourcecoaaccountno: '',
            sourcecoaaccountname: '',
            accountinstitutionid: 1,
            institutionname: 'NFS',
            iscondition: true,
            isTransactionType: false,
            transactionname: '',
            vendortrantypeid: '',
            transactioncode: '',
            isexclude: false
        });
        setMode("add");
        setIsAddEditOpen(prevAddEditOpen => true);
        
    }
    const editRule = row => {
        let iscondition = (row.condition === null) ? false : true;
        let isTransactionType = (row.vendortrantypeid === undefined || row.vendortrantypeid === null || row.vendortrantypeid === '') ? false : true;
        let editrow = { ...row, iscondition: iscondition, isTransactionType: isTransactionType};
        setActiveRule(editrow);
        setMode("edit");
        setIsAddEditOpen(prevAddEditOpen => true);
    }
    
    const save = () => {
        

        if (!activeRule.iscondition || !activeRule.isrename || !activeRule.isTransactionType || activeRule.isexclude ) {
            let condition = !activeRule.iscondition ? null : activeRule.condition;
            let description = !activeRule.iscondition ? '' : activeRule.description;
            let renameddesc = !activeRule.isrename ? '' : activeRule.renameddesc;
            let transactionname = !activeRule.isTransactionType ? '' : activeRule.transactionname;
            let transactioncode = !activeRule.isTransactionType ? '' : activeRule.transactioncode;
            let vendortrantypeid = !activeRule.isTransactionType ? '' : activeRule.vendortrantypeid;
            let coaaccountno = activeRule.isexclude ? '' : activeRule.coaaccountno;
            let coaaccountname = activeRule.isexclude ? '' : activeRule.coaaccountname;
            let subaccountid = activeRule.isexclude ? '' : activeRule.subaccountid;
            let subaccountno = activeRule.isexclude ? '' : activeRule.subaccountno;
            let subaccountname = activeRule.isexclude ? '' : activeRule.subaccountname;
            let vendorid = activeRule.isexclude ? '' : activeRule.vendorid;
            let vendorname = activeRule.isexclude ? '' : activeRule.vendorname;
            setActiveRule(prevActiveRule => {
                return {
                    ...prevActiveRule, condition: condition, description: description, renameddesc: renameddesc, vendortrantypeid: vendortrantypeid,
                    transactionname: transactionname, transactioncode: transactioncode, coaaccountno: coaaccountno, coaaccountname: coaaccountname,
                    subaccountid: subaccountid, subaccountno: subaccountno, subaccountname: subaccountname, vendorid: vendorid, vendorname: vendorname
                }
            });
            setModified(pervModified => !pervModified);   
        } else
            setModified(pervModified => !pervModified);      
    }
    const saveRule = () => {
        setShowOverlay(prevShowOverlay => true);
        addEditRuleService(activeRule, mode).then(res => {
            if (res.status === "success") {
                setIsAddEditOpen(prevAddEditOpen => false);
                getRuleList(activeClient.pKid);
                setActiveRule({});
                setMode("");
                setShowOverlay(prevShowOverlay => false);
            } else {
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable save rule, you can try again.");
            }
        })
            .catch(err => {
                console.error(err);
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable save rule, you can try again.");
            }) 
    }
    return (
        <>
            <div className="RCM_Toast RCM_subAccount_toast">
                {
                    showOverlay ? <div align="center" className="mt-4">Loading Rules
                                        &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                    </div>
                        : <>
                            <BackTop />
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_subAccounts_subHeading">
                                    Select {
                                        activeClient === undefined || activeClient === null || activeClient === "" || activeClient.pKid === undefined ? " a " : ' another '}
                                    <span className="RCM_Table_link" onClick={() => toggleClientListPopup()}>client</span>.
                                
                                </div>
                            </div>
                            {
                                activeClient === undefined || activeClient === null || activeClient === "" || activeClient.pKid === undefined
                                    ? <></>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_subAccounts_subHeading">
                                                RULES FOR - {activeClient && activeClient.name}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5">

                                                <div className="RCM_Rules_Search_wrapper" >
                                                    <input
                                                        type="text"
                                                        className="RCM_form_control RCM_form_control_search"
                                                        placeholder="Search for Rule"
                                                        onChange={(e) => handleFilter(e)}
                                                        value={filterKey}

                                                    />
                                                    <button className="RCM_Textbox_close-icon"
                                                        type="reset"
                                                        onClick={() => clearFilter()}
                                                    ></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                
                                                
                                                <div className="row">
                                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                    {
                                                        ruleList ?
                                                            <div id="RCM_Rules_Table" className="RCM_SubAccounts_Table RCM_table_scorllable_body">
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={gridData}
                                                                    columns={getRuleGridColumn(editRule, add)}
                                                                    headerClasses="row-bg"
                                                                    bordered={false}
                                                                    classes="RCM_two_level_table1"
                                                                    rowClasses="RCM_tow_level_table1_row_animation"
                                                                    noDataIndication={MsgForNoData}
                                                                />
                                                            </div>
                                                            : <div key="ASDropdown22">{MsgForNoData}</div>

                                                        }
                                                    </div>

                                                </div>


                                            </div>


                                        </div>
                                    </>

                            }
                        </>
                }
            </div>
            <PostingRulesView
                activeRule={activeRule}
                activeClient={activeClient}
                mode={mode}
                toggleAddEditPopup={toggleAddEditPopup}
                save={save}
                //vendorList={vendorList}
                isAddEditOpen={isAddEditOpen}
                handleFormChanges={handleFormChanges}
                handleDropdownChange={handleDropdownChange}
                toggleSelectValuesPopup={toggleSelectValuesPopup}
                transactions={transactions}
            />
            <ClientListPopup
                isClientListPopupOpen={isClientListPopupOpen}
                toggleClientListPopup={toggleClientListPopup}
            />
            <SelectValuesPopup
                isSelectValuesPopupOpen={isSelectValuesPopupOpen}
                toggleSelectValuesPopup={toggleSelectValuesPopup}
                handleValueChange={handleValueChange}
                selectValuesPopupData={selectValuesPopupData}
            />
            
        </>
    )

};

export default PostingRules;