import gsap from "gsap";


export const animateRockefellerLogo = () => {
    gsap.to('#RCM_Loading_Logo', {duration: 1, scale: 20 });
}

export const animateDashBoardPage = () => {
    gsap.from('#dashboard', {duration: 0.7, opacity: 0});
}

export const animateRow = () => {
    let row_animation =  gsap.from('.RCM_tow_level_table1_row_animation', { duration: 1, opacity: 0 });
    setTimeout(() => {
      row_animation.progress(1);
    }, 1000);
 };


export const animateViewTable = () => {
    gsap.from('.RCM_two_level_table1', {duration: 1, opacity: 0});
}

export const uploadInvoiceViewAnimation = () => {
    gsap.from('.ant-upload', {duration: 0.7, opacity: 0});
}

export const wireInstructionFormAnimation = () => {
    gsap.from('.RCM_billpay_form_box', {duration: 0.7, opacity: 0});
}

export const rcmLoaderDotsAnimation = () => {
    gsap.from('.RCM_Loader_Dots_Animation', {duration: 0.2, opacity: 0, repeat: 1});
}

export const rcmDiscritionaryDistributionFormAnimation = () => {
    gsap.from('.RCM_Discr_form_container', {duration: 0.7, opacity: 0})
    // let winLenght = window.innerWidth/2;
    // gsap.from('.RCM_Discr_form_container', {duration: 0.5, x: winLenght});
}