import { uploadHedgeFundsUrl } from "../serviceConstant";
import { PostMultiPart } from "../servicecalls";
import axios from "../servicecallsInterceptor";


export const UploadHedgeFundFile = (file, email, onUploadProgress) => {

    var data = new FormData();
    data.append("file", file);  
    data.append("email", email);

    let uploadFilePromise = new Promise(function (resolve, reject) {

        let url = `${uploadHedgeFundsUrl}`;

        PostMultiPart(url, data, null, onUploadProgress).then(
            fileUploadRes => {
                console.log(fileUploadRes);
                resolve(fileUploadRes);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Uploading Hedge Funds", err);
                reject(err);
            }
        })
    });

    return uploadFilePromise;

}