export const INSERT_TOKEN = 'INSERT_TOKEN';
export const INSERT_ENV_VARIABLES = 'INSERT_ENV_VARIABLES';
export const REFRESH = 'REFRESH_APP';
export const ADD_FA = 'ADD_FA';
export const ADD_WIRE_INSTRUCTION = 'ADD_WIRE_INSTRUCTION';
export const UPDATE_SELECTED_CLIENT = 'UPDATE_SELECTED_CLIENT';
export const UPDATE_CLIENT_VENDORLIST =  'UPDATE_CLIENT_VENDORLIST';
export const UPDATE_CLIENT_VISIBILITY_SELECTED_USER = 'UPDATE_CLIENT_VISIBILITY_SELECTED_USER';
export const UPDATE_DISCRETIONARY_DISTRIBUTION_MODE = 'UPDATE_DISCRETIONARY_DISTRIBUTION_MODE';
export const INSERT_FA_PREFERENCE = 'INSERT_FA_PREFERENCE';
export const UPDATE_SELECTED_MENU = 'UPDATE_SELECTED_MENU';
export const UPDATE_SSO_WINDOW = 'UPDATE_SSO_WINDOW';
export const UPDATE_SELECTED_SUB_ACCOUNT = "UPDATE_SELECTED_SUB_ACCOUNT";