
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetCompletedDiscretionaryDistribution, FetchDiscretionDistributionDocument } from '../../services/discritionarydistributionservice/discritionarydistributionservice';
import BootstrapTable from 'react-bootstrap-table-next';
import { MsgForNoData } from '../../common/constants';
import { arrayFilterByValue, getPreferenceValue } from '../../common/utilities.js';
import { getCompletedDistributionColumn } from './distributionlistColumn';
import { Spinner } from 'reactstrap';
import { UpdateDiscretionaryDistributionMode } from '../../actions/discretionaryDistributionAction';
import PdfViwerModal from '../../common/pdfViwerModal';

class DiscretionDistributionCompletedList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            distributionList: [],
            filteredDistrubtionList: [],
            searchText: '',
            isLoading: true,
            pdfViewerState: false,
            pdfdata: null,
            showAllTxn: getPreferenceValue(this.props.faFeatures, 'DISCRETIONARY_DIST_ALLTXN')
        }

        this.openDocument = this.openDocument.bind(this);
        this.togglePdfViewer = this.togglePdfViewer.bind(this);

        this.defaultDistributionSorted = [{
            dataField: 'requestDate',
            order: 'desc'
        }]
    }

    componentDidMount() {

        if (this.props.user && this.props.user.userName) {
            GetCompletedDiscretionaryDistribution(this.props.user.userName, this.state.showAllTxn).then(res => {
                this.setState({ distributionList: res, filteredDistrubtionList: res, isLoading: false });
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.error(err);
            })
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.refresh !== this.props.refresh) {
            this.setState({isLoading: true}, () => {
                if (this.props.user && this.props.user.userName) {
                    GetCompletedDiscretionaryDistribution(this.props.user.userName, this.state.showAllTxn).then(res => {
                        this.setState({ distributionList: res, filteredDistrubtionList: res, isLoading: false });
                    })
                    .catch(err => {
                        this.setState({isLoading: false})
                        console.error(err);
                    })
                }
            })
        }
    }


    filterDistrubtion(evt) {
        //filter here..
        let searchString = evt.target.value;
        if (searchString) {
            var updatedDistributionList = [...this.state.distributionList];
            let searchKeyList = ['requestDate', 'trustTitle', 'entityNumber', 'distributionAmount', 'trustees', 'status'];
            updatedDistributionList = arrayFilterByValue(updatedDistributionList, searchString, searchKeyList);
            this.setState({ filteredDistrubtionList: updatedDistributionList, searchText: searchString });
        } else {
            this.setState({ filteredDistrubtionList: this.state.distributionList, searchText: searchString });
        }

    }

    openDocument(entityId, doctype){
        FetchDiscretionDistributionDocument(entityId, doctype, '').then(doc => {
            // let pdfWindow = window.open("", "Discretionary Document", `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,scrollbars=no,width=${window.innerWidth},height=${window.innerHeight}`);
            // pdfWindow.document.write(
            //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            //     encodeURI(doc) + "'></iframe>"
            // )
            this.setState({pdfViewerState: true, pdfdata: doc});
        })
        .catch(err => {
            console.error(err);
        })
    }

    handleOnNewDistribution() {
        this.props.UpdateDiscretionaryDistributionMode(null, 'ADD').then(() => {
            if (this.props.history) {
                this.props.history.push("/discretionaryrequest");
            }
        })
    }

    togglePdfViewer(){
        this.setState({pdfViewerState:  !this.state.pdfViewerState});
    }


    render() {
        return (
            <div className="RCM_Toast">
                {
                    this.state.isLoading ?
                        (<div align="center" className="mt-4" style={{color: '#fff'}}>Loading Distribution List
                        &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                        </div>) :
                        (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-4">
                                        <div>
                                            <input type="text" className="RCM_form_control" placeholder="Search for your distribution"
                                                value={this.state.searchText}
                                                onChange={(e) => this.filterDistrubtion(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-8">
                                        <div align="right" className="mt-2">
                                            <button type="button" className="RCM_billpay_btn" onClick={() => this.handleOnNewDistribution()}>NEW DISTRIBUTION</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <BootstrapTable
                                            id="admin_clientvisibility_clientlist"
                                            keyField='distributionID'
                                            data={this.state.filteredDistrubtionList}
                                            columns={getCompletedDistributionColumn(this.openDocument, this.state.showAllTxn)}
                                            headerClasses="row-bg"
                                            bordered={false}
                                            classes="RCM_two_level_table1"
                                            rowClasses='RCM_tow_level_table1_row_animation'
                                            noDataIndication={MsgForNoData}
                                            defaultSorted={this.defaultDistributionSorted}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                }

                <PdfViwerModal modalState={this.state.pdfViewerState} toggleModal={this.togglePdfViewer} pdfdata={this.state.pdfdata} />
            
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
        faFeatures: store.app.faFeatures
    };
}

const mapDispatchToProps = {
    UpdateDiscretionaryDistributionMode
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DiscretionDistributionCompletedList));

