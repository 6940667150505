import {
    apiBaseUrl, uploadPaymentLOAUrl, fetchLOADocumentUrl, updatePaymentAccountUrl,
    getPartyIdListUrl
} from "../serviceConstant";
import { errorMsg, MsgForFailedData } from '../../common/constants';
import {PostMultiPart, Post, Get} from "../servicecalls";
import axios from "axios";


export const UploadPaymentLOA = (id, file, onUploadProgress) => {

    let uploadPaymentLOAPromise = new Promise(
        function (resolve, reject) {
            let formData = new FormData();
            formData.append('clientid', id);
            formData.append('paymentloaContent', file);
            formData.append('isforpaymentloa', true);
            let url = `${apiBaseUrl()}/${uploadPaymentLOAUrl}`;

            PostMultiPart(url, formData, null, onUploadProgress).then(res => {
                resolve(res);
            })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure. 
                        console.log("Upload Payment LOA Service : Error while uploading LOA : :: ", error);
                        reject(errorMsg);
                    }
                });
        });

    return uploadPaymentLOAPromise;
}

export var fetchLOADocumentCancellationToken = null;

export const FetchLOADocument = (reqJson) => {

    if (fetchLOADocumentCancellationToken !== null && fetchLOADocumentCancellationToken !== undefined) {
        //Cancel the existing call
        fetchLOADocumentCancellationToken.cancel('Cancelling the get call to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    fetchLOADocumentCancellationToken = cancelToken.source();

    let fetchLOADocumentPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${fetchLOADocumentUrl}`;
            Post(url, reqJson, fetchLOADocumentCancellationToken).then(
                response => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(MsgForFailedData);
                    }
                }
            ).catch(err => {
                if (axios.isCancel(err)) {
                    console.log(err);
                } else {
                    console.log("Error Occured fetching LOA document.", err);
                    reject(err);
                }
            })
        });
    return fetchLOADocumentPromise;
}
export var updatePaymentAccountServiceCancellationToken = null;

export const updatePaymentAccountService = (reqPayload) => {

    if (updatePaymentAccountServiceCancellationToken !== null && updatePaymentAccountServiceCancellationToken !== undefined) {
        updatePaymentAccountServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    updatePaymentAccountServiceCancellationToken = cancelToken.source();

    let updatePaymentAccountPromise = new Promise(function (resolve, reject) {

        let url = `${apiBaseUrl()}/${updatePaymentAccountUrl}`;

        Post(url, reqPayload, updatePaymentAccountServiceCancellationToken).then(
            response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error occured while updating payment account.", err);
                reject(err);
            }
        })
    });

    return updatePaymentAccountPromise;
}

export var GetPartyIdListServiceCancellationToken = null;

export const GetPartyIdListService = (accountKey) => {

    if (GetPartyIdListServiceCancellationToken !== null && GetPartyIdListServiceCancellationToken !== undefined) {
        GetPartyIdListServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetPartyIdListServiceCancellationToken = cancelToken.source();

    let getPartyIdListPromise = new Promise(function (resolve, reject) {

        let url = `${apiBaseUrl()}/${getPartyIdListUrl}?accountno=${accountKey}`;
        Get(url, GetPartyIdListServiceCancellationToken).then(
            response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error occured while getting party id list.", err);
                reject(err);
            }
        })
    });

    return getPartyIdListPromise;
}
