import { UPDATE_SELECTED_SUB_ACCOUNT } from "../actions/actionTypes";

const initialState = {
    selectedSubAccount: null,
    contextJson: null,
    profileJson:null
}

export function AccountingReducer(state=initialState, action){

    switch(action.type)
    {
        case UPDATE_SELECTED_SUB_ACCOUNT:
        {
            return {
                ...state, 
                selectedSubAccount : action.payload,
            }
        }

        default:
        return state;
    }
}