import React, { Component } from 'react';
import UsersList from './usersList';
import ClientsList from './clientsList';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';

class ClientVisibility extends Component {

    constructor(props){
        super(props);
        this.state= {
            
        }
    }

    render() {
        return (
            <div className="RCM_Toast">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <UsersList />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <ClientsList />
                        </div>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        refresh: store.app.refresh
    };
}

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientVisibility));


