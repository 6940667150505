import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from "reactstrap";
import NinthWavePopup from './ninthWavePopup';
import Messages from '../../common/messages.jsx';
const SubAccountsAddEdit = ({ showNinthWaveWidget, mode, handleFormChanges, activeAccount, isLinkAccountDisabled, openConfirmationPopup,
    handleDropdownChange, cancel, save, openNinthwaveModelPopup, toggleNinthwaveModelPopup, categoryLevel1Type, categoryLevel2Type,
    getBankName, bankRoutingNumberErrorMsg }) => {


    const [isCategoryLevel1Open, setIsCategoryLevel1Open] = useState(false);
    const [isCategoryLevel2Open, setIsCategoryLevel2Open] = useState(false);

    return (
        <>
            {
                mode ? <div>
                    <div className="row">
                        {
                            mode === "edit"
                                ?
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading">
                                    Edit Account - {activeAccount.shortName}
                                </div>
                                :
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading">
                                    Add New Sub Account
                                                </div>
                        }

                    </div>
                    <div>
                        <Form>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup>
                                        <label>Account Number *</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="number"
                                            className="RCM_form_control"
                                            value={activeAccount.shortName}
                                            onChange={evt => handleFormChanges(evt)}
                                            disabled={mode === "edit"}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup>
                                        <label>Account Name *</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="name"
                                            className="RCM_form_control"
                                            value={activeAccount.name}
                                            onChange={evt => handleFormChanges(evt)}
                                            
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    {/*<FormGroup>
                                        <label>Description</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="description"
                                            className="RCM_form_control"
                                            value={activeAccount.description}
                                            onChange={evt => handleFormChanges(evt)}
                                        />
                                    </FormGroup>*/}
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridTotal Value">
                                        <div className="RCM_label_wrapper">
                                            <Label for="type">Segment</Label>
                                        </div>

                                        <Dropdown name="categorylevel1" required
                                            isOpen={isCategoryLevel1Open}
                                            toggle={() => setIsCategoryLevel1Open(prevIsCategoryLevel1Open => !prevIsCategoryLevel1Open)}
                                            className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                            <DropdownToggle caret>{activeAccount.categoryCode1}</DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    <div>
                                                        {categoryLevel1Type && categoryLevel1Type !== null && categoryLevel1Type.map((item, index) =>
                                                            <DropdownItem key={index} onClick={() => handleDropdownChange(item, 'categorylevel1')}>
                                                                <div>{item.name}</div>
                                                            </DropdownItem>
                                                        )}
                                                    </div>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>

                                </div>


                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                    <FormGroup controlId="formGridTotal Value">
                                        <div className="RCM_label_wrapper">
                                            <Label for="type">Asset Type</Label>
                                        </div>

                                        <Dropdown name="categorylevel2" required
                                            isOpen={isCategoryLevel2Open}
                                            toggle={() => setIsCategoryLevel2Open(prevIsCategoryLevel2Open => !prevIsCategoryLevel2Open)}
                                            className="RCM_form_control RCM_select_box RCM_mm_select_box">
                                            <DropdownToggle caret>{activeAccount.categoryCode2}</DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    <div>
                                                        {categoryLevel2Type && categoryLevel2Type !== null && categoryLevel2Type.map((item, index) =>
                                                            <DropdownItem key={index} onClick={() => handleDropdownChange(item, 'categorylevel2')}>
                                                                <div>{item.name}</div>
                                                            </DropdownItem>
                                                        )}
                                                    </div>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>
                                </div>

                                {showNinthWaveWidget
                                    ? <>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4 RCM_link">
                                            {activeAccount.isAggregated === null || activeAccount.isAggregated !== 'Y'
                                                ? isLinkAccountDisabled
                                                    ? <label className="RCM_icon_hand RCM_link_disabled" title="* marked fields are required to link an account">Link Account</label>
                                                    : <label className="RCM_icon_hand" onClick={() => openConfirmationPopup('ninthwave-link')}>Link Account</label>
                                                : <label className="RCM_icon_hand" onClick={() => openConfirmationPopup('ninthwave-delink')}>Unlink Account</label>
                                               
                                            }
                                            
                                            
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup controlId="formGridTotal Value">

                                                <div className="RCM_label_wrapper">
                                                    <Label for="type">Bank Routing Number</Label>
                                                </div>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="bankRoutingNumber"
                                                    className="RCM_form_control"
                                                    required
                                                    value={activeAccount.bankRoutingNumber}
                                                    onChange={evt => handleFormChanges(evt)}
                                                    onBlur={evt => getBankName(evt)}
                                                    disabled={activeAccount.isAggregated === 'Y'}
                                                />
                                            </FormGroup>
                                            
                                        </div>

                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup>
                                                <label>Custodian/Bank Name</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="custodianname"
                                                    className="RCM_form_control"
                                                    value={activeAccount.bankName}
                                                    onChange={evt => handleFormChanges(evt)}
                                                    disabled={activeAccount.isAggregated === 'Y' || activeAccount.bankRoutingNumber !== ''}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4">
                                            <FormGroup>
                                                <label>Linked Account Number</label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    name="linkedaccountnumber"
                                                    className="RCM_form_control"
                                                    value={activeAccount.bankAccount}
                                                    onChange={evt => handleFormChanges(evt)}
                                                    disabled={activeAccount.isAggregated === 'Y'}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                            {
                                                (bankRoutingNumberErrorMsg !== '')
                                                && <Messages messages={[{ 'text': bankRoutingNumberErrorMsg, 'class': 'RCM_validation_error_text' }]} containerClass={"RCM_validation_error_container"} />
                                            }
                                        </div>

                                    </>
                                    : ''

                                }
                            </div>

                            <div className="row">
                                <div className={showNinthWaveWidget ? "col-sm-12 col-md-12 col-lg-12" :"col-sm-12 col-md-8 col-lg-8 RCM_subAccount_btn_wrapper"}align="right">
                                    <button type="button" className="btn btn-btn RCM_button_secondary RCM_accounting_btn" onClick={() => cancel()}>CANCEL</button> &nbsp;

                                                    <button type="button" className="btn btn-btn RCM_button_primary RCM_accounting_btn"
                                         onClick={() => save()}>
                                        {mode === "edit" ? 'SAVE' : 'ADD'}
                                    </button>
                                </div>
                            </div>

                        </Form>
                    </div>
                    <NinthWavePopup
                        openNinthwaveModelPopup={openNinthwaveModelPopup}
                        toggleNinthwaveModelPopup={toggleNinthwaveModelPopup}
                    />
                </div>
                    :
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading">
                            Please select an account
                        </div>
                    </div>

            }
        </>
    )
}
export default SubAccountsAddEdit;