import React from 'react';
import { getDateString } from './../../../common/constants';

export function getAgreementListColumn(addViewLOA, openPaymentsAccountPopup){
    const column = [
        {
            dataField: 'name',
            text: 'Client Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'docLocation',
            text: 'Document Name',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            classes: 'RCM_common_table_column_text RCM_table_overflow_cell',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return(<div title={cell}>
                        {cell}
                    </div>);
            }
        },
        {
            dataField: 'docDate',
            text: 'Updated On',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (<div title={cell}>
                    {
                        cell !== undefined && cell !== null ? getDateString(cell) : ''
                    }
                </div>);
            }
        },
        {
            dataField: 'payAcct',
            text: 'Payment Account',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (<div className="RCM_displayflex_justified_end">
                    <div title={cell}>
                    {
                        cell !== undefined && cell !== null ? cell : '-'
                    }
                    </div>
                    <div className="RCM_edit_icon" title="Edit asset" onClick={() => openPaymentsAccountPopup(row)}></div>
                    
                </div>
                );
            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
           
            formatter: (cell, row) => {
                return (<div align="right">
                    
                    {row.docLocation && row.docLocation !== null && row.docLocation !== ''
                        ? <button type="button" className="RCM_billpay_btn"
                            onClick={() => addViewLOA(row, 'view')}>VIEW</button>
                        : ''
                    }
                    
                    <button type="button" className="RCM_billpay_btn"
                        onClick={() => addViewLOA(row, 'add')}>APPEND/ADD LOA</button>
                    
                </div>
                );
            }
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
