
import React from 'react';
import { Tooltip } from 'antd';
import { DeleteFilled } from '@ant-design/icons';


export function getDiscretionaryDocumentColumns(openDocument, mode, deleteDocument){
    const column = [
        {
            dataField: 'documentName',
            text: 'Document Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: false,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: '',
            text: 'Actions',
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cell, row) => {
                return (
                    <div align="right">
                        {
                            <div style={{display: 'flex'}}>
                                <Tooltip title='Click to view your document'>
                                    <div className="RCM_Table_Pdf_icon" onClick={() => openDocument(row)}></div> 
                                </Tooltip>
                                {
                                    mode === 'UPDATE' &&  
                                    (<Tooltip title='Delete document'>
                                        <DeleteFilled style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => deleteDocument(row)}/>
                                     </Tooltip>)
                                }
                                
                           </div>
                        }
                    </div>
                )
            }
        }
    ]

        return column;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}