import React from 'react';

export function getVendorColumn(openWireInstruction, viewInvoice, openBilldotcomVendor) {
    const column = [

        {
            dataField: 'name',
            text: 'Vendor Name',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'payBy',
            text: 'Payment Type',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <div>
                        {
                           cell
                        }
                    </div>
                )
            },
        },
        {
            dataField: 'addressCity',
            text: 'City',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'addressState',
            text: 'State',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'addressZip',
            text: 'Zip',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'isActive',
            text: 'Status',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <div>
                        {getActiveStatus(cell)}
                    </div>
                )
            },
        },
        {
            dataField: 'accNumber',
            text: 'Account Number',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            classes: 'RCM_Vendor_Action_col',
            formatter: (cell, row) => {
                return (
                    <div align="right" style={{display: 'flex'}}>
                        <button type="button" className="RCM_billpay_btn" onClick={() => openWireInstruction(row)}>EDIT WIRE INSTRUCTIONS</button> 
                        <button type="button" className="RCM_billpay_btn" onClick={() => openBilldotcomVendor(row)}>GO TO VENDOR</button>
                        
                    </div>
                )
            },
            events: {
                // onClick: this.openConfirmationPopup
            }
        }
    ]

    return column;
}


export function getActiveStatus(cell){
    if(cell === '1')
        return "Active"
    else if(cell === '2')
        return "In Active"
    else
        return ""    
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
export default getVendorColumn;