import {TelemetryService} from  "./TelemetryService";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getNestedObject } from "../common/common";
import {store} from "../index";

export function _logException(errorMessage)
{
    try {
        let ai =  new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();

        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }   
        ai.appInsights.trackException({ error: new Error(`${JSON.stringify(errorMessage)}`), severityLevel: SeverityLevel.Error }, addCustomData());
        ai.appInsights.flush();
    } catch (err) {
        console.log("Unable to log exception ", err);
    }
}

export function _logInformation(infoMessage)
{
    try{
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackTrace({ message: `${JSON.stringify(infoMessage)}`, severityLevel: SeverityLevel.Information }, addCustomData());
        ai.appInsights.flush();
    }catch(err)
    {
        console.log("Unable to log tarces ", err);
    }
}

export function _logEvent(eventName)
{
    try{
        
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }     
        ai.appInsights.trackEvent({ name: eventName }, addCustomData());
        ai.appInsights.flush();
    }catch(err){
        console.log("Unable to log event ", err);
    }
}

export function _logPageView(pageView){

    try{
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackPageView(pageView);
        ai.appInsights.flush();
    }catch(err)
    {

    }
}

function getTelemetryInitializer()
{
    let telemetryInitializer =null;
    let user  = store.getState().app.user;
     if(user)
     {
       let userName = user.userName;

        telemetryInitializer = (envelope) => {
            envelope.tags['ai.user.id'] = userName;
            envelope.tags['ai.user.authUserId'] = userName;
        };
     }

    return telemetryInitializer;
}

function addCustomData(){
    let customData = {};
    let user  = store.getState().app.user;
     if(user)
     {
       customData.userName = getNestedObject(user, ['name']);
       customData.azureAdId = getNestedObject(user, ['idTokenClaims', 'oid']);
       customData.userEmail = getNestedObject(user, ['idTokenClaims', 'preferred_username']);
     }
     return customData;
}

