import React from 'react'
import { Icon } from 'antd';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log("Error Occured !!", error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div align="center" className="RCM_Error_Container">
                <Icon type="coffee" className="RCM_Error_Coffee_Icon" />
                <h1>Something went wrong, have some coffee and come back after sometime.</h1> 
            </div>
        )
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;
