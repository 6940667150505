import { useCallback, useEffect, useState } from "react"

const useBoolean = ({initialValue}) => {
    const [val, setValue] = useState()

    useEffect(() => {
        setValue(initialValue || false)
    }, [initialValue])

    const toggle = useCallback(() => {
        setValue(prevState => !prevState)
    }, [])

    const setBooleanValue = useCallback((value) => {
        setValue(value || false)
    }, [])   

    return {val, toggle, setBooleanValue}
}

export default useBoolean
