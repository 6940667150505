import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { BackTop, message } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import { MsgForNoData, arrayFilterByValue } from '../../../common/constants';
import { getVendorsGridColumn } from './vendorListColumns';
import VendorAddEdit from './vendorAddEdit';
import { getNestedObject } from '../../../common/common';
import {
    addEditVendorService, addEditVendorServiceCancellationToken,
    getVendorListService, getVendorListServiceCancellationToken
} from './../../../services/accountingServices/accountingService';
import ClientListPopup from '../clientListPopup';
import { animateViewTable } from '../../../common/animation';


const VendorsList = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [filterKey, setFilterKey] = useState(""); 
    const [activeVendor, setActiveVendor] = useState({});
    const [mode, setMode] = useState("");
    const selectedClient = useSelector(state => getNestedObject(state, ['dashboard', 'selectedClient']));
    const [activeClient, setActiveClient] = useState({});
    const [isClientListPopupOpen, setIsClientListPopupOpen] = useState(false);
  
    useEffect(() => {
        
        setActiveClient(prevActiveClient => { return { ...selectedClient } });
        return () => {
            
            if (addEditVendorServiceCancellationToken !== null && addEditVendorServiceCancellationToken !== undefined) {
                addEditVendorServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (getVendorListServiceCancellationToken !== null && getVendorListServiceCancellationToken !== undefined) {
                getVendorListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            
        }
    }, []);

    useEffect(() => {
        if (activeClient && activeClient.pKid) {
            setShowOverlay(prevShowOverlay => true);
            getVendorList(activeClient.pKid);
            setFilterKey(pervFilterKey => "");
        }
    }, [activeClient]);

    useEffect(() => {
        setActiveClient(prevActiveClient => { return { ...selectedClient } });
    }, [selectedClient]);

    const toggleClientListPopup = () => {
        setIsClientListPopupOpen(prevIsClientListPopupOpen => !prevIsClientListPopupOpen);
    }
    
    const getVendorList = (entityId) => {
        getVendorListService(entityId).then(res => {
            
            if (res.status === 200) {
                if (res && res.data && res.data && res.data !== null && Array.isArray(res.data)) {
                    let data = res.data;
                    setVendorList(data);
                    setGridData(data);
                }
            } else {
                animateViewTable();
                message.error("We are unable to get vendor list, you can try again.");
                console.error("Error Occured while fetching vendors");
            }
            setShowOverlay(prevShowOverlay => false);

        })
            .catch(err => {
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable to get vendor list, you can try again.");
                console.error("Error Occured while fetching vendors");
            })
    }
    const handleFilter = (e) => {
        let value = e.target.value
        let filteredVendor = arrayFilterByValue(vendorList, value);
        setFilterKey(pervFilterKey => value);
        setGridData(filteredVendor);
    }
    const clearFilter = () => {
        let filteredVendor = arrayFilterByValue(vendorList, "");
        setFilterKey(pervFilterKey => "");
        setGridData(filteredVendor);
    }
    const add = () => {
        setMode("add");
        setActiveVendor({
            "rcmPayeeId": "",
            "name": "",
            "pKid": activeClient.pKid,
            "vendorType": "payee",
            "sid": "",
            "isActive": "Y",
            "mode": "INSERT-IF-NEW"
        });        
    }
    const editVendor = row => {
        setMode("edit");
        let payee = { ...row, mode: 'UPDATE', "pKid": activeClient.pKid };
        setActiveVendor({ ...payee });
    }
    
    const handleFormChanges = evt => {
        let value = evt.target.value;
        if (evt.target.name === "name") {
            setActiveVendor(prevActiveVendor => { return { ...prevActiveVendor, name: value } });
        }
        //else if (evt.target.name === 'description') {
        //    setActiveVendor(prevActiveVendor => { return { ...prevActiveVendor, description: value } });
        //} 
    }
    const cancel = () => {
        setActiveVendor({});
        setMode("");
    }
    const save = () => {
        
        setShowOverlay(prevShowOverlay => true);
        addEditVendorService(activeVendor).then(res => {
            if (res) {  
                getVendorList(activeClient.pKid);
                setActiveVendor({});
                setMode("");
                setShowOverlay(prevShowOverlay => false);
            } else {
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable save vendor, you can try again.");
            }
        })
            .catch(err => {
                console.error(err);
                setShowOverlay(prevShowOverlay => false);
                animateViewTable();
                message.error("We are unable save vendor, you can try again.");
            })

    }
    return (
        <>
            <div className="RCM_Toast RCM_subAccount_toast">
                {
                    showOverlay ? <div align="center" className="mt-4">Loading Payees
                                        &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                    </div>
                        : <>
                            <BackTop />
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  RCM_subAccounts_subHeading">
                                    Select {
                                        activeClient === undefined || activeClient === null || activeClient === "" || activeClient.pKid === undefined ? " a " : ' another '}
                                    <span className="RCM_Table_link" onClick={() => toggleClientListPopup()}>client</span>.
                                
                                </div>
                            </div>
                            {
                                activeClient === undefined || activeClient === null || activeClient === "" || activeClient.pKid === undefined
                                    ? <></>
                                    :

                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-5 col-lg-5">
                                            <div className="RCM_subAccounts_subHeading">
                                                LIST OF PAYEES - {activeClient && activeClient.name}
                                            </div>
                                            <div className="RCM_Rules_Search_wrapper" >
                                                <input
                                                    type="text"
                                                    className="RCM_form_control RCM_form_control_search"
                                                    placeholder="Search for Payee"
                                                    onChange={(e) => handleFilter(e)}
                                                    value={filterKey}

                                                />
                                                <button className="RCM_Textbox_close-icon"
                                                    type="reset"
                                                    onClick={() => clearFilter()}
                                                ></button>
                                            </div>          


                                            <div>
                                                {
                                                    vendorList ?
                                                        <div id="RCM_SubAccounts_Table" className="RCM_SubAccounts_Table RCM_table_scorllable_body">
                                                            <BootstrapTable
                                                                keyField='id'
                                                                data={gridData}
                                                                columns={getVendorsGridColumn(editVendor, add)}
                                                                headerClasses="row-bg"
                                                                bordered={false}
                                                                classes="RCM_two_level_table1"
                                                                rowClasses="RCM_tow_level_table1_row_animation"
                                                                noDataIndication={MsgForNoData}
                                                            />
                                                        </div>
                                                        : <div key="ASDropdown22">{MsgForNoData}</div>

                                                }
                                            </div>


                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-7 col-lg-7">
                                            <VendorAddEdit
                                                activeVendor={activeVendor}
                                                activeClient={activeClient}
                                                mode={mode}
                                                handleFormChanges={handleFormChanges}
                                                cancel={cancel}
                                                save={save}
                                            />
                                        </div>

                                    </div>
                            }
                        </>
                }
            </div>
            <ClientListPopup
                isClientListPopupOpen={isClientListPopupOpen}
                toggleClientListPopup={toggleClientListPopup}
            />
        </>
    )

};

export default VendorsList;