import React from 'react';

export function getClientsListColumn(handleOnClientSelect){
    const column = [
        {
            dataField: "isUserBelongToOrg",
            text: "Include",
            sort: false,
            editable: false,
            formatter: (cell, row) => {
                return (
                        <input type="checkbox" checked={cell === 'Y' ? true : false} type="checkbox" onChange={() => handleOnClientSelect(row)} />
                    )
            }
        },
        {
            dataField: 'organizationName',
            text: 'Client Name',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
