import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormGroup } from "reactstrap";
import { validateEmailId, basicAlphaNumeric } from './../../../common/constants';
import { BackTop, message } from 'antd';
import { authProvider } from '../../../App';
import {
    addApproverService, addApproverServiceCancellationToken,
    editApproverService, editApproverServiceCancellationToken,
    getUserListService
} from './../../../services/approversService/approversService';


const AddApprovers = (props) => {
    const [approver, setApprover] = useState({
        emailid: '',
        firstname: '',
        lastname: '',
        wsportaluserid:''
    });
    const [isEmailValid, setIsEmailValid] = useState(false);
    //const user = useSelector(state => getNestedObject(state,['app', 'user']));
    useEffect(() => {
        //getGraphAPIUserList();
        return () => {
            if (addApproverServiceCancellationToken !== null && addApproverServiceCancellationToken !== undefined) {
                addApproverServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (editApproverServiceCancellationToken !== null && editApproverServiceCancellationToken !== undefined) {
                editApproverServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
        }
    }, []);

    useEffect(() => {
        if (props.mode === 'edit') {
            setApprover(props.selectedApprover);
            setIsEmailValid(prevIsEmailValid => true);
        } else if (props.mode === 'add') {
            setApprover({
                emailid: '',
                firstname: '',
                lastname: '',
                wsportaluserid:''
            });
        }
    }, [props.mode, props.selectedApprover]);

    const getGraphAPIUserList = () => {
        const accounts = authProvider.getAllAccounts();
        if (accounts && accounts.length > 0) {
            console.log("User exist in cache");

            authProvider
                .acquireTokenSilent({
                    account: accounts[0],
                    //loginHint: user.userName,
                    forceRefresh: true,
                    scopes: ["user.read"],
                })
                .then((silentres) => {
                    getUserListService(silentres.accessToken)
                        .then((res) => {
                            alert(res);
                        })
                        .catch((err) => {
                            console.log("error: " + err);
                        })

                })
                .catch((err) => {
                    console.error(" failed", err);

                });
        }
    }
    const handleFormChanges = evt => {
        let value = evt.target.value;
        if (evt.target.name === "emailid") {
            setApprover(prevApprover => { return { ...prevApprover, emailid: value } });
        } else if (evt.target.name === "firstname" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            setApprover(prevApprover => { return { ...prevApprover, firstname: value } });
        } else if (evt.target.name === "lastname" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            setApprover(prevApprover => { return { ...prevApprover, lastname: value } });
        } else if (evt.target.name === "wsportaluserid" && evt.target.value.length <= 50 && (evt.target.value === "" || basicAlphaNumeric.test(evt.target.value))) {
            setApprover(prevApprover => { return { ...prevApprover, wsportaluserid: value } });
        }

    }
    const handleBlur = evt => {
        let value = evt.target.value;
        if (evt.target.name === "emailid") {
            if (!validateEmailId(value)) {
                message.error("Please enter a valid email address.");
                setIsEmailValid(prevIsEmailValid => false);
            } else {
                setIsEmailValid(prevIsEmailValid => true);
            }
        }
    }
    const clear = () => {
        setApprover(prevApprover => { return { ...prevApprover, emailid: '', firstname: '', lastname: '', wsportaluserid:'' } });
        props.setShowAdd(prevShowAdd => false);
    }
    const save = () => {
        if (props.mode === 'add') {
            addApproverService(approver).then(res => {
                if (res) {
                    props.message.success("Approver added successfully.");
                    clear();
                    props.refresh();
                }

            })
                .catch(err => {
                    console.error(err);
                    props.message.error("Unable to add approver.");
                })
        } else {
            editApproverService(approver).then(res => {
                if (res) {
                    props.message.success("Approver updated successfully.");
                    clear();
                    props.refresh();
                }

            })
                .catch(err => {
                    console.error(err);
                    props.message.error("Unable to updated approver.");
                })
        }


    }

    return (
        <>
            <BackTop key="AddApprover01" />
            <div className="row" key="AddApprover02">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading" key="AddApprover03">
                    {props.mode === "add" ? 'Add New Approver' : (props.mode === "edit") ? 'Edit Approver: ' + approver.emailid : 'Select an approver.'}
                </div>

            </div>
            {
                props.mode === "add" || props.mode === "edit"
                    ? <div key="AddApprover04">
                        <Form key="AddApprover05">

                            <div className="row" key="AddApprover06">
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4" key="AddApprover07">
                                    <FormGroup key="AddApprover08">
                                        <label key="AddApprover09">Email Address *</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="emailid"
                                            className="RCM_form_control RCM_approverEmail"
                                            value={approver.emailid}
                                            onChange={evt => handleFormChanges(evt)}
                                            onBlur={evt => handleBlur(evt)}
                                            key="AddApprover10"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4" key="AddApprover11">
                                    <FormGroup key="AddApprover12">
                                        <label key="AddApprover13">First Name *</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="firstname"
                                            className="RCM_form_control"
                                            value={approver.firstname}
                                            onChange={evt => handleFormChanges(evt)}
                                            key="AddApprover14"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4" key="AddApprover15">
                                    <FormGroup key="AddApprover16">
                                        <label key="AddApprover17">Last Name *</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="lastname"
                                            className="RCM_form_control"
                                            value={approver.lastname}
                                            onChange={evt => handleFormChanges(evt)}
                                            key="AddApprover18"
                                        />
                                    </FormGroup>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4" key="AddApprover20">
                                    <FormGroup key="AddApprover21">
                                        <label key="AddApprover22">WsportalUserId *</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="wsportaluserid"
                                            className="RCM_form_control"
                                            value={approver.wsportaluserid}
                                            onChange={evt => handleFormChanges(evt)}
                                            key="AddApprover23"
                                        />
                                    </FormGroup>
                                </div>

                            </div>
                            <div className="row" key="AddApprover19">
                                <div className="col-sm-12 col-md-12 col-lg-12" align="right" key="AddApprover20">
                                    <button type="button" className="btn btn-btn RCM_button_secondary RCM_accounting_btn" onClick={() => clear()} key="AddApprover21">
                                        CANCEL
                            </button>
                            &nbsp;

                            <button type="button" className="btn btn-btn RCM_button_primary RCM_accounting_btn" onClick={() => save()}
                                        disabled={!isEmailValid || approver.firstname === "" || approver.lastname === ""} key="AddApprover22">
                                        {props.mode === "add" ? 'ADD' : 'EDIT'}
                                    </button>
                                </div>
                            </div>

                        </Form>
                    </div>
                    : ''
            }
        </>
    )

};

export default AddApprovers;