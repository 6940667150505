import React, { Component } from 'react'
import UploadDiscretionaryDistributionDocs from './uploaddiscretionarydistributiondocs'
import DiscretionaryDocumentsList from './discretionarydocumentslist';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

class DiscretionaryDistributionDocuments extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
        //check if we have selectedDistribution and Id
        if(this.props.selectedDistribution && this.props.selectedDistribution.distributionID){
            //do something 
        }else{
            if(this.props.history){
                this.props.history.push('/discretionarydistribution');
            }       
        }
    }

    render() {
        return (
            <div>
                <div className="row RCM_Toast">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <span className="RCM_CM_form_label">ENTITY NUMBER:</span> &nbsp; {(this.props.selectedDistribution && this.props.selectedDistribution.entityNumber) ? this.props.selectedDistribution.entityNumber : ''}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <span className="RCM_CM_form_label">TRUST TITLE:</span> &nbsp; {(this.props.selectedDistribution && this.props.selectedDistribution.trustTitle) ? this.props.selectedDistribution.trustTitle : ''}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <span className="RCM_CM_form_label">REQUEST DATE:</span> &nbsp; {(this.props.selectedDistribution && this.props.selectedDistribution.requestDate) ? this.props.selectedDistribution.requestDate : ''}
                    </div>
                </div>
                <div className="row RCM_Toast">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        {
                            this.props.mode !== 'VIEW' && (
                                <UploadDiscretionaryDistributionDocs 
                                    distributionID={this.props.selectedDistribution && this.props.selectedDistribution.distributionID ? this.props.selectedDistribution.distributionID : ''} 
                                    userEmail={this.props.user && this.props.user.userName ? this.props.user.userName : ''} />
                            )
                        }
                    </div>
                </div>
                <div className="mt-3 RCM_Toast">
                    <DiscretionaryDocumentsList 
                         distributionID={this.props.selectedDistribution && this.props.selectedDistribution.distributionID ? this.props.selectedDistribution.distributionID : ''}
                         mode={this.props.mode} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
        selectedDistribution: store.discretionaryDistribution.selectedDistribution,
        mode: store.discretionaryDistribution.mode
    };
}

const mapDispatchToProps = {
    
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DiscretionaryDistributionDocuments));
