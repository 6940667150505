import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Icon } from 'antd';
import { UploadPaymentLOA } from './../../../services/paymentAgreementService/paymentAgreementService';
const { Dragger } = Upload;

const AgreementDragDrop = (props) => {
    const onFileUpload = ({ onSuccess, onError, onProgress, file }) =>{

        UploadPaymentLOA(props.selectedClient.pKid, file, (progressEvent) => onUploadProgress(progressEvent, file, onProgress))
            .then(res => {
                onSuccess(null, file);
            })
            .catch(err => {
                onError();
            })


    }

    const onUploadProgress = (progressEvent, file, onProgress)=> {
        onProgress({ percent: Math.round(progressEvent.loaded / progressEvent.total * 100).toFixed(2) }, file)
    }
    const onFileUploadStatusChange = (info)=> {
        const { status } = info.file;
        if (status !== 'uploading') {
            //do something 
        }
        if (status === 'done') {
            props.fetchClientList();
        } else if (status === 'error') {
            //props.message.error(`${info.file.name} file upload failed.`);
        }
    }
    const uploadProps = {
        name: 'file',
        accept: '.pdf',
        multiple: true,
        customRequest: ({ onSuccess, onError, onProgress, file }) => onFileUpload({ onSuccess, onError, onProgress, file }),
        onChange: (info) => onFileUploadStatusChange(info),
        listType: 'text'
    };
    
    return (
        <>
            <Dragger {...uploadProps} className="RCM_File_Drop">
                <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                <p className="ant-upload-text">Click or drag file(s) to this area to upload documents</p>
            </Dragger>   
        </>
    )    


};

export default AgreementDragDrop;

