import React from 'react';

export function getApproversListColumn(addApprover, openConfirmationPopup, editApprover){
    const column = [

        {
            dataField: 'emailid',
            text: 'User Email',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return(<div title={cell}>
                        {cell}
                    </div>);
            }
        },
        {
            dataField: 'firstname',
            text: 'First Name',
            headerClasses: 'RCM_noWrap',
            //classes: 'RCM_common_table_column_text RCM_common_table_column_text_right',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'lastname',
            text: 'Last Name',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'wsportaluserid',
            text: 'WSPortalUserId',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            headerFormatter: (cell, row) => {
                return (<div align="right" className="RCM_icon_hand  RCM_manage_grp_new_group" onClick={() => addApprover()} >+ Add New</div>)
            },
            formatter: (cell, row) => {
                return (
                    <div className="RCM_displayflex">
                        <div
                            align="right"
                            className="RCM_edit_icon"
                            title="Edit approver"
                            onClick={() => editApprover(row)}>
                        </div>
                        <div
                            align="right"
                            className="RCM_manage_grp_delete_icon "
                            title="Delete Approver"
                            onClick={() => openConfirmationPopup(row)}>
                        </div>
                        
                </div>
                );
            }
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
