import { LogLevel, Logger } from 'msal';
import { store } from '..';

export const authConfiguration = () => {
    let config = {
        auth: {
            clientId: store.getState().app.env.REACT_APP_AZURE_CLIENTID,
            authority: store.getState().app.env.REACT_APP_AZURE_AUTHORITY,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        },
        system: {
            logger : logger(),
            loadFrameTimeout: 60000
        }
    }

    return config;

}

const logger = ()  => {
   return new Logger(
        loggerCallback,
        {
            correlationId: 'MSAL_LOGGING', 
            level: LogLevel.Verbose, 
            piiLoggingEnabled: true
    });
}

const loggerCallback = (logLevel, message, containsPii) => {
    console.log(`MSAL: [${logLevel} ${message}`);
}

export const apiTokenScope  = () => {
    return  {
        scopes: [store.getState().app.env.REACT_APP_AZURE_APISCOPE]
    }
}


export const isTokenRefreshRequired = (intervalTime, token) => {
    let isRefreshRequired = false;
    let parsedToken = decodeJwtToken(token);
    if (parsedToken !== null) {
        let issuedTime = getNestedObject(parsedToken, ['iat']);
        let expirationTime = getNestedObject(parsedToken, ['exp']);
        let currentTime = new Date().valueOf() / 1000;
        console.log(`Issued Time:: ${issuedTime} Expiration Time:: ${expirationTime} Current Time:: ${currentTime}`);

        if (expirationTime <= currentTime + intervalTime / 1000) {
            isRefreshRequired = true;
        }
    }

    return isRefreshRequired;
}

export function decodeJwtToken (token) {
    let response = null;
        try{
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        response  = JSON.parse(jsonPayload);
    }
    catch(exception)
    {
        //
    }
 return response;
}


export function getNestedObject(nestedObj, pathArr) {

    if(nestedObj !== null && nestedObj !== undefined 
        && pathArr !== null && pathArr !== undefined && Array.isArray(pathArr)){
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== undefined && obj[key] !== null ) ? obj[key] : "", nestedObj);
    }
}
