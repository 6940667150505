import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class GenericMessageModal extends React.PureComponent {

    constructor(props){
        super(props);
        
        this.movetodashboard = this.movetodashboard.bind(this);
    }

    movetodashboard(){
        if(this.props.history){
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.selectClientModalState}
                    toggle={this.props.toggleSelectClientModal}
                    backdrop={false}
                    //size='lg'
                    zIndex='9999'
                    className="RCM_CM_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content"
                    centered>
                    <ModalHeader>
                        
                    </ModalHeader>
                    <ModalBody>
                        <div className="row ml-2">
                            <div className="col col-sm-12">
                                {this.props.message}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            <>
                                <Button color="btn RCM_button_primary" onClick={this.movetodashboard}>MOVE TO DASHBOARD</Button>
                            </>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}


export default withRouter(GenericMessageModal);