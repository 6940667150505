import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form, FormGroup } from "reactstrap";
import { MsgForNoData } from '../../common/constants';
import { getPaymentsColumn } from './paymentsColumn.js';

export function PaymentsView(
    props
) {
    let paymentsView = (
        <div>

            <div className="RCM_Toast">
                <div className="row">
                    <div className="col-sm-10 RCM_tile_heading RCM_payments_toast_heading">
                        Payments
                        </div>
                    <div className="col-sm-2">
                        <Form>
                            <FormGroup controlId="formGridAccount">

                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Search"
                                    name="searchText"
                                    className="RCM_form_control"
                                    value={props.state.searchText}
                                    onChange={props.search}
                                />
                            </FormGroup>
                        </Form>

                    </div>
                </div>
                <div className="row RCM_billpay_back_btn_container">
                    <button type="button" class="RCM_billpay_btn" onClick={() => props.backFromPayments()}>BACK</button>
                </div>
                {props && props.state ?
                    <div id="RCM_PaymentsDashboard_Table" className="RCM_ManageGroup_Tables RCM_CM_scroll RCM_ManageAsset_Table">
                        <BootstrapTable
                            keyField='paymentId'
                            data={props.state.paymentsList}
                            columns={getPaymentsColumn(props.retryPayment)}
                            headerClasses="row-bg"
                            bordered={false}
                            // rowClasses={props.grpTableRowClasses}
                            classes="RCM_two_level_table1"
                            noDataIndication={MsgForNoData}
                        //cellEdit={props.cellEditFactory({ mode: 'dbclick', blurToSave: true, afterSaveCell: props.handleChange })}
                        //expandRow={props.expandRow}
                        //selectRow={props.selectAssetRow}
                        />
                    </div>
                    : <div key="ASDropdown22">{MsgForNoData}</div>
                }

            </div>


        </div>
    )
    return paymentsView
}