import { submitDiscritionaryDistributionUrl, apiBaseUrl, getEntityInfomationUrl, 
    getDiscritionaryDistributionListUrl, getDiscritionaryDistributionUrl, createDistributionPdfUrl, uploadDiscretionaryDistributionDocsUrl,
    createDocusignEnvelopeUrl, updateDiscritionaryDistributionUrl, fetchDistributionDocumentsUrl, getDiscretionaryDocumentsListUrl,
    getCompletedDiscritionaryDistributionListUrl, updatedistributionStatusUrl, deleteDiscrtionaryDocumentUrl, deleteDiscrtionaryDistributionUrl } from "../serviceConstant";
import {errorMsg} from '../../common/constants';
import { Post, Get, PostMultiPart, Delete} from "../servicecalls";
import axios from "axios";
import {toUTF8Array} from '../../common/utilities';

export var SubmitDiscritionaryDistributionCancellationToken = null;
export var GetEntityInfomationCancellationToken = null;
export var GetDiscretionaryDistributionCancellationToken = null;
export var GetDiscretionaryDistributionInfoCancellationToken =null;
export var CreateDocuSignEnvelopDDCancellationToken = null;
export var CreateDistributionPdfCancellationToken = null;
export var UpdateDiscritionaryDistributionCancellationToken =null;
export var FetchDiscretionDistributionDocumentCancellationToken = null;
export var GetCompletedDiscretionaryDistributionCancellationToken = null;
export var UpdateDiscritionaryDistributionStatusCancellationToken = null;
export var GetDiscretionaryDistributionDocumentListCancellationToken = null;
export var DeleteDiscretionaryDistributionDocumentCancellationToken = null;
export var DeleteDiscretionaryDistributionCancellationToken = null;

export const SubmitDiscritionaryDistribution = (request, mode) => {

    if (SubmitDiscritionaryDistributionCancellationToken !== null && SubmitDiscritionaryDistributionCancellationToken !== undefined) {
        //Cancel the existing call
        SubmitDiscritionaryDistributionCancellationToken.cancel('Cancelling the get vendor list request to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    SubmitDiscritionaryDistributionCancellationToken = cancelToken.source();


    let submitDiscritionaryDistributionPromise = new Promise(
        function (resolve, reject) {
            let data = {
                "IsSubmit": mode === 'SUBMIT' ? true : false,
                "DiscritionDistribution": request
            }

            let byteArr = toUTF8Array(JSON.stringify(data));

            Post(submitDiscritionaryDistributionUrl, byteArr, SubmitDiscritionaryDistributionCancellationToken).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Bill Pay Service : Error submit distribution list : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return submitDiscritionaryDistributionPromise;
}


export const GetEntityInfomation = (entityId) => {

    if (GetEntityInfomationCancellationToken !== null && GetEntityInfomationCancellationToken !== undefined) {
        //Cancel the existing call
        GetEntityInfomationCancellationToken.cancel('Cancelling the get entity data to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetEntityInfomationCancellationToken = cancelToken.source();


    let submitDiscritionaryDistributionPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${getEntityInfomationUrl}?rockItClientID=${entityId}`
            Get(url, GetEntityInfomationCancellationToken).then(res => {
                if(res && res.data && res.data.error_code && res.data.error_code === "0"){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error get entity info : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return submitDiscritionaryDistributionPromise;
}


export const GetDiscretionaryDistribution = (emailId, showAllTxn) => {


    if (GetDiscretionaryDistributionCancellationToken !== null && GetDiscretionaryDistributionCancellationToken !== undefined) {
        //Cancel the existing call
        GetDiscretionaryDistributionCancellationToken.cancel('Cancelling the get list of distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetDiscretionaryDistributionCancellationToken = cancelToken.source();


    let submitDiscritionaryDistributionPromise = new Promise(
        function (resolve, reject) {
            let url = `${getDiscritionaryDistributionListUrl}?email=${emailId}&alltxn=${showAllTxn}`
            Get(url, GetDiscretionaryDistributionCancellationToken).then(res => {
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error get distribution list : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return submitDiscritionaryDistributionPromise;
}

export const GetCompletedDiscretionaryDistribution = (emailId, showAllTxn) => {

    if (GetCompletedDiscretionaryDistributionCancellationToken !== null && GetCompletedDiscretionaryDistributionCancellationToken !== undefined) {
        //Cancel the existing call
        GetCompletedDiscretionaryDistributionCancellationToken.cancel('Cancelling the get list of distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetCompletedDiscretionaryDistributionCancellationToken = cancelToken.source();


    let getDistributionlistPromise = new Promise(
        function (resolve, reject) {
            let url = `${getCompletedDiscritionaryDistributionListUrl}?email=${emailId}&alltxn=${showAllTxn}`
            Get(url, GetCompletedDiscretionaryDistributionCancellationToken).then(res => {
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error get distribution list : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return getDistributionlistPromise;
}


export const GetDiscretionaryDistributionInfo = (distributionId) => {

    if (GetDiscretionaryDistributionInfoCancellationToken !== null && GetDiscretionaryDistributionInfoCancellationToken !== undefined) {
        //Cancel the existing call
        GetDiscretionaryDistributionInfoCancellationToken.cancel('Cancelling the get list of distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetDiscretionaryDistributionInfoCancellationToken = cancelToken.source();


    let submitDiscritionaryDistributionPromise = new Promise(
        function (resolve, reject) {
            let url = `${getDiscritionaryDistributionUrl}?id=${distributionId}`
            Get(url, GetDiscretionaryDistributionInfoCancellationToken).then(res => {
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error get distribution information : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return submitDiscritionaryDistributionPromise;
}



export const CreateDocuSignEnvelopDD = (distributionId) => {

    if (CreateDocuSignEnvelopDDCancellationToken !== null && CreateDocuSignEnvelopDDCancellationToken !== undefined) {
        //Cancel the existing call
        CreateDocuSignEnvelopDDCancellationToken.cancel('Cancelling the get list of distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    CreateDocuSignEnvelopDDCancellationToken = cancelToken.source();


    let CreateDocuSignEnvelopDDPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${createDocusignEnvelopeUrl}?id=${distributionId}`
            Get(url, CreateDocuSignEnvelopDDCancellationToken).then(res => {
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error to create docusign : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return CreateDocuSignEnvelopDDPromise;
}


export const CreateDistributionPdf = (distributionId) => {

    if (CreateDistributionPdfCancellationToken !== null && CreateDistributionPdfCancellationToken !== undefined) {
        //Cancel the existing call
        CreateDistributionPdfCancellationToken.cancel('Cancelling the get list of distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    CreateDistributionPdfCancellationToken = cancelToken.source();


    let CreateDocuSignEnvelopDDPromise = new Promise(
        function (resolve, reject) {
            let url = `${apiBaseUrl()}/${createDistributionPdfUrl}?id=${distributionId}`
            Post(url, '', CreateDistributionPdfCancellationToken).then(res => {
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error to create distributio pdf : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return CreateDocuSignEnvelopDDPromise;
}

export const UpdateDiscritionaryDistribution = (request, mode) => {

    if (UpdateDiscritionaryDistributionCancellationToken !== null && UpdateDiscritionaryDistributionCancellationToken !== undefined) {
        //Cancel the existing call
        UpdateDiscritionaryDistributionCancellationToken.cancel('Cancelling the update discretionary distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    UpdateDiscritionaryDistributionCancellationToken = cancelToken.source();


    let submitDiscritionaryDistributionPromise = new Promise(
        function (resolve, reject) {
            let data = {
                "IsSubmit": mode === 'SUBMIT' ? true : false,
                "DiscritionDistribution": request
            }

            let byteArr = toUTF8Array(JSON.stringify(data));

            Post(updateDiscritionaryDistributionUrl, byteArr, UpdateDiscritionaryDistributionCancellationToken).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Bill Pay Service : Error submit distribution list : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return submitDiscritionaryDistributionPromise;
}


export const FetchDiscretionDistributionDocument = (distributionId, doctype, documentId) => {

    if (FetchDiscretionDistributionDocumentCancellationToken !== null && FetchDiscretionDistributionDocumentCancellationToken !== undefined) {
        //Cancel the existing call
        FetchDiscretionDistributionDocumentCancellationToken.cancel('Cancelling the get list of distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    FetchDiscretionDistributionDocumentCancellationToken = cancelToken.source();


    let FetchDiscretionDistributionDocumentPromise = new Promise(
        function (resolve, reject) {
            let url = `${fetchDistributionDocumentsUrl}?id=${distributionId}&doctype=${doctype}&docId=${documentId}`
            Get(url, FetchDiscretionDistributionDocumentCancellationToken).then(res => {
                if(res && res.data){
                    resolve(res.data);
                }else{
                    reject(errorMsg);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error while fetching document : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return FetchDiscretionDistributionDocumentPromise;
}


export const UpdateDiscritionaryDistributionStatus = (id, status) => {

    if (UpdateDiscritionaryDistributionStatusCancellationToken !== null && UpdateDiscritionaryDistributionStatusCancellationToken !== undefined) {
        //Cancel the existing call
        UpdateDiscritionaryDistributionStatusCancellationToken.cancel('Cancelling the update discretionary distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    UpdateDiscritionaryDistributionStatusCancellationToken = cancelToken.source();


    let updateDistributionStatusPromise = new Promise(
        function (resolve, reject) {
            let data = {
                "id": id,
                "status": status
            }


            Post(updatedistributionStatusUrl, data, UpdateDiscritionaryDistributionStatusCancellationToken).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Bill Pay Service : Error update distribution status : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return updateDistributionStatusPromise;
}



export const UploadDiscretionaryDocument = (id, file, email, onUploadProgress) => {

    let UploadDiscretionaryDocumentPromise = new Promise(
        function (resolve, reject) {
            let formData = new FormData();
            formData.append('DistributionId', id);
            formData.append('UserEmail', email);
            formData.append('File', file);

            PostMultiPart(uploadDiscretionaryDistributionDocsUrl, formData, null, onUploadProgress).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Bill Pay Service : Error update distribution status : :: ", error);
                    reject(errorMsg);
                }
            });
        });

    return UploadDiscretionaryDocumentPromise;
}

export const GetDiscretionaryDistributionDocumentList = (id) => {

    if (GetDiscretionaryDistributionDocumentListCancellationToken !== null && GetDiscretionaryDistributionDocumentListCancellationToken !== undefined) {
        //Cancel the existing call
        GetDiscretionaryDistributionDocumentListCancellationToken.cancel('Cancelling to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    GetDiscretionaryDistributionDocumentListCancellationToken = cancelToken.source();


    let GetDiscretionaryDistributionDocumentListPromise = new Promise(
        function (resolve, reject) {
            let url = `${getDiscretionaryDocumentsListUrl}?distributionId=${id}`
            Get(url, GetDiscretionaryDistributionDocumentListCancellationToken).then(res => {
                resolve(res.data);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Bill Pay Service : Error fetch distribution documents list : :: ", error);
                    reject(errorMsg);
                }
            });
        });

    return GetDiscretionaryDistributionDocumentListPromise;
}


export const DeleteDiscretionaryDistributionDoc = (docId) => {

    if (DeleteDiscretionaryDistributionDocumentCancellationToken !== null && DeleteDiscretionaryDistributionDocumentCancellationToken !== undefined) {
        //Cancel the existing call
        DeleteDiscretionaryDistributionDocumentCancellationToken.cancel('Cancelling the delete distribution doc to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    DeleteDiscretionaryDistributionDocumentCancellationToken = cancelToken.source();


    let deleteDiscritionaryDistributionDocPromise = new Promise(
        function (resolve, reject) {
            let url = `${deleteDiscrtionaryDocumentUrl}?docId=${docId}`
            Delete(url, null, DeleteDiscretionaryDistributionDocumentCancellationToken).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error delete distribution doc : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return deleteDiscritionaryDistributionDocPromise;
}

export const DeleteDiscretionaryDistribution = (id, email) => {

    if (DeleteDiscretionaryDistributionCancellationToken !== null && DeleteDiscretionaryDistributionCancellationToken !== undefined) {
        //Cancel the existing call
        DeleteDiscretionaryDistributionCancellationToken.cancel('Cancelling the delete distribution to prevent memory leaks.');
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    DeleteDiscretionaryDistributionCancellationToken = cancelToken.source();

    const data = {
        'DistributionId': id,
        'RequestorEmail': email
    }

    let deleteDiscritionaryDistributionPromise = new Promise(
        function (resolve, reject) {
            let url = `${deleteDiscrtionaryDistributionUrl}`

            Post(url, data, DeleteDiscretionaryDistributionCancellationToken).then(res => {
                resolve(res);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    //Log the error on console incase of failure. 
                    console.log("Discretionary Distributiob Service : Error delete distribution  : :: ", error);
                    reject(errorMsg);
                }
            });
        });
    return deleteDiscritionaryDistributionPromise;
}


