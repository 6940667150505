import React, { Component } from 'react'
import { GetDiscretionaryDistributionDocumentList, FetchDiscretionDistributionDocument, DeleteDiscretionaryDistributionDoc } from '../../../services/discritionarydistributionservice/discritionarydistributionservice';
import { MsgForNoData } from '../../../common/constants';
import { getDiscretionaryDocumentColumns } from './discretionartdistributiondocColumn';
import BootstrapTable from 'react-bootstrap-table-next';
import {addIndexToList} from '../../../common/utilities';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import PdfViwerModal from '../../../common/pdfViwerModal';

class DiscretionaryDocumentsList extends Component {
    constructor(props){
        super(props);
        this.state = {
            documentsList: [],
            pdfViewerState: false,
            pdfdata: null
        }

        this.deleteDocument = this.deleteDocument.bind(this);
        this.togglePdfViewer = this.togglePdfViewer.bind(this);
        this.openDocument = this.openDocument.bind(this);
    }

    componentDidMount(){
        this.fetchDiscretionaryDocumentsList();
    }

    fetchDiscretionaryDocumentsList(){
        let distributionId = this.props.distributionID ? this.props.distributionID : '';
        if(distributionId){
            GetDiscretionaryDistributionDocumentList(distributionId)
            .then(res => {
                this.setState({documentsList: [...addIndexToList(res)]});
            })
            .catch(err => {
                console.error(err);
            })
        }
    }

    openDocument(row){
        if(row && row.distributionId){
            //then call api 
            let docType = row.documentType === 'Process Document' ? row.documentName === 'Sent File' ? 'SIGN' : 'SIGNED' : 'ADDITIONAL';
            FetchDiscretionDistributionDocument(row.distributionId, docType, row.documentId).then(doc => {
                this.setState({pdfViewerState: true, pdfdata: doc});
            })
            .catch(err => {
                console.error(err);
            });
        }
    }

    deleteDocument(row){
        let docId = row && row.documentId ? row.documentId : '';
        DeleteDiscretionaryDistributionDoc(docId).then(res => {
            message.info("Document Deleted");
            this.fetchDiscretionaryDocumentsList();
        })
        .catch(err => {
            console.log(err);
        })
    }

    togglePdfViewer(){
        this.setState({pdfViewerState:  !this.state.pdfViewerState});
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="RCM_tile_heading RCM_payments_toast_heading">
                            ADDITIONAL DOCUMENTS LIST
                        </div>
                    </div>
                </div>
                
                <div className="mt-1">
                    <BootstrapTable
                        id="discretionary_distribution_docs_list"
                        keyField='uniqueIdx'
                        data={this.state.documentsList}
                        columns={getDiscretionaryDocumentColumns(this.openDocument, this.props.mode, this.deleteDocument)}
                        headerClasses="row-bg"
                        bordered={false}
                        classes="RCM_two_level_table1"
                        rowClasses='RCM_tow_level_table1_row_animation'
                        noDataIndication={MsgForNoData}
                    />
                </div>
                <PdfViwerModal modalState={this.state.pdfViewerState} toggleModal={this.togglePdfViewer} pdfdata={this.state.pdfdata} />
            </div>
        )
    }
}

export default withRouter(DiscretionaryDocumentsList);
