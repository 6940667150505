import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default class HowToPayModal extends React.PureComponent{

    constructor(props){
        super(props);
        this.onModalOpen = this.onModalOpen.bind(this);
    }

    onModalOpen(){
           
    }

    render(){

    return (
        <div>
                <Modal
                    isOpen={this.props.howToPayModalState}
                    toggle={this.props.toggleHowtoPayAcceptModal}
                    backdrop={false}
                    onOpened={this.onModalOpen}
                    //size='lg'
                    zIndex='9999'
                    className="RCM_CM_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content"
                    centered>
                    <ModalHeader toggle={this.props.toggleHowToPayAcceptModal} charCode="X" ></ModalHeader>
                    <ModalBody>
                        {
                            <>
                                <div>
                                    How  will you pay this vendor?
                                </div>
                                <div>
                                    
                                </div>
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {
                            <>
                                <Button color="btn RCM_button_secondary" onClick={this.props.payUsingWire}>DOMESTIC WIRE</Button>
                                <Button color="btn RCM_button_primary" onClick={this.props.payUsingOtherPayment}>OTHER PAYMENT METHOD</Button>
                            </>   
                        }
                    </ModalFooter>
                </Modal>
            </div>
    )
}
}
