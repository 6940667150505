import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetDiscretionaryDistribution, FetchDiscretionDistributionDocument, DeleteDiscretionaryDistribution } from '../../services/discritionarydistributionservice/discritionarydistributionservice';
import BootstrapTable from 'react-bootstrap-table-next';
import { MsgForNoData } from '../../common/constants';
import { arrayFilterByValue, getPreferenceValue } from '../../common/utilities.js';
import { getDistributionColumn } from './distributionlistColumn';
import { UpdateDiscretionaryDistributionMode } from '../../actions/discretionaryDistributionAction';
import { Spinner } from 'reactstrap';
import PdfViwerModal from '../../common/pdfViwerModal';
import { message } from 'antd';

class DiscretionDistributionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            distributionList: [],
            filteredDistrubtionList: [],
            searchText: '',
            isLoading: true,
            pdfViewerState: false,
            pdfdata: null,
            showAllTxn: getPreferenceValue(this.props.faFeatures, 'DISCRETIONARY_DIST_ALLTXN')
        }

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.openDocument = this.openDocument.bind(this);
        this.handleViewDocumentsClick = this.handleViewDocumentsClick.bind(this);
        this.togglePdfViewer = this.togglePdfViewer.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);

        this.defaultDistributionSorted = [{
            dataField: 'requestDate',
            order: 'desc'
        }]
    }

    componentDidMount() {

        this.fetchDiscretionaryDistribution();
    }

    componentDidUpdate(prevProps){
        if (prevProps.refresh !== this.props.refresh) {
            this.setState({isLoading: true}, () => {
                this.fetchDiscretionaryDistribution();
            })
        }
    }

    fetchDiscretionaryDistribution(){
        if (this.props.user && this.props.user.userName) {
            GetDiscretionaryDistribution(this.props.user.userName, this.state.showAllTxn).then(res => {
                this.setState({ distributionList: res, filteredDistrubtionList: res, isLoading: false });
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.error(err);
            })
        }
    }

    handleOnNewDistribution() {
        this.props.UpdateDiscretionaryDistributionMode(null, 'ADD').then(() => {
            if (this.props.history) {
                this.props.history.push("/discretionaryrequest");
            }
        })
    }

    filterDistrubtion(evt) {
        //filter here..
        let searchString = evt.target.value;
        if (searchString) {
            var updatedDistributionList = [...this.state.distributionList];
            let searchKeyList = ['requestDate', 'trustTitle', 'entityNumber', 'distributionAmount', 'trustees', 'status'];
            updatedDistributionList = arrayFilterByValue(updatedDistributionList, searchString, searchKeyList);
            this.setState({ filteredDistrubtionList: updatedDistributionList, searchText: searchString });
        } else {
            this.setState({ filteredDistrubtionList: this.state.distributionList, searchText: searchString });
        }

    }

    handleEditClick(row) {
        if (row) {
            this.props.UpdateDiscretionaryDistributionMode(row, 'UPDATE').then(() => {
                if (this.props.history) {
                    this.props.history.push("/discretionaryrequest");
                }
            })
        }
    }

    handleViewClick(row){
        if (row) {
            this.props.UpdateDiscretionaryDistributionMode(row, 'VIEW').then(() => {
                if (this.props.history) {
                    this.props.history.push("/discretionaryrequest");
                }
            })
        }
    }

    handleDeleteClick(row){
        console.log('Delete Record ', row);
        const id = row.distributionID;
        const email =  this.props.user && this.props.user.userName ? this.props.user.userName : '';

        DeleteDiscretionaryDistribution(id, email)
        .then(res => {
            console.log(res);
            message.info("Successfully deleted distribution");
            this.fetchDiscretionaryDistribution();
        })
        .catch(err => {
            message.error("We are unable to delete distribution");
        })
    }

    openDocument(entityId, doctype){
        FetchDiscretionDistributionDocument(entityId, doctype, '').then(doc => {
            this.setState({pdfViewerState: true, pdfdata: doc});
        })
        .catch(err => {
            console.error(err);
        })
    }

    handleViewDocumentsClick(row){
        if(row){
            let mode = row.status === 'Draft' ? 'UPDATE' : 'VIEW';
            this.props.UpdateDiscretionaryDistributionMode(row, mode).then(() => {
                if(this.props.history){
                    this.props.history.push('/discretionarydistribution/documents')
                }
            })
        }
    }

    togglePdfViewer(){
        this.setState({pdfViewerState:  !this.state.pdfViewerState});
    }


    render() {
        return (
            <div className="RCM_Toast">
                {
                    this.state.isLoading ?
                        (<div align="center" className="mt-4" style={{color: '#fff'}}>Loading Distribution List
                        &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner>
                        </div>) :
                        (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-4">
                                        <div>
                                            <input type="text" className="RCM_form_control" placeholder="Search for your distribution"
                                                value={this.state.searchText}
                                                onChange={(e) => this.filterDistrubtion(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-8">
                                        <div align="right" className="mt-2">
                                            <button type="button" className="RCM_billpay_btn" onClick={() => this.handleOnNewDistribution()}>NEW DISTRIBUTION</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <BootstrapTable
                                            id="admin_clientvisibility_clientlist"
                                            keyField='distributionID'
                                            data={this.state.filteredDistrubtionList}
                                            columns={getDistributionColumn(this.handleEditClick, this.handleViewClick, this.openDocument, this.handleViewDocumentsClick, this.handleDeleteClick, this.state.showAllTxn)}
                                            headerClasses="row-bg"
                                            bordered={false}
                                            classes="RCM_two_level_table1"
                                            rowClasses='RCM_tow_level_table1_row_animation'
                                            noDataIndication={MsgForNoData}
                                            defaultSorted={this.defaultDistributionSorted}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                }

                <PdfViwerModal modalState={this.state.pdfViewerState} toggleModal={this.togglePdfViewer} pdfdata={this.state.pdfdata} />
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
        faFeatures: store.app.faFeatures
    };
}

const mapDispatchToProps = {
    UpdateDiscretionaryDistributionMode
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DiscretionDistributionList));

