import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UploadInvoiceView } from './uploadInvoiceView.jsx';
import { uploadInvoiceViewAnimation } from '../../common/animation.js';

export class UploadInvoice extends Component {
    constructor(props) {
        super(props);
        this.viewInvoice = this.viewInvoice.bind(this);
        this.vendorId = (this.props.match && this.props.match.params && this.props.match.params.id ) ? this.props.match.params.id : '';
        this.userEmail = (this.props.user && this.props.user.userName) ? this.props.user.userName : '';

    }
    viewInvoice = rows => {
        alert("in viewInvoice function");
    }

    backFromUploadInvoice = () => {
       if(this.props.history){
           this.props.history.push('/');
       }
    }

    componentDidMount(){
        uploadInvoiceViewAnimation();
    }

    render() {
        return (
            <UploadInvoiceView
                state={this.state}
                viewInvoice={this.viewInvoice}
                backFromUploadInvoice={this.backFromUploadInvoice}
                vendorId={this.vendorId}
                selectedClient={this.props.selectedClient}
                userEmail={this.userEmail}
            />
        );

    }
}
const mapStateToProps = (store) => {
    return {
        selectedClient: store.dashboard.selectedClient,
        user: store.app.user
    };
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadInvoice));

