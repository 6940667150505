import React from 'react';

export function getPaymentsColumn(retryPayment) {
    const column = [

        {
            dataField: 'vendorName',
            text: 'Vendor Name',
            headerClasses: 'RCM_noWrap RCM_clientName_col',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'paymentNumber',
            text: 'Payment #',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'paymentType',
            text: 'Payment Type',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'paymentStatus',
            text: 'Status',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (cell === "success")
                    return <div>Success</div>;
                else
                    return <div class="RCM_negativeval">Failed</div>;
            },
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            classes: 'RCM_manage_groups_delete_col',
            formatter: (cell, row) => {
                if (row.paymentStatus === "success")
                    return <div></div>
                else
                    return <button type="button" class="RCM_billpay_btn" onClick={() => retryPayment(row)}>RETRY</button>;
            },
            events: {
                // onClick: this.openConfirmationPopup
            }
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}
export default getPaymentsColumn;