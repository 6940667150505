import React from 'react';
import { formatInCurrency, roundvalue, formatMoney, roundUnitValue } from './../../../common/utilities';


export const defaultSorted = [{ dataField: 'dtsettle', order: 'desc' }];
export function getSourceDataColumns(accounts) {
    const columns = [
        {
            dataField: 'dttrade',
            text: 'Trade Date',
            sort: true,
            classes: 'RCM_common_table_column_text RCM_noWrap',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell.split('T')[0]}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'dtsettle',
            text: 'Settlement Date',
            headerClasses: 'RCM_noWrap',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell.split('T')[0]}</span>;
            }
        },
        {
            dataField: 'accountnickname',
            text: 'Account',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'trndesc',
            text: 'Description',
            sort: false,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'trntype',
            text: 'Type',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'secid',
            text: 'Symbol/Cusip',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'units',
            text: 'Quantity',
            sort: true,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>0</span>;
                else return <span>{roundUnitValue(cell)}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'unitprice',
            text: 'Price',
            sort: true,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell == 0) return <span>$0</span>;
                else return <span>{formatInCurrency(cell)}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'total',
            text: 'Amount',
            sort: true,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else {
                    //let modifiedCell = parseFloat(String(cell).replace("*", ""));
                    if (cell < 0) return <span className="RCM_negative">{row.ispending
                        ? formatMoney(roundvalue(cell, 2)) + "*"
                        : formatMoney(roundvalue(cell, 2))}</span>;
                    else if (cell > 0) return <span className="RCM_positive">{row.ispending
                        ? formatMoney(roundvalue(cell, 2)) + "*"
                        : formatMoney(roundvalue(cell, 2))}</span>;
                    else return <span>$0</span>;
                }

            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
    ]
    return columns;
}

export function getAccountingDataColumns(accounts) {
    const columns = [
        {
            dataField: 'transdate',
            text: 'Transaction Date',
            sort: true,
            classes: 'RCM_common_table_column_text RCM_noWrap',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell.split('T')[0]}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'accountdisplayvalue',
            text: 'Account',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'subaccount',
            text: 'Posting Account',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'subaccount',
            text: 'SubAccount',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'vendor',
            text: 'Vendor',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: false,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            }
        },
        {
            dataField: 'trntype',
            text: 'Type',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'creditamount',
            text: 'Credit Amount',
            sort: true,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>
                else {
                    //let modifiedCell = parseFloat(String(cell).replace("*", ""));
                    //if (cell < 0) return <span className="RCM_negative">{row.ispending
                    //    ? formatMoney(roundvalue(cell, 2)) + "*"
                    //    : formatMoney(roundvalue(cell, 2))}</span>;
                    //else if (cell > 0) return <span className="RCM_positive">{row.ispending
                    //    ? formatMoney(roundvalue(cell, 2)) + "*"
                    //    : formatMoney(roundvalue(cell, 2))}</span>;
                    return <span>{formatMoney(roundvalue(cell, 2))}</span>;
                }

            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'debitamount',
            text: 'Debit Amount',
            sort: true,
            headerAlign: 'right',
            classes: 'RCM_common_table_column_number',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else if (cell === 0) return <span>$0</span>
                else {
                    //let modifiedCell = parseFloat(String(cell).replace("*", ""));
                    //if (cell < 0) return <span className="RCM_negative">{row.ispending
                    //    ? formatMoney(roundvalue(cell, 2)) + "*"
                    //    : formatMoney(roundvalue(cell, 2))}</span>;
                    //else if (cell > 0) return <span className="RCM_positive">{row.ispending
                    //    ? formatMoney(roundvalue(cell, 2)) + "*"
                    //    : formatMoney(roundvalue(cell, 2))}</span>;
                    return <span>{formatMoney(roundvalue(cell, 2))}</span>;
                }

            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'offsetaccountdisplayvalue',
            text: 'Offset Account',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return <span>{cell}</span>;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'subaccount',
            text: 'SubAccount',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
        {
            dataField: 'vendor',
            text: 'Vendor',
            sort: true,
            classes: 'RCM_common_table_column_text',
            headerAlign: 'left',
            formatter: (cell, row) => {
                if (cell === undefined || cell === null || cell === '') return <span>-</span>;
                else return row.accountnickname;
            },
            sortCaret: (order, column) => customCaretSort(order, column)
        },
    ]
    return columns;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}


