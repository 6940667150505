import React from 'react'
import UploadHedgeFundView from './uploadHedgeFundView'
import { useSelector } from 'react-redux'
import { getNestedObject } from '../../common/common';

function UploadHedgeFund() {

    const email = useSelector(state => getNestedObject(state, ['app', 'user', 'userName']));

    return (
        <div>
            <UploadHedgeFundView email={email} />
        </div>
    )
}

export default UploadHedgeFund
