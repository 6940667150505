import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { getFailedPaymentColumns } from "./failedpaymentColumns";
import { MsgForNoData } from "../../../common/constants";
import {
  getFailedPaymentList,
  updateFailedPayment,
} from "../../../services/adminServices/failedPaymentService";
import { useState } from "react";
import { message } from "antd";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import moment from "moment";

const filterPaymentByText = (payments, text) => {
  let filtered = []
  if(payments && text && Array.isArray(payments)) {
    const lowerCaseText = text.toLowerCase();
    filtered = payments.filter(p => {
       if((p.clientName && p.clientName.toLowerCase().includes(lowerCaseText))
         || (p.legalEntityId && p.legalEntityId.toLowerCase().includes(lowerCaseText))
         || (p.vendorName && p.vendorName.toLowerCase().includes(lowerCaseText))
         || (p.vendorName && p.vendorName.toLowerCase().includes(lowerCaseText))
         || (p.statusCode && p.statusCode.toLowerCase().includes(lowerCaseText))
         || (p.statusDesc && p.statusDesc.toLowerCase().includes(lowerCaseText))
         || (p.paymentItems && Array.isArray(p.paymentItems) && p.paymentItems.length > 0 && p.paymentItems[0] && p.paymentItems[0].amount && p.paymentItems[0].amount.toString().includes(text))
         || (p.dueDate && moment(p.dueDate).format("L").includes(text))
       )
       {
         return true;
       } else {
         return false 
       }
    })
  }
  return filtered;
}

const FailedPayments = () => {
  const [searchtext, setSearchText] = useState();
  const [failedPayments, setFailedPayments] = useState();
  const [filteredPayments, setFilteredPayments] = useState();
  const refresh = useSelector((store) => store.app.refresh);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //call api and update the failedPayment
    setIsLoading(true);
    getFailedPaymentList()
      .then((res) => {
        if (res && Array.isArray(res)) {
          setFailedPayments(res);
          setFilteredPayments(searchtext ? filterPaymentByText(res, searchtext) : res)
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error While fetching failed payments", err);
        message.error("Unable to fetch failed payments");
      });
  }, [refresh]);

  const retryFailedPayment = useCallback((row) => {
    updateFailedPayment(row)
      .then(() => {
        getFailedPaymentList()
          .then((response) => {
            if (response && Array.isArray(response)) {
              setFailedPayments(response);
              setFilteredPayments(searchtext ? filterPaymentByText(response, searchtext) : response)
              message.success("Payment Updated");
            }
          })
          .catch((err) => {
            console.error("Error While fetching failed payments", err);
            message.error(
              "Failed Payment updated but unable to fetch failed payments information"
            );
          });
      })
      .catch((err) => {
        console.error("Error while updating failed payment", err);
        message.error("Unable to update failed payment");
      });
  }, []);

  const handleOnSearchTextChange = (event) => {
    const _searchText = event && event.target ? event.target.value : undefined
     setSearchText(_searchText)
     setFilteredPayments(_searchText ? filterPaymentByText(failedPayments, _searchText) : failedPayments)
  }


  if (isLoading) {
    return (
      <div align="center">
        Loading Payments For Action <Spinner size="sm" animation="grow" />
      </div>
    );
  }

  return (
    <div className="RCM_Toast">
      <div className="row">
        <div className="col-sm-6">
        <input type="text" value={searchtext} onChange={handleOnSearchTextChange} 
          placeholder="Search Payments" 
          className="RCM_form_control"/>
          </div>
      </div>
      <div className="row mt-1">
        <div className="col-sm-12 col-md-12 col-lg-12" id="admin_wireslist">
          <BootstrapTable
            keyField="billId"
            data={filteredPayments ? filteredPayments : []}
            columns={getFailedPaymentColumns(retryFailedPayment)}
            headerClasses="row-bg"
            bordered={false}
            classes="RCM_two_level_table1"
            rowClasses="RCM_tow_level_table1_row_animation"
            noDataIndication={MsgForNoData}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(FailedPayments);
