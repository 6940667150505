import React from 'react';
import {getDashBoardViewGridColumn} from './DashBoardViewColumns';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form, FormGroup} from "reactstrap";
import {MsgForNoData} from  '../../common/constants';
import Payments from '../clientpayments/payments';
import SubAccounts from '../accounting/subAccounts';

export default function DashBoardView(props) {

    const renderConditionalView = () => {

        if(props && props.state && props.state.openClientsPayments){
            return(
                <Payments state={props.state} backFromPayments={props.backFromPayments} />
            )
        }else if (props && props.state && props.state.openSubAccounts) {
            return (
                <SubAccounts state={props.state} backFromSubAccounts={props.backFromSubAccounts} />
            )
        }       
        else{
            return (
                <div className="RCM_Toast">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 RCM_tile_heading RCM_payments_toast_heading">
                            <label key='paymentDashboardheading'>PAYMENTS DASHBOARD</label> 
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Form>
                                <FormGroup controlid="formGridAccount">
                                    
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Search"
                                        name="searchText"
                                        className="RCM_form_control"
                                        value={props.state.searchText}
                                        onChange={props.handleFormChanges}
                                    />
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                    {props && props.state ?
                        <div id="RCM_PaymentsDashboard_Table">
                            <BootstrapTable
                                keyField='orgId'
                                data={props.state.filteredClientsList}
                                columns={getDashBoardViewGridColumn(props.viewInvoice, props.uploadInvoice, props.goToVendors, props.goToPayments, props.openInboxWindow, props.goToSubAccounts, props.contextualMenu)}
                                headerClasses="row-bg"
                                bordered={false}
                                classes="RCM_two_level_table1"
                                rowClasses="RCM_tow_level_table1_row_animation"
                                noDataIndication={MsgForNoData}
                            />
                        </div>
                        : <div key="ASDropdown22">{MsgForNoData}</div>
                    }
                </div>
            )
        }

    }

    return (
        <div id="dashboardview">
            {
                renderConditionalView()
            }
        </div>
    )
}
