import axios from "axios";
import { getEnvironmentVariableUrl, financialAdvisorsFeatureUrl } from "../serviceConstant";
import { Get } from "../servicecalls";


export var getEnvironmentVariablesCancellationToken = null;
export var getfaFeaturesCancellationToken = null;


export const getEnvironmentVariables = () => {
    

    if (getEnvironmentVariablesCancellationToken !== null && getEnvironmentVariablesCancellationToken !== undefined) {
        getEnvironmentVariablesCancellationToken.cancel("Cancel existing get environment variables to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getEnvironmentVariablesCancellationToken = cancelToken.source();

    let getEnvironmentVariablePromise = new Promise(function (resolve, reject) {
        Get(getEnvironmentVariableUrl, getEnvironmentVariablesCancellationToken).then(
            environmentRes => {
                resolve(environmentRes);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching environment data", err);
                reject(err);
            }
        })
    });


    return getEnvironmentVariablePromise;


}

export const getfaFeatures  = (emailId) => {

    if (getfaFeaturesCancellationToken !== null && getfaFeaturesCancellationToken !== undefined) {
        getfaFeaturesCancellationToken.cancel("Cancel existing get fa featurescall to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getfaFeaturesCancellationToken = cancelToken.source();

    let getfaFeaturesPromise = new Promise(function (resolve, reject) {
        const faFeaturesUrl = `${financialAdvisorsFeatureUrl}?emailId=${emailId}`;
        Get(faFeaturesUrl, getfaFeaturesCancellationToken).then(
            faResponse => {
                resolve(faResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching fa features", err);
                reject(err);
            }
        })
    });


    return getfaFeaturesPromise;

}
