import download from 'downloadjs';
export function downloadDoc(data, fileName) {
    if (data) {
        var base64str = data;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        var blob = new Blob([view], { type: "application/pdf" });

        fileName = fileName.split('/').join('') + '.pdf'
        download(blob, fileName, "application/pdf");
        return 'pdf downloaded'


    } else {
        console.log("didn't receive document from webapi")
        return null
    }
}
export const validateEmailId = (emailid) => {
    var isValid = true;
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailid && pattern.test(emailid)) {
        isValid = true;
    }
    else {
        isValid = false;
    }
    return isValid;
}

export function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

export function downloadReferenceDoc(data, fileName,contentType) {
    if (data) {
        var base64str = data;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        var blob  = new Blob([view], { type: contentType });
        download(blob, fileName, contentType);
        return 'file downloaded';
    } else {
        console.log("didn't receive document from webapi")
        return null
    }
}

export function removeNonAlphaNumericChar(input){
    let response = '';
    if(input !== null && input !== undefined){
        response = input.replace(/[^0-9a-z]/gi, '');
    }

    return response;
}


export function getPreferenceValue(featuresList, featurename){
    let value = '';

    if(featuresList && Array.isArray(featuresList) && featuresList.length >0){
        for(let i=0; i< featuresList.length; i++){
            if(featuresList[i].featureName === featurename)
            {
                value = featuresList[i].showFeature ? featuresList[i].showFeature : '';
            }
        }
    }

    return value;
}

export function arrayFilterByValue(array, string, searchkeylist) 
{
    let filteredArr = [];
    
    if(array && Array.isArray(array) && array.length > 0){
        filteredArr = array.filter(o => { 
            return Object.keys(o).some(k => {
                    if(searchkeylist && searchkeylist.includes(k)){
                        if(o[k] !== null && o[k] !== undefined && (typeof o[k] === 'string' || typeof o[k] === 'number')) 
                            return o[k].toString().toLowerCase().includes(string.toLowerCase()); 
                    }
            }); 
        });
    }
    
    
    return filteredArr;
}


export function formatInCurrency(val){
    if(val){
       return isNaN(val) ? "$0" : `${parseFloat(val).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`
    }else{
        return "-";
    }
}


export function toUTF8Array(str) {
    var utf8 = [];
    for (var i=0; i < str.length; i++) {
        var charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
            utf8.push(0xc0 | (charcode >> 6), 
                      0x80 | (charcode & 0x3f));
        }
        else if (charcode < 0xd800 || charcode >= 0xe000) {
            utf8.push(0xe0 | (charcode >> 12), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
        // surrogate pair
        else {
            i++;
            // UTF-16 encodes 0x10000-0x10FFFF by
            // subtracting 0x10000 and splitting the
            // 20 bits of 0x0-0xFFFFF into two halves
            charcode = 0x10000 + (((charcode & 0x3ff)<<10)
                      | (str.charCodeAt(i) & 0x3ff));
            utf8.push(0xf0 | (charcode >>18), 
                      0x80 | ((charcode>>12) & 0x3f), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
    }
    return utf8;
}


export const addIndexToList = (arr) => {
    if(arr && Array.isArray(arr) && arr.length > 0){
        for(let i=0; i< arr.length; i++){
            arr[i]['uniqueIdx'] = i; 
        }
    }
    return arr;
}

export const addIncludeIndicatorToList = (arr) => {
    if(arr && Array.isArray(arr) && arr.length > 0){
        for(let i=0; i< arr.length; i++){
            arr[i]['uniqueIdx'] = i;
            arr[i]['indInclude'] = 'Y' 
        }
    }
    return arr;
}
export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        if (amount) {
            amount = parseFloat(amount);
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimalCount });
        }
    }
    catch (e) {
        console.log(e)
    }
}

export function roundUnitValue(cell) {
    if (!cell) return '-';
    var result = (roundvalue(cell, 2) - Math.floor(cell, 2)) !== 0;
    let value;
    if (result) {
        value = roundvalue(cell, 2);
    }
    else {
        value = Math.floor(cell);
    }
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");//this regex is used for thousand seprator 
}

export function roundvalue(value, decimals) {
    if (!value) return '-';
    var floatValue = parseFloat(value);
    return floatValue.toFixed(decimals);

}
