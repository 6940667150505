import axios from 'axios';
import {
    getApproversListUrl, apiBaseUrl, deleteApproverUrl, addApproversUrl, editApproverUrl, graphApiUrl
} from '../serviceConstant';
import { Get, Post, GetForGraph } from '../servicecalls';
import { MsgForFailedData } from '../../common/constants';


export var getApproversListServiceCancellationToken = null;
export const getApproversListService= () => {

    if (getApproversListServiceCancellationToken !== null && getApproversListServiceCancellationToken !== undefined) {
        getApproversListServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getApproversListServiceCancellationToken = cancelToken.source();

    let getApproversListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getApproversListUrl}`;

        Get(url, getApproversListServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching approvers list.", err);
                reject(err);
            }
        })
    });


    return getApproversListPromise;
}

export var addApproverServiceCancellationToken = null;
export const addApproverService = (reqPayload) => {
    if (addApproverServiceCancellationToken !== null && addApproverServiceCancellationToken !== undefined) {
        addApproverServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    addApproverServiceCancellationToken = cancelToken.source();

    let addApproverServicePromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${addApproversUrl}`;
        
        Post(url, reqPayload, addApproverServiceCancellationToken).then(
            catResponse => {
                if (catResponse.data) {
                    resolve(catResponse.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While adding approver.", err);
                reject(err);
            }
        })
    });


    return addApproverServicePromise;
}
export var deleteApproverServiceCancellationToken = null;

export const deleteApproverService = (approverid) => {
    if (deleteApproverServiceCancellationToken !== null && deleteApproverServiceCancellationToken !== undefined) {
        deleteApproverServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    deleteApproverServiceCancellationToken = cancelToken.source();

    let  deleteApproverPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${deleteApproverUrl}?approverid=${approverid}`;

        Get(url, deleteApproverServiceCancellationToken).then(
            response => {
                if (response) {
                    resolve(response);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While deleting approver. ", err);
                reject(err);
            }
        })
    });


    return  deleteApproverPromise;
}

export var editApproverServiceCancellationToken = null;

export const editApproverService = (reqPayload) => {
    if (editApproverServiceCancellationToken !== null && editApproverServiceCancellationToken !== undefined) {
        editApproverServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    editApproverServiceCancellationToken = cancelToken.source();

    let editApproverPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${editApproverUrl}`;

        Post(url, reqPayload, editApproverServiceCancellationToken).then(
            catResponse => {
                if (catResponse.data) {
                    resolve(catResponse.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While adding approver.", err);
                reject(err);
            }
        })
    });


    return editApproverPromise;
}
export var getUserListServiceCancellationToken = null;

export const getUserListService = (token) => {
    if (getUserListServiceCancellationToken !== null && getUserListServiceCancellationToken !== undefined) {
        getUserListServiceCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getUserListServiceCancellationToken = cancelToken.source();

    let getUserListPromise = new Promise(function (resolve, reject) {
        let url = `${graphApiUrl}/users`;

        GetForGraph(url, token, getUserListServiceCancellationToken).then(
            catResponse => {
                if (catResponse.data) {
                    resolve(catResponse.data);
                } else {
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While fetching user list.", err);
                reject(err);
            }
        })
    });


    return getUserListPromise;
}
