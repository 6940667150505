import React from 'react';

export function getVendorsGridColumn(editVendor, add){
    const column = [
        {
            dataField: 'rcmPayeeId',
            text: 'Payee Id',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'name',
            text: 'Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
       
        
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            headerFormatter: (cell, row) => {
                return (<div align="right" className="RCM_icon_hand  RCM_manage_grp_new_group" onClick={() => add()} >+ Add New</div>)
            },
            formatter: (cell, row) => {
                    return(<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => editVendor(row)}>EDIT</button>
                        </div>);
            }
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

