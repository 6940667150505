import axios from 'axios';
import { MsgForFailedData } from '../../common/constants';
import { getwiresListsUrl, apiBaseUrl } from '../serviceConstant';
import { Get } from '../servicecalls';

export var getWiresListCancellationToken = null;

export const getWiresList = (fromDate, toDate) => {
    
    if (getWiresListCancellationToken !== null && getWiresListCancellationToken !== undefined) {
        getWiresListCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getWiresListCancellationToken = cancelToken.source();

    let getUsersListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getwiresListsUrl}?fromDate=${fromDate}&toDate=${toDate}`;
        Get(url, getWiresListCancellationToken).then(
            wiresListRes => {
                //console.log(wiresListRes);
                if(wiresListRes.data ){
                    resolve(wiresListRes.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching wireslist data", err);
                reject(err);
            }
        })
    });


    return getUsersListPromise;


}