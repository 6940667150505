import React from 'react';
import { MsgForNoData } from '../../common/constants';
import { Form, FormGroup, Label } from "reactstrap";
import 'antd/dist/antd.css';
import { Upload, Icon, message } from 'antd';
import { uploadInvoices } from '../../services/billpayservice/billpayservices';
import uuid from 'uuid';
const { Dragger } = Upload;


export function UploadInvoiceView(props) 
{
    const uploadProps = {
        name: 'file',
        accept:'.pdf',
        multiple: true,
        customRequest: ({ onSuccess, onError, onProgress, file }) => onFileUpload({ onSuccess, onError, onProgress, file }),
        onChange: (info) => onFileUploadStatusChange(info),
        listType: 'text'
      };
     

    const onFileUpload = ({ onSuccess, onError, onProgress, file }) => {

          uploadInvoices(file, props.vendorId, props.userEmail, (progressEvent) => onUploadProgress(progressEvent, file, onProgress)).then(uploadRes => {
            console.log('upload response', uploadRes);
            onSuccess(null, file);
          })
          .catch(err => {
            console.log('file upload error', err);
            onError();
          })
      }
    
    const onUploadProgress = (progressEvent, file, onProgress) => {
        onProgress({ percent: Math.round(progressEvent.loaded / progressEvent.total * 100).toFixed(2) }, file)
      }

    const onFileUploadStatusChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
          //do something 
        }
        if (status === 'done') {
          //message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          //message.error(`${info.file.name} file upload failed.`);
        }
      }
      

    let uploadInvoiceView = (
        <div>
            <div className="RCM_Toast">
                <div className="row">
                    <div className="col-sm-12 RCM_tile_heading RCM_payments_toast_heading">
                         UPLOAD INVOICES
                    </div>
                </div>
                {props && props.vendorId ?
                    <div>
                        <div className="row RCM_billpay_back_btn_container">

                            <button type="button" className="RCM_billpay_btn" onClick={() => props.backFromUploadInvoice()}>BACK</button>
                        </div>
                        <div className="row">
                            <div className="RCM_NavTab_container RCM_NavTab_container_details_no_padding RCM_asset_tab_container RCM_asset_form_container" id="RCM_AssetAccounts_form">
                              
                                <div>

                                    <Form>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="type" className="RCM_uploadInvoice_label">Client Name:</Label>
                                                    <Label for="type">{props.selectedClient && props.selectedClient.name ? props.selectedClient.name : ''}</Label>
                                                </FormGroup>
                                            </div>
                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                {/* <Label for="type" className="RCM_dragAndDrop_text">Drag and drop scanned/saved invoices here to link to {props.state.activeClient.clientName}</Label> */}
                                            
                                                <Dragger {...uploadProps} className="RCM_File_Drop">
                                                    <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                                                    <p className="ant-upload-text">Click or drag file(s) to this area to upload invoices for {props.selectedClient && props.selectedClient.name ? props.selectedClient.name : ''} </p>
                                                </Dragger>

                                            </div>

                                        </div>
                                        {/* <div className="row">
                                            <div className="col-sm-6 col-xs-6 col-md-4 col-lg-4">
                                                <FormGroup controlId="formGridTotal Value">
                                                    <Label for="type" className="RCM_uploadInvoice_label">Upload Status:</Label>
                                                    <Label for="type">{props.state.uploadStatus}</Label>
                                                </FormGroup>
                                            </div>

                                        </div> */}
                                        {/* <div className="row ">
                                            <div className="col-sm-6 col-xs-6 col-md-6 col-lg-6 RCM_Billpay_upload_btn_container">
                                                <button type="button" class="btn btn-primary RCM_button_primary RCM_payments_btn" onClick={() => props.upload()}>UPLOAD</button>
                                            </div>

                                        </div> */}
                                    </Form>
                                </div>
                            </div>                           
                        </div>
                    </div>
                    : <div key="ASDropdown22">{MsgForNoData}</div>
                }

            </div>
        </div>
    )
    return uploadInvoiceView
}