import {UPDATE_DISCRETIONARY_DISTRIBUTION_MODE } from "../actions/actionTypes";

const initialState = {
    mode: null,
    selectedDistribution: null,
}

export function DiscretionaryDistributionReducer(state=initialState, action){

    switch(action.type)
    {

        case UPDATE_DISCRETIONARY_DISTRIBUTION_MODE:
        {
            return {
                ...state, 
                selectedDistribution : action.payload,
                mode: action.mode
            }
        }

        default:
        return state;
    }
}