import React from "react";
import { getPreferenceValue } from "./common/utilities";
import { Route } from "react-router";
import SetupBills from "./components/setupbills/setupbills";
import Vendors from "./components/vendors/vendors";
import UploadInvoice from "./components/uploadinvoice/uploadInvoice";
import Admininstrator from "./components/admin/admininstrator";
import DiscrtionaryDistribution from "./components/discretionarydistrequest/discrtionarydistribution";
import NewDiscretionaryDistributionReqForm from "./components/discretionarydistrequest/newdiscretiondistreqform";
import DiscretionaryDistributionDocuments from "./components/discretionarydistrequest/documents/discretionarydistributiondocuments";
import UploadPrivateEquity from "./components/uploadprivateequity/uploadPrivateEquity";
import UploadHedgeFund from "./components/uploadhedgefund/uploadHedgeFund";
import DashBoardContainer from "./components/dashboard/DashBoardContainer";


export const getApplicationRoutes = (featuredata) => {
  let routes = [];

  if (getPreferenceValue(featuredata, "DASHBOARD") === "Y") {
    //Adding the routes for dashboard application or billpay application
    routes.push(
      <Route key="dashboard" exact={false} path="/dashboard" component={DashBoardContainer} />,
      <Route key="setupbill" exact path="/setupbills" component={SetupBills} />,
      <Route key="vendors" exact path="/vendors/:id" component={Vendors} />,
      <Route
        key="uploadinvoices"
        exact
        path="/uploadInvoice/:id"
        component={UploadInvoice}
      />
    );
  }

  if (getPreferenceValue(featuredata, "ADMIN") === "Y") {
    routes.push(
      <Route key="admin" exact={false} path="/admin" component={Admininstrator} />
    );
  }

  if (getPreferenceValue(featuredata, "DISCRETIONARY_DISTRIBUTION") === "Y") {
    routes.push(
      <Route
        key="discretionarydistribution"
        exact
        path="/discretionarydistribution"
        component={DiscrtionaryDistribution}
      />,
      <Route
        key="discretionaryrequest"
        exact
        path="/discretionaryrequest"
        component={NewDiscretionaryDistributionReqForm}
      />,
      <Route
        key="discretionarydistributiondocuments"
        exact
        path="/discretionarydistribution/documents"
        component={DiscretionaryDistributionDocuments}
      />
    );
  }
  routes.push(<Route key="privateequity" exact path="/ai" component={UploadPrivateEquity} />);
  routes.push(<Route key="hedgefund" exact path="/hedgefund" component={UploadHedgeFund} />);
  return routes;
};
