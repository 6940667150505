import React, { Component } from 'react'
import { getUsersList } from '../../../services/adminServices/clientVisibilityService';
import { MsgForNoData } from '../../../common/constants';
import {getUsersListColumn} from  './usersListColumns';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import { UpdateClientVisibilitySelectedUser } from '../../../actions/adminClientVisiblityAction';
import gsap from 'gsap/gsap-core';
import { arrayFilterByValue } from '../../../common/utilities';
import { message } from 'antd';

class UsersList extends Component {

    constructor(props){
        super(props);
        this.state = {
            usersList: [],
            filtereduserList: [],
            selectedUser: null
        }

        this.handleOnAssignClient = this.handleOnAssignClient.bind(this);
    }

    componentDidMount(){
        this.fetchUsersList();
    }

    componentWillUnmount(){
        this.props.UpdateClientVisibilitySelectedUser(null);
    }

    componentDidUpdate(prevProps){
        if(this.props.refresh !== prevProps.refresh){
            this.fetchUsersList();
        }
    }

    fetchUsersList(){
        gsap.to('#admin_clientvisibility_userlist', {duration: 0, opacity: 0, onComplete: () => {
            getUsersList().then(res => {
                this.setState({usersList: res, filtereduserList: res}, () => {
                    gsap.to('#admin_clientvisibility_userlist', {duration: 1, opacity: 1});
                })
            })
            .catch(err => {
                console.error("Error Occured ", err);
                this.setState({usersList: [], filtereduserList: []}, () => {
                    gsap.to('#admin_clientvisibility_userlist', {duration: 0, opacity: 1});
                    message.error('Unable to pull users list, you try again by using refresh option');
                });
                
            })
        }});
        
    }

    handleOnAssignClient(row){
        if(row){
            this.setState({selectedUser: row}, () => {
                this.props.UpdateClientVisibilitySelectedUser(row);
            })
        }
    }

    rowClassForSelectedUser = (row) => {
        let cssClass = null;
        if (row && row.rcmUserId && this.state.selectedUser && this.state.selectedUser.rcmUserId === row.rcmUserId) {
            cssClass = "RCM_Selected_User_Row";
        }

        return cssClass;
    }

    handleOnSearchUserChange(event){
        if(event.target.value === ''){
            this.setState({filtereduserList:  this.state.usersList});
        }else{
            let filterArr =   arrayFilterByValue(this.state.usersList, event.target.value, ['rcmUserId', 'firstname', 'lastname']);
            this.setState({filtereduserList: filterArr});
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6" align="right">
                        <input type="text" placeholder="Search User" className="RCM_form_control" onChange={(e) => this.handleOnSearchUserChange(e)} />
                    </div>
                </div>

                <div className="mt-1" id="admin_clientvisibility_userlist">
                    <BootstrapTable
                            keyField='rcmUserId'
                            data={this.state.filtereduserList}
                            columns={getUsersListColumn(this.handleOnAssignClient)}
                            headerClasses="row-bg"
                            bordered={false}
                            classes="RCM_two_level_table1"
                            rowClasses={(row) => this.rowClassForSelectedUser(row)}
                            noDataIndication={MsgForNoData}
                        />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        refresh: store.app.refresh,
        env: store.app.env
    };
}

const mapDispatchToProps = {
    UpdateClientVisibilitySelectedUser
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersList));
