import React from 'react';
import { Divider, Popconfirm } from 'antd';


export function getDistributionColumn(handleEditClick, handleViewClick, openDocument, handleViewDocumentsClick, handleDeleteClick, showAllTxn) {
    const column = [

        {
            dataField: 'requestDate',
            text: 'Request Date',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => {
                try {
                    if (order === 'asc') {
                        return new Date(b) - new Date(a);
                    }
                    return new Date(a) - new Date(b); // desc
                } catch (err) {
                    console.error(err);
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b;
                }
            }
        },
        {
            dataField: 'entityNumber',
            text: 'Entity Number',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <div>
                        {
                            cell
                        }
                    </div>
                )
            },
        },
        {
            dataField: 'trustTitle',
            text: 'Trust Title',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'distributionAmount',
            text: 'Distribution Amount',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <div align="left">
                        {cell}
                    </div>
                )
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'approvals',
            text: 'Approvers',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <>
                        {

                            (cell && Array.isArray(cell) && cell.length > 0) && (
                                cell.map((approval, index) => {
                                    return (
                                        <span key={index}
                                            className={approval.approvalStatus !== 'Approved' ? 'RCM_Not_Approved_text' : ''}
                                            title={`Status: ${approval.approvalStatus} ${approval.approvalDateTime && `, Approved at: ${approval.approvalDateTime}`}`}>
                                            {approval.approver && approval.approver.fullName ? approval.approver.fullName : ''}
                                            {approval.approvalStatus === 'Approved' && '✓'}
                                            {cell.length - 1 !== index && <Divider type='vertical' />}
                                        </span>
                                    )
                                })
                            )
                        }
                    </>
                )
            }
        },
        ...((showAllTxn && showAllTxn === 'Y') ? [
            {
                dataField: 'createdBy',
                text: 'Created By',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return (
                        <>
                            {
                                cell ? cell : ''
                            }
                        </>
                    )
                }
            }
        ] : []),
        {
            dataField: 'df1',
            text: 'Documents',
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cell, row) => {
                return (
                    <div align="right" style={{ display: 'flex' }}>
                        {
                            (row.isEsignDocExist === 'Y') && (
                                <div className="RCM_Table_Pdf_icon" onClick={() => openDocument(row.distributionID, 'SIGN')} title="Sent Document"></div>
                            )
                        }
                        {
                            (row.isEsignedDocExist === 'Y') && (
                                <div className="RCM_Table_Pdf_icon" onClick={() => openDocument(row.distributionID, 'SIGNED')} title="Signed Document"></div>
                            )
                        }
                    </div>
                )
            },
            events: {
                // onClick: this.openConfirmationPopup
            }
        },
        {
            dataField: 'df2',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cell, row) => {
                return (
                    <div align="right" style={{ display: 'flex' }}>
                        {
                            (row.status && row.status === 'Draft') ?
                                (
                                    <>
                                        <button type="button" className="RCM_billpay_btn" onClick={() => handleEditClick(row)}>EDIT</button>
                                    </>
                                )
                                :
                                (<button type="button" className="RCM_billpay_btn" onClick={() => handleViewClick(row)} >VIEW</button>)
                        }

                        {
                            (
                                <>
                                    <button type="button" className="RCM_billpay_btn" onClick={() => handleViewDocumentsClick(row)} >Documents</button>
                                    <Popconfirm placement="left" title="Delete Distribution ?" onConfirm={() => handleDeleteClick(row)} okText="Yes" cancelText="No">
                                        <button type="button" className="RCM_billpay_btn" >DELETE</button>
                                    </Popconfirm>
                                </>
                            )
                        }
                    </div>
                )
            },
            events: {
                // onClick: this.openConfirmationPopup
            }
        }
    ]

    return column;
}


export function getCompletedDistributionColumn(openDocument, showAllTxn) {
    const column = [

        {
            dataField: 'requestDate',
            text: 'Request Date',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            sortFunc: (a, b, order) => {
                try{
                    if (order === 'asc') {
                        return new Date(b) - new Date(a);
                    }
                    return new Date(a) - new Date(b); // desc
               }catch(err){
                     console.error(err);
                    if(order === 'asc'){
                        return b - a;
                    }
                     return a - b;
               }
            }
        },
        {
            dataField: 'entityNumber',
            text: 'Entity Number',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <div>
                        {
                            cell
                        }
                    </div>
                )
            },
        },
        {
            dataField: 'trustTitle',
            text: 'Trust Title',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'distributionAmount',
            text: 'Distribution Amount',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <div align="left">
                        {cell}
                    </div>
                )
            }
        },
        {
            dataField: 'trustees',
            text: 'Trustee Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'status',
            text: 'Status',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
        },
        {
            dataField: 'approvals',
            text: 'Approvers',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return (
                    <>
                        {

                            (cell && Array.isArray(cell) && cell.length > 0) && (
                                cell.map((approval, index) => {
                                    return (
                                        <span key={index}
                                            className={approval.approvalStatus !== 'Approved' ? 'RCM_Not_Approved_text' : ''}
                                            title={`Status: ${approval.approvalStatus} ${approval.approvalDateTime && `, Approved at: ${approval.approvalDateTime}`}`}>
                                            {approval.approver && approval.approver.fullName ? approval.approver.fullName : ''}
                                            {approval.approvalStatus === 'Approved' && '✓'}
                                            {cell.length - 1 !== index && <Divider type='vertical' />}
                                        </span>
                                    )
                                })
                            )
                        }
                    </>
                )
            }
        },
        ...((showAllTxn && showAllTxn === 'Y') ? [
            {
                dataField: 'createdBy',
                text: 'Created By',
                headerClasses: 'RCM_noWrap',
                headerAlign: 'left',
                sort: true,
                sortCaret: (order, column) => customCaretSort(order, column),
                formatter: (cell, row) => {
                    return (
                        <>
                            {
                                cell ? cell : ''
                            }
                        </>
                    )
                }
            }
        ] : []),
        {
            dataField: '',
            text: 'Documents',
            sort: false,
            isDummyField: true,
            headerAlign: 'left',
            formatter: (cell, row) => {
                return (
                    <div align="right" style={{ display: 'flex' }}>
                        {
                            (row.isEsignDocExist === 'Y') && (
                                <div className="RCM_Table_Pdf_icon" onClick={() => openDocument(row.distributionID, 'SIGN')} title="Sent Document"></div>
                            )
                        }
                        {
                            (row.isEsignedDocExist === 'Y') && (
                                <div className="RCM_Table_Pdf_icon" onClick={() => openDocument(row.distributionID, 'SIGNED')} title="Signed Document"></div>
                            )
                        }
                    </div>
                )
            },
            events: {
                // onClick: this.openConfirmationPopup
            }
        }
    ]

    return column;
}

function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}