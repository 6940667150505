import { store } from "..";

//let baseUrl = process.env.REACT_APP_BASEURL; 
let baseUrl = window.location.origin;
//let apiBaseUrl = process.env.REACT_APP_API_BASEURL;

export const apiBaseUrl = () => {
    return store.getState().app.env.REACT_APP_API_BASEURL;
}


export const getEnvironmentVariableUrl = `${baseUrl}/api/EnvironmentConfig/Environment`;
export const billPayURL = "payment/api/billmgmt";
export const referenceAPIURL = "referenceapi/odata";
export const mmSummaryUrl = "mm/odata/MMSummary";
export var validateABAUrl = "cashmanagement/mm/odata/GetABA('ATOCH,ABANumber')";
export const getVendorsListUrl = "payment/api/billmgmt/getMatrixinfo";
export const invoiceUploadUrl = "payment/api/billmgmt/attachmentUpload";
export const searchVendorListUrl = "payment/api/payment/getClientVendorList";
export const createUpdateVendorWirePaymentInstruction = "payment/api/payment/manageVendorPaymentInstructionForWire";
export const getWireInstructionUrl = 'payment/api/payment/searchVendorList';
export const getEligibleFromAccturl = "payment/api/billmgmt/retrieveFromAcctList";
export const getLookUpValue = "payment/api/payment/getLookUpValues";
export const getUsersListUrl = "payment/api/enterprise/searchRcmUserList";
export const getClientsListUrl = "payment/api/enterprise/searchRcmUserOrg";
export const manageUserOrgsUrl = "payment/api/enterprise/manageRcmUserOrg";
export const submitDiscritionaryDistributionUrl = `${baseUrl}/api/DiscritionaryDistribution/Add`;
export const updateDiscritionaryDistributionUrl = `${baseUrl}/api/DiscritionaryDistribution/Update`;
export const getDiscritionaryDistributionListUrl = `${baseUrl}/api/DiscritionaryDistribution/List`;
export const getCompletedDiscritionaryDistributionListUrl = `${baseUrl}/api/DiscritionaryDistribution/CompletedList`
export const getDiscritionaryDistributionUrl = `${baseUrl}/api/DiscritionaryDistribution/distribution`;
export const getEntityInfomationUrl = 'payment/api/trustcompany/getTrustDiscretionaryDistributionInfo';
export const createDocusignEnvelopeUrl = 'acctopngapi/api/AccountProfile/CreateDocusignEnvelope_DD';
export const createDistributionPdfUrl = 'payment/api/billmgmt/getDiscretionaryById';
export const financialAdvisorsFeatureUrl = `${baseUrl}/api/pilotfeature/list`;
export const updatedistributionStatusUrl =  `${baseUrl}/api/DiscritionaryDistribution/UpdateStatus`;
export const fetchDistributionDocumentsUrl = `${baseUrl}/api/DiscritionaryDistribution/Document`;
export const getwiresListsUrl = "payment/api/enterprise/getBillInfo";
export const uploadDiscretionaryDistributionDocsUrl = `${baseUrl}/api/DiscritionaryDistribution/UploadDocument`;
export const getDiscretionaryDocumentsListUrl = `${baseUrl}/api/DiscritionaryDistribution/DiscretionaryDocumentList`;
export const deleteDiscrtionaryDocumentUrl = `${baseUrl}/api/DiscritionaryDistribution/DeleteDistributionDocument`;
export const deleteDiscrtionaryDistributionUrl = `${baseUrl}/api/DiscritionaryDistribution/Delete`;
export const uploadPrivateEquitiesUrl = `${baseUrl}/api/PrivateEquity/UploadPrivateEquities`;
export const uploadHedgeFundsUrl = `${baseUrl}/api/HedgeFund/UploadHedgeFunds`;
 
export const fetchSubAccountCategoriesUrl = `${baseUrl}/api/Accounting/FetchSubAccountCategories`;
export const getSubAccountListUrl = `payment/api/billmgmt/retrieveOrgSubAccount`;
export const addEditSubAccountUrl = 'payment/api/billmgmt/updateSubAccount';
export const linkSubAccountUrl = "profileapi/odata/aggregatedaccount";
export const unlinkSubAccountUrl = "assetag/odata/ExtAssetAggregationSubmit";
export const getTransactionsSourceDataUrl = `activityapi/odata/activitysummary`;
export const getTransactionsAccountingDataUrl = `trustapi/api/Transactions/GetProcessedTransactions`;
export const getVendorListUrl = `payment/api/d365/getUIVendorPayee?entityId=`;
export const addEditVendorUrl = 'payment/api/d365/updUIVendorPayee';

export const getRuleListUrl = 'trustapi/api/VendorPayeeRule/GetRules';
export const addRuleUrl = 'trustapi/api/VendorPayeeRule/AddRule';
export const updateRuleUrl = 'trustapi/api/VendorPayeeRule/UpdateRule';
export const getInstitutionListUrl = 'trustapi/api/VendorPayeeRule/GetInstitutions?';
export const getAccountSubaccountListUrl = 'trustapi/api/VendorPayeeRule/GetSubAccounts';
export const getMainAccountListUrl = 'trustapi/api/VendorPayeeRule/GetMainAccounts';
export const getTransactionListUrl = 'trustapi/api/VendorPayeeRule/GetTransactions';

export const getApproversListUrl = 'trustapi/api/AuthorizedApprovers/GetApprovers';
export const addApproversUrl = 'trustapi/api/AuthorizedApprovers/InsertApprover';
export const deleteApproverUrl = 'trustapi/api/AuthorizedApprovers/DeleteApprover';
export const editApproverUrl = 'trustapi/api/AuthorizedApprovers/UpdateApprover';

export const getFailedPaymentListUrl = `payment/api/billmgmt/getBillStatus`
export const updateFailedPaymentUrl = `payment/api/billmgmt/updBillStatus`
export const uploadPaymentLOAUrl = 'payment/api/payment/UploadLOADoc';
export const fetchLOADocumentUrl = 'payment/api/payment/GetLOADoc';
export const updatePaymentAccountUrl = 'trustapi/api/PaymentAccount/Update';
export const getPartyIdListUrl = 'trustapi/api/Accounting/GetAccountParty';

export const billDotComSsoLogUrl = `trustapi/api/PaymentAccount/SSOLog`
/* Ninth waves */
export var getAALoginTokenUrl = "assetag/odata/ExtAssetAggregationStartSession";
export var fetchMatrixContexualMenuItemsUrl = `payment/api/payment/getDynamicsFeatures?isAdminUser=true`

/*Graph API */
export var graphApiUrl = 'https://graph.microsoft.com/v1.0';
export var getMSAuthorityUrl = 'https://login.microsoftonline.com/rockco.onmicrosoft.com';

