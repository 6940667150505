import { INSERT_TOKEN, INSERT_ENV_VARIABLES, REFRESH, ADD_FA, INSERT_FA_PREFERENCE, UPDATE_SELECTED_MENU, UPDATE_SSO_WINDOW} from "./actionTypes";




export const ADD_ENV_VARIABLES = (variables) => (dispatch) => {
   return Promise.resolve(
        dispatch({
            type: INSERT_ENV_VARIABLES,
            payload: variables
        })
    )
}

export function ADD_TOKEN(token)
{
    return {
        type: INSERT_TOKEN,
        payload: token
    }
}

export function REFRESH_APP(isRefresh)
{
    return {
        type: REFRESH,
        payload:isRefresh
    }
}

export function ADD_FADetail(email){
    return{
        type: ADD_FA,
        payload: email
    }
}

export function INSERT_FA_FEATURES(featureList){
    return {
        type: INSERT_FA_PREFERENCE,
        payload: featureList
    }
}

export const UPDATE_SELECTED_MENU_VALUE = (value) => (dispatch) => {
    return Promise.resolve(
         dispatch({
             type: UPDATE_SELECTED_MENU,
             payload: value
         })
     )
 }

 export function UPDATE_SSO_WINDOW_INSTANCE(window){
    return {
        type: UPDATE_SSO_WINDOW,
        payload: window
    }
}
