import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaymentsView } from './paymentsView.jsx';

export class Payments extends Component {
    constructor(props) {
        super(props);
        this.retryPayment = this.retryPayment.bind(this);
        this.search = this.search.bind(this);

        this.state = {
            openUploadInvoice: false,
            openVendors: false,
            activeClient: {},
            paymentsList: [],
            originalPaymentsList: [],
            searchText: ''
        };
    }
    componentDidMount = () => {
        this.setState({
            paymentsList: this.props.state.activeClient.payments, originalPaymentsList: this.props.state.activeClient.payments
        });
    }
    search = (evt) => {
        let searchString = evt.target.value;
        var updatedPaymentsList = [...this.state.originalPaymentsList];
        updatedPaymentsList = updatedPaymentsList.filter(function (item) {
            return item.vendorName.toLowerCase().search(
                searchString.toLowerCase()) !== -1;
        });
        this.setState({ paymentsList: updatedPaymentsList, searchText: searchString });
    }
    retryPayment = (rows) => {
        alert("in Retry Payment method");
    }
    render() {
        return (
            <PaymentsView
                state={this.state}
                backFromPayments={this.props.backFromPayments}
                retryPayment={this.retryPayment}
                search={this.search}
            />
        );
    }

}
const mapStateToProps = (store) => {
    return {
    };
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Payments);

