import React from 'react';
import 'antd/dist/antd.css';
import { Result } from 'antd';

export default function Notfound() {

    const backhome = () => {
        window.location.replace(window.location.origin);
    }

    return (
        <div>
            <Result 
                status="404" 
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<input type="button" value='Back Home' className="btn btn-btn RCM_button_primary" onClick={() => backhome()} />}
            >
            </Result>
        </div>
    )
}
