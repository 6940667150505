import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';
import SubAccounts from './subAccounts';
import PostingRules from './postingRules/postingRulesContainer';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import Transactions from './transactions/transactions';
import Notfound from '../../common/notfound';
import Vendor from './vendors/vendor'; 
const { TabPane } = Tabs;

class Accounting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ActiveKey: this.getActiveTab()
        }

        this.onTabChanges = this.onTabChanges.bind(this);
        this.getActiveTab = this.getActiveTab.bind(this);
    }

    getActiveTab = () => {
        const currentRoute = this.props.history.location.pathname;
        let activeKey = "1";
        if (currentRoute === `${this.props.match.url}` || currentRoute === `${this.props.match.url}/subAccount`)
            activeKey = "1";
        else if (currentRoute === `${this.props.match.url}/payees` || currentRoute === `${this.props.match.url}/payees/allclientpayees` || currentRoute === `${this.props.match.url}/specificclientpayees`)
            activeKey = "2";
        else if (currentRoute === `${this.props.match.url}/postingRules`)
            activeKey = "3";
        return activeKey;        
    }

    onTabChanges(activetabKey) {
        switch (activetabKey) {
            case "1": {
                this.props.history.push(this.props.match.url + '/subAccount');
                break;
            }
            case "2": {
                this.props.history.push(this.props.match.url + '/payees');
                break;
            }
            case "3": {
                this.props.history.push(this.props.match.url + '/postingRules');
                break;
            }

            default: {
                break;
            }
        }
        this.setState(prevState => ({
            ActiveKey: activetabKey
        }));

    }

    render() {
        let mode = 'left';
        return (
            <div className="RCM_display_flex">
                <div className=" RCM_subMenu">
                <Tabs defaultActiveKey={this.state.ActiveKey} animated={false} onChange={this.onTabChanges} tabPosition={mode}>
                    <TabPane tab="SUB ACCOUNTS" key="1">

                    </TabPane>
                        <TabPane tab="PAYEES" key="2">

                        </TabPane>
                        <TabPane tab="POSTING RULES" key="3">

                        </TabPane>
                    </Tabs>
                </div>
                <div className="RCM_subMenu_body">
                {
                    <Switch>
                        <Route exact path={`${this.props.match.url}`} render={() => <Redirect to={`${this.props.match.url}/subAccount`} />} />
                            <Route exact path={`${this.props.match.url}/payees`} component={() => (<Vendor ActiveKey={this.state.ActiveKey} history={this.props.history} matchUrl={this.props.match.url}/>)} />
                            <Route exact path={`${this.props.match.url}/subAccount`} component={() => (<SubAccounts ActiveKey={this.state.ActiveKey} history={this.props.history} matchUrl={this.props.match.url} />)} />
                        <Route exact path={`${this.props.match.url}/postingRules`} component={() => (<PostingRules ActiveKey={this.state.ActiveKey} history={this.props.history} />)} />
                            <Route exact path={`${this.props.match.url}/subAccount/transactions`} component={() => (<Transactions ActiveKey={this.state.ActiveKey} history={this.props.history} matchUrl={this.props.match.url}/>)} />
                        <Route path="*" component={Notfound} />
                    </Switch>
                    }
                </div>

            </div>
        )
    }
}

export default withRouter(Accounting);


