import React, { useState, useEffect } from 'react';
import { arrayFilterByValue } from '../../../common/utilities';
import { MsgForNoData } from '../../../common/constants';
import BootstrapTable from 'react-bootstrap-table-next';
import { getApproversListColumn } from './approversListColumns';
import AddApprovers from './addApprovers';
import ConfirmationPopup from './../../accounting/confirmationPopup';
import { BackTop, message } from 'antd';
import { Spinner } from 'reactstrap';
import {
    getApproversListService, getApproversListServiceCancellationToken,
    deleteApproverService, deleteApproverServiceCancellationToken,
    
} from './../../../services/approversService/approversService';

const PaymentApprovers = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [approversList, setApproversList] = useState([]);
    const [filteredApproversList, setFilteredApproversList] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [selectedApprover, setSelectedApprover] = useState({});
    const [mode, setMode] = useState("");

    useEffect(() => {
        setShowOverlay(prevShowOverlay => true);
        getData(); 
        return () => {
            if (getApproversListServiceCancellationToken !== null && getApproversListServiceCancellationToken !== undefined) {
                getApproversListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (deleteApproverServiceCancellationToken !== null && deleteApproverServiceCancellationToken !== undefined) {
                deleteApproverServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
        }
    }, []);
    
    const handleOnSearchChange = (event) => {
        if (event.target.value === '') {
            setFilteredApproversList(approversList);
           
        } else {
            let filterArr = arrayFilterByValue(approversList, event.target.value,['emailid', 'firstname', 'lastname']);
            setFilteredApproversList(filterArr);
        }
    }
   
    const getData = () => {
        //call api and get approvers
        getApproversListService().then(res => {
            if (res && res.status === 200 && res.data && res.data !=null)
            setApproversList(res.data);
            setFilteredApproversList(res.data);
            setShowOverlay(prevShowOverlay => false);
        })
            .catch(err => {
                console.error(err);
                message.error("Unable to get approvers list.");
                setShowOverlay(prevShowOverlay => false);
            })
        
    }
    const openConfirmationPopup = row => {
        setShowConfirmationPopup(pervShowConfirmationPopup => true);
        setSelectedApprover(row);
    }
    const toggleConfirmationModelPopup = () => {
        setShowConfirmationPopup(pervShowConfirmationPopup => !pervShowConfirmationPopup);
    }
    const confirmAction = source => {
        setShowOverlay(prevShowOverlay => true);
        if (selectedApprover && selectedApprover.approverid !== undefined && selectedApprover.rcmUserId !== "") {
            deleteApproverService(selectedApprover.approverid).then(res => {
                if (res) {
                    message.success("Successfully deleted approver.");
                    refresh();
                }
            })
                .catch(err => {
                    console.error(err);
                    message.error("Unable to delete selected approver.");
                });
        }
        setSelectedApprover({});
        setShowConfirmationPopup(pervShowConfirmationPopup => !pervShowConfirmationPopup);
    }
    const addApprover = () => {
        setMode("add");
        setShowAdd(prevShowAdd => true);
        setSelectedApprover({});
    }
    const editApprover = row => {
        setMode("edit");
        setSelectedApprover(row);
        setShowAdd(prevShowAdd => true);
    }
    const refresh = () => {
        getData();
        setSelectedApprover({});
        setMode('');
    }
    return (
        <>
            <BackTop key="Approver01"/>
            {showOverlay
                ? <div align="center" className="mt-4" key="Approver02">Loading Payment Approvers
                  &nbsp;<Spinner animation="grow" size="sm" variant="info" key="Approver03"></Spinner>
                </div>
                :
                <div className="RCM_Toast RCM_subAccount_toast" key="Approver04">
                    <div className="row" key="Approver05">
                        <div className="col-sm-12 col-md-4 col-lg-4" align="right" key="Approver06">
                            <input type="text" placeholder="Search User" className="RCM_form_control" onChange={(e) => handleOnSearchChange(e)} key="Approver07"/>
                        </div>
                    </div>
                    <div className="row mt-1" key="Approver08">
                        <div className="col-sm-12 col-md-6 col-lg-6 " key="Approver09">
                            <BootstrapTable
                                keyField='approverid'
                                data={filteredApproversList}
                                columns={getApproversListColumn(addApprover, openConfirmationPopup, editApprover)}
                                headerClasses="row-bg"
                                bordered={false}
                                classes="RCM_two_level_table1"
                                noDataIndication={MsgForNoData}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6" key="Approver10">
                            {showAdd
                                ? <AddApprovers
                                    refresh={refresh}
                                    setShowAdd={setShowAdd}
                                    message={message}
                                    mode={mode}
                                    setMode={setMode}
                                    selectedApprover={selectedApprover}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            }
            <ConfirmationPopup
                openConfirmationModelPopup={showConfirmationPopup}
                toggleConfirmationModelPopup={toggleConfirmationModelPopup}
                confirmationMessage={"Are you sure you want to delete selected approver: " + selectedApprover.emailid}
                confirmationPopupSource={"deleteApprover"}
                confirmAction={confirmAction}
            />
        </>
    )

};

export default PaymentApprovers;