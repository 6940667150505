import axios from "axios";
import { getUsersListUrl, apiBaseUrl, getClientsListUrl, manageUserOrgsUrl } from "../serviceConstant";
import { Get } from "../servicecalls";
import { MsgForFailedData } from  '../../common/constants'
 

export var getUsersListCancellationToken = null;
export var getClientsListCancellationToken = null;
export var manageRCMUserOrgCancellationToken = null;


export const getUsersList = () => {
    
    if (getUsersListCancellationToken !== null && getUsersListCancellationToken !== undefined) {
        getUsersListCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getUsersListCancellationToken = cancelToken.source();

    let getUsersListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getUsersListUrl}`
        Get(url, getUsersListCancellationToken).then(
            userListRes => {
                if(userListRes.data && Array.isArray(userListRes.data)){
                    resolve(userListRes.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching userslist data", err);
                reject(err);
            }
        })
    });


    return getUsersListPromise;


}

export const getClientsList = (useremail) => {
    
    if (getClientsListCancellationToken !== null && getClientsListCancellationToken !== undefined) {
        getClientsListCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getClientsListCancellationToken = cancelToken.source();

    let getClientsListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${getClientsListUrl}?rcmUserId=${useremail}`;
        Get(url, getClientsListCancellationToken).then(
            clientsListRes => {
                if(clientsListRes.data && Array.isArray(clientsListRes.data)){
                    resolve(clientsListRes.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching clientslist data", err);
                reject(err);
            }
        })
    });


    return getClientsListPromise;


}

export const manageRCMUserOrg = (mode, orgId, useremail) => {
    
    if (manageRCMUserOrgCancellationToken !== null && manageRCMUserOrgCancellationToken !== undefined) {
        manageRCMUserOrgCancellationToken.cancel("Cancel existing call to prevent memory leak.");
    }
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    manageRCMUserOrgCancellationToken = cancelToken.source();

    let getClientsListPromise = new Promise(function (resolve, reject) {
        let url = `${apiBaseUrl()}/${manageUserOrgsUrl}?operationMode=${mode}&orgId=${orgId}&RcmUserId=${useremail}`;
        Get(url, manageRCMUserOrgCancellationToken).then(
            manageUserOrgRes => {
                if(manageUserOrgRes.data && manageUserOrgRes.data.response_status === 0){
                    resolve(manageUserOrgRes.data);
                }else{
                    reject(MsgForFailedData);
                }
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While updating user org data", err);
                reject(err);
            }
        })
    });


    return getClientsListPromise;


}