import { validateABAUrl, apiBaseUrl, getEligibleFromAccturl} from "../serviceConstant";
import { errorMsg} from "../../common/constants";
import { Get } from "../servicecalls";
import axios from "axios";


export var validateABACancellationToken = null;
export var getEligibleFromAccountCancellationToken = null;

export const validateABA = (abaNumber, token, contextJson, profileJson) => {

    if (validateABACancellationToken !== null && validateABACancellationToken !== undefined) {
        validateABACancellationToken.cancel("Cancel existing validate ABA request to revent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    validateABACancellationToken = cancelToken.source();



    let validateABAPromise = new Promise(
        function (resolve, reject) {
            let url = apiBaseUrl() +"/" + validateABAUrl.replace(/ABANumber/g, abaNumber);
            Get(url, token, contextJson, profileJson, validateABACancellationToken).then(res => {
                // get the response in case of successful call.
                console.log("Manage external account :: validate ABA", res);
                if (res.data !== null
                    && res.data != undefined
                    && res.data.value !== null
                    && res.data.value !== undefined
                    && Array.isArray(res.data.value)
                    && res.data.value.length > 0) {
                    let abaResponse = res.data.value[0];
                    if (abaResponse.status !== null
                        && abaResponse.status !== undefined
                        && abaResponse.status.Code !== null
                        && abaResponse.status.Code !== undefined
                        && abaResponse.status.Code === "000000") {
                        resolve(res.data.value[0]);
                    } else {
                        reject(errorMsg);
                    }

                } else {
                    console.log("Manage External Account Service Non Zero : Validate ABA :: ");
                    reject(errorMsg);
                }

            })
                .catch((error) => {
                    if (axios.isCancel()) {
                        console.log(error);
                    } else {
                        //Log the error on console incase of failure.
                        console.error("Manage External Account Service : Validate ABA :: ", error);
                        reject(errorMsg);
                    }
                });

        });
    return validateABAPromise;
};

export const getEligibleFromAccount = (orgid) => {

    //Check if cancellation token has value;
    if(getEligibleFromAccountCancellationToken !== null && getEligibleFromAccountCancellationToken !== undefined)
    {
        //Cancel the existing call
        getEligibleFromAccountCancellationToken.cancel('Cancelling the existing eligible pair request to prevent memory leaks.');
    }
    
    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getEligibleFromAccountCancellationToken = cancelToken.source(); 

    let getEligibleFromAccountPromise = new Promise(
        function (resolve, reject) {
            if (orgid) {
                //Call eligible pair service 
                let url = `${apiBaseUrl()}/${getEligibleFromAccturl}?orgid=${orgid}` 
                Get(url, getEligibleFromAccountCancellationToken).then(res => {
                    // get the response in case of successful call. 
                    if(res.data && res.data.length > 0){
                        resolve(res.data);
                    }else{
                        resolve([]);
                    }
                })
                .catch((error) => {
                    if(axios.isCancel(error))
                    {
                        console.log(error);
                    }else{
                    //Log the error on console incase of failure.
                    console.error("Reterive from account error :: ", error);
                    reject(errorMsg);
                    }
                });
            }
            else {
                console.error("Invalid orgid provided");
                reject(errorMsg);
            }

        }
    );

    return getEligibleFromAccountPromise;
}
