import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './NavMenu.css';
import { authProvider } from '../App';
import { REFRESH_APP, UPDATE_SELECTED_MENU_VALUE } from '../actions/appActions';
//import img from './../images/user.svg';
import { getPreferenceValue } from '../common/utilities';
import 'antd/dist/antd.css';
import { Layout, Menu, Tooltip, Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const { Header } = Layout;

class NavMenu extends React.PureComponent {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.RefreshApplication = this.RefreshApplication.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
        this.openCloseAdminDropdown = this.openCloseAdminDropdown.bind(this);
        this.openCloseAdminSubMenuDropdown = this.openCloseAdminSubMenuDropdown.bind(this);
        this.handleSubMenuItemClick = this.handleSubMenuItemClick.bind(this);
        this.state = {
            isOpen: false,
            selectedKey: null,
            adminDropdownOpen: false,
            adminSubMenuDropdownOpen: false,
            dashBoardDropdownOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    openCloseAdminDropdown = (source) => {
        let value = false;
        if (source === "onMouseOver") {
            value = true;
        } else if (source === 'onMouseLeave') {
            value = false;
        } else {
            value = !this.state.adminDropdownOpen;
        }
        this.setState({
            adminDropdownOpen: value
        });
    }
    openCloseAdminSubMenuDropdown = source => {
        let value = false;
        if (source === "onMouseOver") {
            value = true;
        } else if (source === 'onMouseLeave') {
            value = false;
        } else {
            value = !this.state.adminSubMenuDropdownOpen;
            this.openCloseAdminDropdown();
        }
        this.setState({
            adminSubMenuDropdownOpen: value
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultSelectedMenu !== this.props.defaultSelectedMenu) {
            this.setState({ selectedKey: this.props.defaultSelectedMenu });
        }
    }


    logoutUser() {
        //authProvider.clearCache();
        authProvider.logout();
    }

    RefreshApplication() {
        let refresh = !this.props.refresh;
        this.props.REFRESH_APP(refresh);

    }

    showUserPlanner() {
        document.dispatchEvent(new CustomEvent('showUserTaskPlanner', { bubbles: true }));
    }

    handleMenuItemClick(event) {
        if (event.key && event.key !== '0') {

            this.props.UPDATE_SELECTED_MENU_VALUE(event.key).then(res => {
                switch (event.key) {
                    case "1": {
                        this.props.history.push('/');
                        break;
                    }

                    case "2": {
                        this.props.history.push('/admin');
                        break;
                    }

                    case "3": {
                        this.props.history.push('/discretionarydistribution');
                        break;
                    }

                    default: {
                        break;
                    }
                }

            })
        }
    }
    handleSubMenuItemClick = (event, source) => {
        event.stopPropagation();
        if (source && source !== '0') {

            this.props.UPDATE_SELECTED_MENU_VALUE('2').then(res => {
                switch (source) {

                    case "1": {
                        this.props.history.push('/admin/clientVisibility');
                        break;
                    }
                    case "2": {
                        this.props.history.push('/admin/payments');
                        break;
                    }
                    case "3": {
                        this.props.history.push('/admin/accounting');
                        break;
                    }
                    case "4": {
                        this.props.history.push('/admin/accounting/subAccount');
                        break;
                    }
                    case "5": {
                        this.props.history.push('/admin/accounting/payees');
                        break;
                    }
                    case "6": {
                        this.props.history.push('/admin/accounting/postingRules');
                        break;
                    }
                    case "7": {
                        this.props.history.push('/admin/paymentApprovers');
                        break;
                    }
                    case "8": {
                        this.props.history.push('/admin/clientSettings');
                        break;
                    }

                    case "9": {
                        this.props.history.push('/admin/failedpayments');
                        break;
                    }
                    default: {
                        break;
                    }    
                }
            })
        }
    }

    handleDashBoardMenuItemClick(event, source) {
        event.stopPropagation();
        this.props.history.push(source === "dashboard" ?  "/dashboard" : `/dashboard/${source}`)
    }

    render() {

        return (
            <>
                <Header style={{ position: 'fixed', zIndex: 10, width: '100%', minHeight: '5rem' }}>
                    <div id="RCMPaymentHub_Logo" className="RCMPaymentHub_Logo"><span>RCM Payment Hub</span></div>
                    <Menu theme="dark" mode="horizontal" 
                        selectedKeys={[this.state.selectedKey]} 
                        style={{ lineHeight: '64px', display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Menu.Item key="0" onClick={this.RefreshApplication}>
                            <span style={{ fontSize: '2rem', fontWeight: 500 }} title='Refresh'>&#8635;</span>
                        </Menu.Item>
                        {(getPreferenceValue(this.props.faFeatures, 'DASHBOARD') === 'Y') && (
                        <Menu.Item key="1" onClick={this.handleMenuItemClick} 
                        onMouseOver={() => this.setState(prevState => { return {...prevState, dashBoardDropdownOpen: true}})}
                        onMouseLeave={() => this.setState(prevState => { return {...prevState, dashBoardDropdownOpen: false}})}>
                            <Dropdown 
                                isOpen={this.state.dashBoardDropdownOpen} 
                                toggle={() => this.setState(prevState => { return {...prevState, dashBoardDropdownOpen: !prevState.dashBoardDropdownOpen}})}>
                                <DropdownToggle >
                                    DashBoard
                                </DropdownToggle>
                                <DropdownMenu className="RCM_navmenu">
                                    <DropdownItem id="dashboard" onClick={(evt) => this.handleDashBoardMenuItemClick(evt, 'dashboard')}>Dashboard</DropdownItem>
                                    <DropdownItem id="payments" onClick={(evt) => this.handleDashBoardMenuItemClick(evt, 'payments')}>Payments</DropdownItem>
                                    <DropdownItem id="failedPayments" onClick={(evt) => this.handleDashBoardMenuItemClick(evt, 'failedPayments')}>Payments For Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Menu.Item>)}
                        {(getPreferenceValue(this.props.faFeatures, 'ADMIN') === 'Y') && <Menu.Item key="2" onClick={this.handleMenuItemClick}
                            onMouseOver={() => this.openCloseAdminDropdown("onMouseOver")}
                            onMouseLeave={() => this.openCloseAdminDropdown("onMouseLeave")}>
                            <Dropdown isOpen={this.state.adminDropdownOpen} toggle={() => this.openCloseAdminDropdown()}>
                                <DropdownToggle >
                                    Admin
                                </DropdownToggle>
                                <DropdownMenu className="RCM_navmenu">
                                    <DropdownItem id="1" onClick={(evt) => this.handleSubMenuItemClick(evt, '1')}>Client Visibility</DropdownItem>
                                    <DropdownItem id="2" onClick={(evt) => this.handleSubMenuItemClick(evt, '2')}>Payments</DropdownItem>
                                    <DropdownItem id="3" onClick={(evt) => this.handleSubMenuItemClick(evt, '3')}
                                        onMouseOver={() => this.openCloseAdminSubMenuDropdown("onMouseOver")}
                                        onMouseLeave={() => this.openCloseAdminSubMenuDropdown("onMouseLeave")}>
                                        <Dropdown isOpen={this.state.adminSubMenuDropdownOpen} toggle={() => this.openCloseAdminSubMenuDropdown()} direction='right'>
                                            <DropdownToggle className="RCM_subMenu">
                                                Accounting
                                            </DropdownToggle>
                                            <DropdownMenu className="RCM_navmenu" >
                                                <DropdownItem id="4" onClick={(evt) => this.handleSubMenuItemClick(evt, '4')}>Sub Accounts</DropdownItem>
                                                <DropdownItem id="5" onClick={(evt) => this.handleSubMenuItemClick(evt, '5')}>Payees</DropdownItem>
                                                <DropdownItem id="6" onClick={(evt) => this.handleSubMenuItemClick(evt, '6')}>Posting Rules</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </DropdownItem>
                                    <DropdownItem id="9" onClick={(evt) => this.handleSubMenuItemClick(evt, '9')}>Payments For Action</DropdownItem>
                                    <DropdownItem id="7" onClick={(evt) => this.handleSubMenuItemClick(evt, '7')}>Payment Approvers</DropdownItem>
                                    <DropdownItem id="8" onClick={(evt) => this.handleSubMenuItemClick(evt, '8')}>Client Settings</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Menu.Item>}
                        {(getPreferenceValue(this.props.faFeatures, 'DISCRETIONARY_DISTRIBUTION') === 'Y') && <Menu.Item key="3" onClick={this.handleMenuItemClick}>Discretionary Distribution</Menu.Item>}

                        <Menu.Item key="4">

                            <div className="RCM_Table_link RCM_User_Icon" onClick={() => this.showUserPlanner()}>
                                <Avatar icon={<UserOutlined style={{ fontSize: '1.8rem' }} />} /> {this.props.user.userName}
                            </div>
                        </Menu.Item>
                        <Menu.Item key="5"><Tooltip title="Sign Out"><LogoutOutlined onClick={this.logoutUser} className="RCM_Table_link" /></Tooltip></Menu.Item>
                    </Menu>
                </Header>
            </>
        );
    }
}

const mapStateToProps = store => {
    return {
        user: store.app.user,
        refresh: store.app.refresh,
        faFeatures: store.app.faFeatures,
        defaultSelectedMenu: store.app.defaultSelectedMenu
    };
};

const mapDispatchToProps = {
    REFRESH_APP,
    UPDATE_SELECTED_MENU_VALUE
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavMenu));
