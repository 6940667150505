import { UPDATE_DISCRETIONARY_DISTRIBUTION_MODE } from "./actionTypes";

export const UpdateDiscretionaryDistributionMode = (row, mode) => (dispatch) => {
    return Promise.resolve(
         dispatch({
             type: UPDATE_DISCRETIONARY_DISTRIBUTION_MODE,
             payload: row,
             mode: mode
         })
     )
 }