import React from 'react';
import { Label } from 'reactstrap';

export default function DistributionApprover(props) {
    return (
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
                <Label className="RCM_Discr_form_label_heading">
                    Approver:
                </Label>
                <div style={{ display: 'flex' }}>
                    {
                        props.isTrustCompanyTrustee === 'N' &&
                        <label className="checkbox_container RCM_discretion_distribution_form_checkbox">
                            <input type="checkbox" 
                                onChange={props.approverIncludeChange}
                                disabled={props && props.approver && props.approver.emailAddress ? false :  true}
                                checked={props && props.approver && props.approver.indInclude === 'Y' ? true : false} 
                            />
                            <span className="checkmark"></span>
                        </label>
                    }
                    <input type="text" 
                        className="RCM_form_control" 
                        value={props && props.approver && props.approver.fullName ? props.approver.fullName : '' }    
                        autoComplete="off" readOnly />
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
                <Label className="RCM_Discr_form_label_heading">
                    Email:
                 </Label>
                <input type="text" 
                    className="RCM_form_control" 
                    value={props && props.approver && props.approver.emailAddress ? props.approver.emailAddress : '' } 
                    autoComplete="off" readOnly />
            </div>
        </div>
    )
}
