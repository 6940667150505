import React from 'react'
import UploadPrivateEquityView from './uploadPrivateEquityView'
import { useSelector } from 'react-redux'
import { getNestedObject } from '../../common/common';

function UploadPrivateEquity() {

    const email = useSelector(state => getNestedObject(state, ['app', 'user', 'userName']));

    return (
        <div>
            <UploadPrivateEquityView email={email} />
        </div>
    )
}

export default UploadPrivateEquity
