import { ADD_WIRE_INSTRUCTION, UPDATE_CLIENT_VENDORLIST } from "../actions/actionTypes";

const initialState = {
    wireInstruction: null,
    mode: null,
    clientVendorsList: []
}

export function VendorsReducer(state=initialState, action){

    switch(action.type)
    {

        case ADD_WIRE_INSTRUCTION:
        {
            return {
                ...state, 
                wireInstruction : action.payload,
                mode: action.mode
            }
        }

        case UPDATE_CLIENT_VENDORLIST:
            {
                return {
                    ...state,
                    clientVendorsList: action.payload
                }
            }

        default:
        return state;
    }
}