import React from 'react';
import { Label } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form, FormGroup } from "reactstrap";
import { MsgForNoData } from '../../common/constants';
import { getVendorColumn } from './vendorsColumn';
import GenericMessageModal from '../../common/genericMsgModal';

export function VendorsView(
    props
) {
    let vendorsView = (
        <div>

            <div className="RCM_Toast">
                <div className="row">
                    <div className="col-sm-12 RCM_tile_heading RCM_payments_toast_heading">
                         VENDORS
                        </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                    
                    </div>

                    <div className="col-sm-4 RCM_billpay_back_btn_container" align="right">
                        <button type="button" class="RCM_billpay_btn RCM_billpay_btnPadding" onClick={props.addVendor}>ADD VENDOR</button>
                        <button type="button" class="RCM_billpay_btn " onClick={() => props.backFromVendors()}>BACK</button>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10 mt-2">
                        <Label for="type" className="RCM_uploadInvoice_label">Client Name:</Label>
                        <Label for="type">{props.selectedClient && props.selectedClient.name ? props.selectedClient.name : ''}</Label>
                    </div>
                    <div className="col-sm-2 mt-2">
                    <Form>
                        <FormGroup controlId="formGridAccount">

                            <input
                                type="text"
                                autoComplete="off"
                                placeholder="Search"
                                name="searchText"
                                className="RCM_form_control"
                                value={props.state.searchText}
                                onChange={props.search}
                            />
                        </FormGroup>
                    </Form>
                  </div>
                </div>
                {props && props.state ?
                    <div id="RCM_Vendors_Table" className="RCM_ManageAsset_Table">
                        <BootstrapTable
                            keyField='id'
                            data={props.state.vendorList}
                            columns={getVendorColumn(props.openWireInstruction, props.viewInvoice, props.openBilldotcomVendor)}
                            headerClasses="row-bg"
                            bordered={false}
                            classes="RCM_two_level_table1"
                            rowClasses="RCM_tow_level_table1_row_animation RCM_icon_hand"
                            noDataIndication={MsgForNoData}
                            rowEvents= { 
                                {
                                    onClick: (e, row, rowIndex) => {
                                        if(e.target.type !== 'button'){
                                           props.openBilldotcomVendor(row)
                                        }
                                    }
                                }
                             }
                        />
                    </div>
                    : <div key="ASDropdown22">{MsgForNoData}</div>
                }

            </div>
            
            <GenericMessageModal 
                selectClientModalState={props.state.selectClientModalState} 
                message={props.state.genericModalMsg} />

        </div>
    )
    return vendorsView
}