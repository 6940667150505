import React, { Component } from 'react'
import { Label } from 'reactstrap';
import 'antd/dist/antd.css';
import { DatePicker, message } from 'antd'
import { dateFormat } from '../../common/constants';
import { DiscretionDistributionRequest, requestApproversList } from './discretionrequest';
import { SubmitDiscritionaryDistribution, UpdateDiscritionaryDistribution, GetEntityInfomation, GetDiscretionaryDistributionInfo, CreateDistributionPdf, CreateDocuSignEnvelopDD, UpdateDiscritionaryDistributionStatus } from '../../services/discritionarydistributionservice/discritionarydistributionservice';
import * as moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { rcmDiscritionaryDistributionFormAnimation } from '../../common/animation';
import OverlayProcessing from '../../common/overlayProcessing';
import DiscretionaryDocumentsList from './documents/discretionarydocumentslist';
import UploadDiscretionaryDistributionDocs from './documents/uploaddiscretionarydistributiondocs';
import DistributionApprover from './distributionApprover';
import { addIncludeIndicatorToList, addIndexToList } from '../../common/utilities';
import * as cloneDeep from 'lodash/cloneDeep';
import DiscretionarydistributionSubmitProgress from './discretionarydistributionSubmitProgress';

class NewDiscretionaryDistributionReqForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            discretionaryRequest: new DiscretionDistributionRequest(),
            defaultDate: moment(),
            showOverlay: false,
            displayCurrentValuation: '',
            displayEstimatedNetIncome: '',
            distributionApproversList: [],
            currentProcess: 0,
            showOverlayProgress: false,
            currentProcessStatus: '',
            trustCompanyApprCheckbox: true
        }

        this.handleFormChanges = this.handleFormChanges.bind(this);
        this.handleRequestDateChange = this.handleRequestDateChange.bind(this);
        this.prepopulateFields = this.prepopulateFields.bind(this);
        this.approverIncludeChange = this.approverIncludeChange.bind(this);
        this.closeShowProgress = this.closeShowProgress.bind(this);
        this.trustCompanyApprChange = this.trustCompanyApprChange.bind(this);
    }

    componentDidMount(){
        rcmDiscritionaryDistributionFormAnimation();
        if(this.props.mode && (this.props.mode === 'UPDATE' || this.props.mode === 'VIEW')){
            this.fetchDistributionInformation();
        }
        else{
            let clonedState = Object.assign({}, this.state);
            clonedState.discretionaryRequest.RequestDate = moment().format(dateFormat);
            this.setState(clonedState);
        }
        
    }

    fetchDistributionInformation(){
        this.setState({showOverlay: true}, () => {
                let distributionId = this.props.selectedDistribution && this.props.selectedDistribution.distributionID ? this.props.selectedDistribution.distributionID : null;
                if(distributionId){
                    GetDiscretionaryDistributionInfo(distributionId).then(data => {
                        if(data){
                            this.setState({showOverlay: false}, () => {
                                this.populateEditableFields(data);
                            })
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        this.setState({showOverlay: false});
                    })
                } 
        })
    }


    handleFormChanges(event){
        let controlType = event.target.type;
        if(controlType === 'text' || controlType === 'textarea' || controlType === 'radio'){
            let clonedState = Object.assign({}, this.state);
            const amountFormat = /^[0-9]+(\.)?([0-9]{1,2})?$/;
            if (event.target.name === "CurrentValuation" || event.target.name === "EstdAnnualNetIncome") {
                if ((event.target.value === "" || amountFormat.test(event.target.value)) && event.target.value.length <= 19) {
                    clonedState.discretionaryRequest[event.target.name] = event.target.value;
                    if(event.target.name === "CurrentValuation"){
                        clonedState.displayCurrentValuation = event.target.value;
                    }

                    if(event.target.name === "EstdAnnualNetIncome"){
                        clonedState.displayEstimatedNetIncome = event.target.value;
                    }
                    this.setState(clonedState);
                }
            }else if(event.target.name === "DistributionAmount"){
                const alphaNumericWithPercentage = /^[0-9a-zA-Z%,$. ]*$/;
                if(alphaNumericWithPercentage.test(event.target.value)){
                    clonedState.discretionaryRequest[event.target.name] = event.target.value;
                    this.setState(clonedState);
                }
            }
            else{
                clonedState.discretionaryRequest[event.target.name] = event.target.value;
                this.setState(clonedState);
            }
        }
    }


    handleRequestDateChange(val){
        if(val){
            let clonedState = Object.assign({}, this.state);
            clonedState.discretionaryRequest.RequestDate = val.format(dateFormat);
            clonedState.defaultDate = moment(val.format(dateFormat), dateFormat);
            this.setState(clonedState);
        } 
    }

    onSave(){
        if(this.props.mode === 'UPDATE'){
            this.updateRequest('SAVE');
        }else{
            this.submitRequest('SAVE');
        }
    }

    onSubmit(){
        if(this.props.mode === 'UPDATE'){
            this.updateRequest('SUBMIT');
        }else{
            this.submitRequest('SUBMIT');
        }    
    }

    onCancel(){
        if(this.props.history){
            this.props.history.push("/discretionarydistribution")
        }
    }

    updateRequest(mode){

        if(this.isApproversIncluded(this.state.distributionApproversList)){

        //let ApprovedText = `${this.state.discretionaryRequest.Trustees}, TRUSTEE, THROUGH ITS TRUST COMMITTEE`;
        let RequestorEmail = this.props.user.userName;

        //cloned object 
        let clonedState = Object.assign({}, this.state);
        //clonedState.discretionaryRequest.ApprovedText = ApprovedText;
        clonedState.discretionaryRequest.RequestorEmail = RequestorEmail;
        clonedState.discretionaryRequest.Approvers = [...requestApproversList(this.state.distributionApproversList)];
        clonedState.showOverlayProgress= true;
        clonedState.currentProcess = 0;
        clonedState.currentProcessStatus = '';

        this.setState(clonedState,  () => {
            UpdateDiscritionaryDistribution(this.state.discretionaryRequest, mode).then(res => {
                let distributionID = res.data;
                this.setState({currentProcess: 1});
                    CreateDistributionPdf(distributionID).then(pdrRes => {
                      if(mode === 'SUBMIT'){
                            this.setState({currentProcess: 2});
                            CreateDocuSignEnvelopDD(distributionID).then(docusignRes => {
                                this.setState({currentProcess: 3});
                                UpdateDiscritionaryDistributionStatus(distributionID, 'PND').then(statusRes => {
                                    this.setState({showOverlayProgress: false, currentProcess: 4}, () => {
                                        message.info('Successfully updated distribution.', 7);
                                        this.props.history.push('/discretionarydistribution');
                                    });
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error('Distribution has been updated but unable to update status', 7);
                                    this.setState({currentProcessStatus: 'error'}, () => {
                                        this.closeShowProgress();
                                    })
                                })
                            })
                            .catch(err => {
                                console.error(err);
                                message.error('Distribution has been updated but unable to create docusign record, you can resubmit/edit anytime.', 7)
                                this.setState({currentProcessStatus: 'error'}, () => {
                                    this.closeShowProgress();
                                })
                            })
                        }
                        else{
                            this.setState({showOverlayProgress: false}, () => {
                                message.info('Distribution has been saved');
                                this.props.history.push('/discretionarydistribution', 7);
                            })
                        }
                    })
                    .catch(err => {

                        console.error(err);
                        message.error('Distribution has been updated but unable to create document for processing, you can resubmit/edit anytime', 7);
                        this.setState({currentProcessStatus: 'error'}, () => {
                            this.closeShowProgress();
                        })
                    })
            })
            .catch(err => {
                console.error(err);
                message.error("We are unable to update distribution, please try again later.", 7);
                this.setState({currentProcessStatus: 'error'}, () => {
                    this.closeShowProgress();
                })
            })
        })
     }else{
         message.warning('Please select atleast one approver.');
     }
    }

    closeShowProgress(){
        setTimeout(() => {
            this.setState({showOverlayProgress: false});
        }, 1000);
    }


    submitRequest(mode){

        if(this.isApproversIncluded(this.state.distributionApproversList)){

        //let ApprovedText = `${this.state.discretionaryRequest.Trustees}, TRUSTEE, THROUGH ITS TRUST COMMITTEE`;
        let RequestorEmail = this.props.user.userName;

        //cloned object 
        let clonedState = Object.assign({}, this.state);
        //clonedState.discretionaryRequest.ApprovedText = ApprovedText;
        clonedState.discretionaryRequest.RequestorEmail = RequestorEmail;
        clonedState.discretionaryRequest.Approvers = [...requestApproversList(this.state.distributionApproversList)];
        clonedState.showOverlayProgress= true;
        clonedState.currentProcess = 0;
        clonedState.currentProcessStatus = '';

        this.setState(clonedState, () => {
            SubmitDiscritionaryDistribution(this.state.discretionaryRequest, mode).then(res => {
                this.setState({currentProcess: 1});
                let distributionID = res.data;
                this.state.discretionaryRequest.DistributionID = distributionID;
                    CreateDistributionPdf(distributionID).then(pdrRes => {
                        if(mode === 'SUBMIT'){

                            this.setState({currentProcess: 2});
                            CreateDocuSignEnvelopDD(distributionID).then(docusignRes => {
                                this.setState({currentProcess: 3});
                                UpdateDiscritionaryDistributionStatus(distributionID, 'PND').then(statusRes => {
                                    this.setState({showOverlayProgress: false, currentProcess: 4}, () => {
                                        message.info('Successfully created distribution.', 7);
                                        this.props.history.push('/discretionarydistribution');
                                    })
                                })
                                .catch(err => {
                                    console.error(err);
                                    message.error('Distribution has been created but unable to update status', 7);
                                    this.setState({currentProcessStatus: 'error'}, () => {
                                        this.closeShowProgress();
                                    })
                                })
                            })
                            .catch(err => {
                                console.error(err);
                                message.error('Distribution has been created but unable to create docusign record, you can resubmit/edit anytime.', 7)
                                this.setState({currentProcessStatus: 'error'}, () => {
                                    this.closeShowProgress();
                                })
                            })
                        }
                        else{
                            this.setState({showOverlayProgress: false, currentProcess: 4}, () => {
                                message.info('Distribution has been saved', 7);
                                this.props.history.push('/discretionarydistribution');
                            })
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        message.error('Distribution has been created but unable to create document for processing, you can resubmit/edit anytime', 7);
                        this.setState({currentProcessStatus: 'error'}, () => {
                            this.closeShowProgress();
                        })
                    })
                
            })
            .catch(err => {
                console.error(err);
                message.error("We are unable to create distribution, please try again later.", 7);
                this.setState({currentProcessStatus: 'error'}, () => {
                    this.closeShowProgress();
                })
            })
        })
     }else{
         //
         message.warning('Please select atleast one approver.');
     }
    }

    

    populateEditableFields(data){
        let clonedState = Object.assign({}, this.state);
            clonedState.discretionaryRequest.DistributionID = data.distributionID ? data.distributionID : '';
            clonedState.discretionaryRequest.TrustTitle = data.trustTitle ? data.trustTitle : '';
            clonedState.discretionaryRequest.EntityNumber = data.entityNumber ? data.entityNumber : '';
            clonedState.discretionaryRequest.Grantor = data.grantor ? data.grantor : '';
            clonedState.discretionaryRequest.Trustees = data.trustees ? data.trustees : '';
            clonedState.discretionaryRequest.TrustCompanyName = data.trustCompanyName ? data.trustCompanyName : '';
            clonedState.discretionaryRequest.TrusteeAction = data.trusteeAction ? data.trusteeAction : '';
            clonedState.discretionaryRequest.Termination = data.remainderProvisions ? data.remainderProvisions : '';
            clonedState.discretionaryRequest.DiscretionaryDistProvision = data.discretionaryDistProvision ? data.discretionaryDistProvision : '';
            clonedState.discretionaryRequest.PrimaryBeneficiaries = data.primaryBeneficiaries ? data.primaryBeneficiaries : '';
            clonedState.discretionaryRequest.CurrentValuation = data.currentValuation ? parseFloat(data.currentValuation) :  '';
            clonedState.discretionaryRequest.EstdAnnualNetIncome = data.estdAnnualNetIncome ? parseFloat(data.estdAnnualNetIncome) : '';
            clonedState.discretionaryRequest.DistributionAmount = data.distributionAmount ?  data.distributionAmount : '';
            clonedState.discretionaryRequest.Termination = data.termination ?  data.termination : '';
            clonedState.discretionaryRequest.RequestedDistribution = data.requestedDistribution ? data.requestedDistribution : '';
            clonedState.discretionaryRequest.Recommendation = data.recommendation ? data.recommendation : '';
            clonedState.discretionaryRequest.SkippingTaxableEvent = data.skippingTaxableEvent ?  data.skippingTaxableEvent : '';
            clonedState.discretionaryRequest.ApprovedText = data.approvedText ? data.approvedText : '';
            clonedState.discretionaryRequest.RequestDate = data.requestDate ? data.requestDate : '';
            clonedState.defaultDate = moment(data.requestDate, dateFormat);
            clonedState.displayCurrentValuation = data.currentValuation ? parseFloat(data.currentValuation).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
            clonedState.displayEstimatedNetIncome = data.estdAnnualNetIncome ? parseFloat(data.estdAnnualNetIncome).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';

            if(data.approvers && Array.isArray(data.approvers)){
                let isPresent  = this.isTrustCompanyApproverPresent(data.approvers);
                this.includeExcludeTrustCompanyAppr(data.approvers, isPresent);
                this.excludeNonEmailAdditionalApprover(data.approvers);
                clonedState.distributionApproversList = [...addIndexToList(data.approvers)];
                clonedState.trustCompanyApprCheckbox = isPresent;
            }else{
                clonedState.distributionApproversList = [];
                clonedState.trustCompanyApprCheckbox = false;
            }

        this.setState(clonedState);
    }

    prepopulateFields(res){
        if(res && res.response_data && Array.isArray(res.response_data) && res.response_data.length > 0){
            let data = res.response_data[0];
            let clonedState = Object.assign({}, this.state);
            
            let ApprovedText = `${data.trustee ? data.trustee : ''}, TRUSTEE, THROUGH ITS TRUST COMMITTEE`;
            clonedState.discretionaryRequest.ApprovedText = ApprovedText;
            clonedState.discretionaryRequest.TrustTitle = data.legalName1 ? data.legalName1 : '';
            clonedState.discretionaryRequest.Grantor = data.grantors ? data.grantors : '';
            clonedState.discretionaryRequest.Trustees = data.trustee ? data.trustee : '';
            clonedState.discretionaryRequest.TrustCompanyName = data.trustCompanyName ? data.trustCompanyName : '';
            clonedState.discretionaryRequest.Termination = data.remainderProvisions ? data.remainderProvisions : '';
            clonedState.discretionaryRequest.DiscretionaryDistProvision = data.distributionProvisions ? data.distributionProvisions : '';
            clonedState.discretionaryRequest.PrimaryBeneficiaries = data.beneficiaryName ? data.beneficiaryName : '';
            clonedState.discretionaryRequest.CurrentValuation = data.currentValuation ? data.currentValuation :  '';
            clonedState.discretionaryRequest.EstdAnnualNetIncome = data.estimatedAnnualNetIncome ? data.estimatedAnnualNetIncome : '';
            clonedState.displayCurrentValuation = data.currentValuation ? parseFloat(data.currentValuation).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
            clonedState.displayEstimatedNetIncome = data.estdAnnualNetIncome ? parseFloat(data.estdAnnualNetIncome).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
            if(data.trustCommitteeSignature && Array.isArray(data.trustCommitteeSignature)){
                let isPresent  = true;
                this.includeExcludeTrustCompanyAppr(data.trustCommitteeSignature, isPresent);
                let lsApproverWithInclude = [...addIncludeIndicatorToList(data.trustCommitteeSignature)];
                this.excludeNonEmailAdditionalApprover(lsApproverWithInclude);
                clonedState.distributionApproversList = [...lsApproverWithInclude];
                clonedState.trustCompanyApprCheckbox = isPresent;
            }else{
                clonedState.trustCompanyApprCheckbox = false;
                clonedState.distributionApproversList = [];
            }

            this.setState(clonedState);
        }
    }

    includeExcludeTrustCompanyAppr(approvers, isPresent){
            if(isPresent){
                for(let i=0; i< approvers.length; i++){
                    if(approvers[i].isTrustCompanyTrustee === 'Y'){
                        approvers[i].indInclude = 'Y';
                    }
                }
            }else{
                for(let i=0; i< approvers.length; i++){
                    if(approvers[i].isTrustCompanyTrustee === 'Y'){
                        approvers[i].indInclude = 'N';
                    }
                }
            }
    }

    isTrustCompanyApproverPresent(approvers){
        let isPresent = false;

            for(let i=0; i< approvers.length; i++){
                if(approvers[i].isTrustCompanyTrustee === 'Y' && approvers[i].indInclude === 'Y'){
                    isPresent = true;
                    break;
                }
            }

         return isPresent   
    }

    excludeNonEmailAdditionalApprover(approvers){
        for(let i=0; i< approvers.length; i++){
            if(approvers[i].isTrustCompanyTrustee !== 'Y' && (approvers[i].emailAddress === null || approvers[i].emailAddress === undefined || approvers[i].emailAddress === '' )){
                approvers[i].indInclude = 'N';
            }
        }
    }

    isApproversIncluded(approvers){
        let isIncluded = false;

        if(approvers && Array.isArray(approvers) && approvers.length > 0){
            for(let i=0; i< approvers.length; i++){
                if(approvers[i].indInclude === 'Y'){
                    isIncluded =  true;
                    break;
                }
            }
        }

        return isIncluded;
    }


    fetchEntityInformation(entityId){

        if(entityId){
        this.setState({showOverlay: true}, () => {
            GetEntityInfomation(entityId).then(res => {
                this.setState({showOverlay: false}, () => {
                    this.prepopulateFields(res);
                })
            })
            .catch(err => {
                console.error(err);
                let clearReq = new DiscretionDistributionRequest();
                clearReq.EntityNumber = this.state.discretionaryRequest.EntityNumber;
                clearReq.RequestDate = this.state.discretionaryRequest.RequestDate ? this.state.discretionaryRequest.RequestDate : '';
                let defaultDate = clearReq.RequestDate ?  moment(clearReq.RequestDate, dateFormat) : moment();
                this.setState({showOverlay: false, discretionaryRequest: clearReq, defaultDate: defaultDate});
            })
        });
      }
    }

    handleAmtOnBlur(event){
        let clonedState = Object.assign({}, this.state);

        if(event.target.name === "CurrentValuation"){

                if(event.target.value){
                    if(!isNaN(event.target.value)){
                        clonedState.discretionaryRequest.CurrentValuation = event.target.value;
                        clonedState.displayCurrentValuation = parseFloat(event.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                    }
                }else{
                    clonedState.discretionaryRequest.CurrentValuation = '';
                    clonedState.displayCurrentValuation = ''
                }
          
        }

        if(event.target.name === "EstdAnnualNetIncome"){
                if(event.target.value){
                    if(!isNaN(event.target.value)){
                        clonedState.discretionaryRequest.EstdAnnualNetIncome = event.target.value;
                        clonedState.displayEstimatedNetIncome = parseFloat(event.target.value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                    }
                }else{
                    clonedState.discretionaryRequest.EstdAnnualNetIncome = '';
                    clonedState.displayEstimatedNetIncome = ''
                }
        }

        if(event.target.name === "DistributionAmount"){
            if(event.target.value){
                clonedState.discretionaryRequest.Recommendation = 
                    `Upon the consideration of ${this.state.discretionaryRequest.Trustees} acting through the Trust Committee of ${this.getCommaSeparatedTrusteeApprovers(this.state.distributionApproversList)} approval of a discretionary distribution in the amount of ${this.state.discretionaryRequest.DistributionAmount} USD for the purposes described above has been recommended as appropriate.`;
            }else{
                clonedState.discretionaryRequest.DistributionAmount = '';
                clonedState.discretionaryRequest.Recommendation = '';
            }
        }

        this.setState(clonedState);
    }

    getCommaSeparatedTrusteeApprovers(approversList){
        let approvers  = '';
        if(approversList && Array.isArray(approversList) && approversList.length > 0){
            for(let i=0; i< approversList.length; i++){
                console.log(approversList[i]);
                if(approversList[i].isTrustCompanyTrustee === 'Y' && approversList[i].fullName){                    
                    approvers = approvers + `${approversList[i].fullName}, `
                }
            }
        }

        console.log(approvers);
        return approvers;
    }

    handleAmtFieldFocus(event){
        let clonedState = Object.assign({}, this.state);

        if(event.target.name === "CurrentValuation"){
            clonedState.displayCurrentValuation = this.state.discretionaryRequest.CurrentValuation;
        }

        if(event.target.name === "EstdAnnualNetIncome"){
            clonedState.displayEstimatedNetIncome = this.state.discretionaryRequest.EstdAnnualNetIncome;
        }
        

        this.setState(clonedState);
    }

    showFileUploader(){
        if(this.props.mode !== 'UPDATE'){
            //call submit api
            //let ApprovedText = `${this.state.discretionaryRequest.Trustees}, TRUSTEE, THROUGH ITS TRUST COMMITTEE`;
            let RequestorEmail = this.props.user.userName;

            //cloned object 
            let clonedState = Object.assign({}, this.state);
            //clonedState.discretionaryRequest.ApprovedText = ApprovedText;
            clonedState.discretionaryRequest.RequestorEmail = RequestorEmail;
            clonedState.discretionaryRequest.Approvers = [...requestApproversList(this.state.distributionApproversList)];
            clonedState.showOverlay= true;

            this.setState(clonedState, () => {
                SubmitDiscritionaryDistribution(this.state.discretionaryRequest, 'SAVE').then(res => {
                    let clonedState = Object.assign({}, this.state);
                    clonedState.discretionaryRequest.DistributionID = res.data;
                    clonedState.showOverlay= false;
                    this.setState(clonedState);
                    message.info('Saved your distribution before allowing to upload additioanl document');
                })
                .catch(err => {
                    message.error('Unable to save your request before allowing to upload additional document');
                    this.setState({showOverlay: false});
                })
            });

        }
    }

    approverIncludeChange(event, approver){
        if(approver){
            let clonedApproverList = cloneDeep(this.state.distributionApproversList);
            for(let i=0; i< clonedApproverList.length; i++){
                if(clonedApproverList[i].uniqueIdx === approver.uniqueIdx){
                    clonedApproverList[i].indInclude = event.target.checked ? 'Y' : 'N';
                    break;
                }
            }
            this.setState({distributionApproversList: clonedApproverList && clonedApproverList.length > 0 ? clonedApproverList : []});
        }
    }

    trustCompanyApprChange(event){
        //include and exclude all trust company approver
        let clonedApproverList = cloneDeep(this.state.distributionApproversList);
        for(let i=0; i< clonedApproverList.length; i++){
            if(clonedApproverList[i].isTrustCompanyTrustee === 'Y'){
                clonedApproverList[i].indInclude = event.target.checked ? 'Y' : 'N';
            }
        }

        this.setState({
            distributionApproversList: clonedApproverList && clonedApproverList.length > 0 ? clonedApproverList : [],
            trustCompanyApprCheckbox: event.target.checked
        });
    }


    render() {
        return (
            <>
                {
                    this.state.showOverlay  && (<OverlayProcessing />)
                }

                {
                    this.state.showOverlayProgress && (<DiscretionarydistributionSubmitProgress currentProcess={this.state.currentProcess} currentProcessStatus={this.state.currentProcessStatus} />)
                }

                <div className="container RCM_Discr_form_container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Label className="RCM_Discr_form_main_heading">
                                MEMORANDUM TO TRUSTEE
                            </Label>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6" align="right">
                            <Label>
                                DATE: &nbsp; <DatePicker value={moment(this.state.defaultDate)} format={dateFormat} showToday={false} onChange={this.handleRequestDateChange}/>
                            </Label>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12 col-md-12 col-lg-12" align="center">
                            <Label className="RCM_Discr_form_main_heading">REQUEST FOR DISCRETIONARY DISTRIBUTION</Label>
                        </div>
                    </div>
                    <div className="RCM_Discr_form mt-4">
                        <div className="row">
                            
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Entity No.
                                </Label>
                                <input type="text" className="RCM_form_control" name="EntityNumber" value={this.state.discretionaryRequest.EntityNumber} 
                                    maxLength={16}
                                    autoComplete="off" 
                                    onChange={this.handleFormChanges}
                                    disabled={(this.props.mode === 'UPDATE' || this.props.mode === 'VIEW' ) ? true : false } 
                                    onBlur={() => this.fetchEntityInformation(this.state.discretionaryRequest.EntityNumber)}>
                                </input>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Trust Title:
                                </Label>
                                <input type="text" className="RCM_form_control" name="TrustTitle" 
                                    value={this.state.discretionaryRequest.TrustTitle} onChange={this.handleFormChanges} 
                                    maxLength={256}
                                    autoComplete="off" 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true}
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Current Valuation:
                                </Label>
                                <input type="text" className="RCM_form_control" name="CurrentValuation" 
                                    maxLength={17}
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true}
                                    onBlur={(e) => this.handleAmtOnBlur(e)}
                                    onFocus={(e) => this.handleAmtFieldFocus(e)}  
                                    value={this.state.displayCurrentValuation} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Estimated Annual Net Income:
                                </Label>
                                <input type="text" className="RCM_form_control" name="EstdAnnualNetIncome" 
                                maxLength={17}
                                value={this.state.displayEstimatedNetIncome}
                                disabled={this.state.discretionaryRequest.EntityNumber ? false : true}
                                onBlur={(e) => this.handleAmtOnBlur(e)} 
                                onFocus={(e) => this.handleAmtFieldFocus(e)}
                                onChange={this.handleFormChanges} autoComplete="off"></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Grantor:
                                </Label>
                                <input type="text" className="RCM_form_control" name="Grantor" 
                                    maxLength={128}
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.Grantor} onChange={this.handleFormChanges} autoComplete="off"></input>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Trustee(s):
                                </Label>
                                <input type="text" className="RCM_form_control" name="Trustees" 
                                    maxLength={128}
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.Trustees} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Label className="RCM_Discr_form_label_heading">
                                    Primary Beneficiary(ies):
                                </Label>
                                <input type="text" className="RCM_form_control" name="PrimaryBeneficiaries"
                                    maxLength={128} 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.PrimaryBeneficiaries} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Termination/Remainderman:
                                </Label>
                                <textarea className="RCM_form_control RCM_form_control_textarea" rows="6" cols="50" name="Termination" 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.Termination} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                 Discretionary Distribution Provision: 
                                </Label>
                                <textarea className="RCM_form_control RCM_form_control_textarea" rows="6" cols="50" name="DiscretionaryDistProvision" 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.DiscretionaryDistProvision} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Distribution Amount:
                                </Label>
                                <input type="text" className="RCM_form_control" name="DistributionAmount" 
                                    maxLength={64}
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true}
                                    value={this.state.discretionaryRequest.DistributionAmount} 
                                    onChange={this.handleFormChanges}
                                    onBlur={(e) => this.handleAmtOnBlur(e)}  
                                    autoComplete="off"  />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                Requested or Proposed Distribution:
                                </Label>
                                <textarea className="RCM_form_control RCM_form_control_textarea" rows="6" cols="50" name="RequestedDistribution" 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.RequestedDistribution} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Recommendation:
                                </Label>
                                <textarea className="RCM_form_control RCM_form_control_textarea" rows="6" cols="50" name="Recommendation" 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    value={this.state.discretionaryRequest.Recommendation} onChange={this.handleFormChanges} autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <Label className="RCM_Discr_form_label_heading">
                                    Is this distribution a Generation Skipping taxable event ?
                                </Label>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <span className="RCM_Discr_radio_container">
                                    <input type="radio" name="SkippingTaxableEvent" id="skiptaxable_yes" value="Y" onChange={this.handleFormChanges} checked={this.state.discretionaryRequest.SkippingTaxableEvent === 'Y' ? true : false} /><label htmlFor="skiptaxable_yes">Yes</label>
                                    <input type="radio" name="SkippingTaxableEvent" id="skiptaxable_no" value="N" onChange={this.handleFormChanges} checked={this.state.discretionaryRequest.SkippingTaxableEvent === 'N' ? true : false} /><label htmlFor="skiptaxable_no">No</label>
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Label className="RCM_Discr_form_label_heading">
                                    Trustee(s) Action:
                                </Label>
                                <input type="text" className="RCM_form_control"
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true}
                                    onChange={this.handleFormChanges}
                                    name="TrusteeAction"
                                    value={this.state.discretionaryRequest.TrusteeAction} autoComplete="off" />
                            </div>
                        </div>
                        {
                             this.props.mode !== 'VIEW' 
                             && this.state.discretionaryRequest.EntityNumber
                             && this.state.discretionaryRequest.DistributionID 
                             && (
                                <UploadDiscretionaryDistributionDocs 
                                    distributionID={this.state.discretionaryRequest && this.state.discretionaryRequest.DistributionID ? this.state.discretionaryRequest.DistributionID : ''} 
                                    userEmail={this.props.user && this.props.user.userName ? this.props.user.userName : ''} />
                            )
                        }
                        {
                            this.state.discretionaryRequest.EntityNumber && (
                                <div className="row">
                                    <div className="col-sm12 col-md-12 col-lg-12" >
                                        { this.props.mode !== 'VIEW' && (!this.state.discretionaryRequest.DistributionID)
                                            && <div align="right"><button type="button" className="RCM_billpay_btn" style={{position:'relative', top: '1rem', cursor: 'pointer', zIndex: 1}} onClick={() => this.showFileUploader()} >ADD DOCUMENT</button></div> }
                                        <DiscretionaryDocumentsList
                                            mode={this.props.mode}
                                            distributionID={this.state.discretionaryRequest.DistributionID} />
                                    </div>
                                </div>
                            )
                        }
                        
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Label className="RCM_Discr_form_label_heading">
                                    APPROVED:
                                </Label>
                                <input type="text" className="RCM_form_control" 
                                    disabled={this.state.discretionaryRequest.EntityNumber ? false : true} 
                                    onChange={this.handleFormChanges}
                                    name="ApprovedText"
                                    value={this.state.discretionaryRequest.ApprovedText ? this.state.discretionaryRequest.ApprovedText : ''} autoComplete="off"/>
                            </div>
                        </div>
                        

                        {
                            this.state.distributionApproversList.filter(appr => appr.isTrustCompanyTrustee === 'Y').length > 0 && (
                                <div className="mt-1">
                                    <div style={{ display: 'flex' }}>
                                        <label className="checkbox_container RCM_discretion_distribution_form_checkbox">
                                            <input type="checkbox"
                                                onChange={this.trustCompanyApprChange}
                                                checked={this.state.trustCompanyApprCheckbox === true ? true : false}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <Label className="RCM_tile_heading RCM_payments_toast_heading RCM_discretion_distribution_form_checkbox">TRUST COMPANY TRUSTEE APPROVER:</Label>
                                    </div>
                                    {
                                        this.state.distributionApproversList.filter(appr => appr.isTrustCompanyTrustee === 'Y').map((approver, index) => {
                                            return (
                                                <DistributionApprover
                                                    approver={approver}
                                                    key={index}
                                                    approverIncludeChange={(e) => this.approverIncludeChange(e, approver)}
                                                    isTrustCompanyTrustee={'Y'} />
                                            );
                                        })
                                    }
                                </div>
                            )
                        }
                        
                        {
                            this.state.distributionApproversList.filter(appr => appr.isTrustCompanyTrustee !== 'Y').length > 0 && (
                                <div className="mt-1">
                                    <Label className="RCM_tile_heading RCM_payments_toast_heading">ADDITIONAL APPROVERS:</Label>
                                    {
                                        this.state.distributionApproversList.filter(appr => appr.isTrustCompanyTrustee !== 'Y').map((approver, index) => {
                                            return (
                                                <DistributionApprover
                                                    approver={approver}
                                                    key={index}
                                                    approverIncludeChange={(e) => this.approverIncludeChange(e, approver)}
                                                    isTrustCompanyTrustee={'N'} />
                                            );
                                        })
                                    }
                                </div>
                            )
                        }
                        

                        
                        </div>
                        <div className="row mt-3">
                            {
                                this.props.mode !== 'VIEW' && (
                                    <div className="col-sm-12 col-md-12 col-lg-12" align="right">
                                        <button type="button" className="btn btn-btn RCM_button_secondary"   onClick={() => this.onCancel()}>CANCEL</button> &nbsp;
                                        <button type="button" className="btn btn-btn RCM_button_secondary" disabled={this.state.discretionaryRequest.EntityNumber ? false : true}  onClick={() => this.onSave()}>SAVE DRAFT</button> &nbsp;
                                        <button type="button" className="btn btn-btn RCM_button_primary" disabled={this.state.discretionaryRequest.EntityNumber ? false : true}  onClick={() => this.onSubmit()}>SEND FOR SIGNATURE</button>
                                    </div>
                                )
                            }
                            
                        </div>

                        
                </div>
             </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        user : store.app.user,
        mode: store.discretionaryDistribution.mode,
        selectedDistribution: store.discretionaryDistribution.selectedDistribution
    };
}

const mapDispatchToProps = {
    
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewDiscretionaryDistributionReqForm));
