import React from 'react';

export function getRuleGridColumn(editRule, add){
    const column = [
        {
            dataField: 'rulename',
            text: 'Rule Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'institutionname',
            text: 'Institution Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: 'sourcecoaaccountno',
            text: 'Source Main Account #',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                return <div>{cell}</div>;
            }
        },
        {
            dataField: '',
            text: 'Condition',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                let renderText = row.condition !== null ? "Transaction description " + row.condition +" " + row.description + ' then':'';
                return <div>{renderText} </div>;
            }
        },
        {
            dataField: '',
            text: 'Action',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                
                let payeeText = !row.isexclude
                    ? ("Post to " + (row.coaaccountno !== '' ? "Main Account: " + row.coaaccountno + " " : '')
                    + (row.subaccountid !== '' ? "Sub Account: " + (row.subaccountno !== "" ? row.subaccountno : row.subaccountid) + " ": '')
                        + (row.vendorid !== '' ? "Payee " + (row.vendorname !== "" ? row.vendorname : row.vendorid) : ''))
                    : '';
                let text = row.isrename ? (' And rename the discription to this ' + row.renameddesc + '.') : '';
                return <div>{payeeText + ' ' + text} </div>;
            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            headerClasses: 'RCM_noWrap',
            headerFormatter: (cell, row) => {
                return (<div align="right" className="RCM_icon_hand  RCM_manage_grp_new_group" onClick={() => add()} >+ Add New</div>)
            },
            formatter: (cell, row) => {
                    return(<div align="right">
                        <button type="button" className="RCM_billpay_btn" onClick={() => editRule(row)}>EDIT</button>
                        </div>);
            }
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

