import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { MsgForNoData } from '../../../common/constants';
import { getAgreementListColumn } from './agreementListColumns';
import { BackTop, message } from 'antd';
import { Spinner } from 'reactstrap';
import { GetPaymentListService, GetPaymentListServiceCancellationToken } from '../../../services/billpayservice/billpayservices';
import { getNestedObject } from '../../../common/common';
import { FetchLOADocument, fetchLOADocumentCancellationToken, updatePaymentAccountServiceCancellationToken } from '../../../services/paymentAgreementService/paymentAgreementService';
import PaymentAccountPopup from './paymentAccountPopup';
import PdfViwerModal from '../../../common/pdfViwerModal';
import { useBoolean } from '../../../common/hooks'
import PaymentAgreementLoaUploadPopup from './paymentAgreementLoaUploadPopup';

const PaymentAgreement = (props) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    const user = useSelector(state => getNestedObject(state, ['app', 'user']));
    const [pdfdata, setPdfdata] = useState('');
    const [isOpenPaymentsAccountPopup, setIsOpenPaymentsAccountPopup] = useState(false);
    const {val: isLoaViewerOpen, toggle: toggleLoaViewer} = useBoolean(false)
    const {val: isLoaUploadModalOpen, toggle: toggleLoaUploadModal } = useBoolean(false)

    const addViewLOA = (row, source) => {
        setSelectedClient(row);
        if (source === 'view') {
            getPDFData(row);
            toggleLoaViewer()
        }

        if(source === 'add') {
            toggleLoaUploadModal()
        }
    }

    useEffect(() => {
        if(!isLoaViewerOpen) {
            setPdfdata('')
        }
    }, [isLoaViewerOpen])

    useEffect(() => {
        fetchClientList();
        return () => {
            if (GetPaymentListServiceCancellationToken !== null && GetPaymentListServiceCancellationToken !== undefined) {
                GetPaymentListServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (fetchLOADocumentCancellationToken !== null && fetchLOADocumentCancellationToken !== undefined) {
                fetchLOADocumentCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
            if (updatePaymentAccountServiceCancellationToken !== null && updatePaymentAccountServiceCancellationToken !== undefined) {
                updatePaymentAccountServiceCancellationToken.cancel("Cancelling eligible pair request on unmount to prevent memory leak.");
            }
        }
    }, []);
    const getPDFData = row => {
        let reqJson = {
            "ClientId": row.pKid,
            "isforpaymentloa": true
        }
        FetchLOADocument(reqJson).then(doc => {
            setPdfdata(doc);
        })
            .catch(err => {
                console.error(err);
                message.error("Unable to fetch client LOA document.");
            });
    }
    const fetchClientList = () => {
        setShowOverlay(prevShowOverlay => true);
        GetPaymentListService(user).then(res => {
            if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                setClientList(res.data);
            }

            setShowOverlay(prevShowOverlay => false);
        })
            .catch(err => {
                message.error("Unable to fetch User List.");
                console.error("Unable to fetch User List.");
            })
    }
    const openPaymentsAccountPopup = (row) => {
        setIsOpenPaymentsAccountPopup(prevIsOpenPaymentsAccountPopup => true);
        setSelectedClient(row);
    }
    const togglePaymentsAccountModelPopup = () => {
        setIsOpenPaymentsAccountPopup(prevIsOpenPaymentsAccountPopup => !prevIsOpenPaymentsAccountPopup);
        setSelectedClient({});
    }
    
    return (
        <>
            <BackTop />
            {showOverlay
                ? <div align="center" className="mt-4" key='PA001'>Loading Payment Agreements
                  &nbsp;<Spinner animation="grow" size="sm" variant="info" key='PA002'></Spinner>
                </div>
                :
                <div className="RCM_Toast RCM_subAccount_toast" key='PA003'>
                    <div className="row mt-1" key='PA004'>
                        <div className="col-sm-12 col-md-12 col-lg-12" key='PA005'>
                            <BootstrapTable
                                keyField='rcmUserId'
                                data={clientList}
                                columns={getAgreementListColumn(addViewLOA, openPaymentsAccountPopup)}
                                headerClasses="row-bg"
                                bordered={false}
                                classes="RCM_two_level_table1"
                                noDataIndication={MsgForNoData}
                                key='PA006'
                            />
                        </div>
                    </div>
                </div>
            }
            <PaymentAccountPopup
                isOpenPaymentsAccountPopup={isOpenPaymentsAccountPopup}
                togglePaymentsAccountModelPopup={togglePaymentsAccountModelPopup}
                fetchClientList={fetchClientList}
                selectedClient={selectedClient}
                message={message}
            />
            {  selectedClient && selectedClient.orgId && selectedClient.orgId !== null && selectedClient.orgId &&
               <>
                    <PdfViwerModal pdfdata={pdfdata ? pdfdata.paymentloadoc : ''} toggleModal={toggleLoaViewer} modalState={isLoaViewerOpen} >
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">{selectedClient && selectedClient.name}: View Client LOA/Agreement</div>
                        </div>
                    </PdfViwerModal>

                    <PaymentAgreementLoaUploadPopup 
                        toggleModal={toggleLoaUploadModal} 
                        modalState={isLoaUploadModalOpen} 
                        selectedClient={selectedClient} 
                        fetchClientList={fetchClientList}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">{selectedClient && selectedClient.name}: Upload Client LOA/Agreement</div>
                        </div>
                    </PaymentAgreementLoaUploadPopup>
               </> 
            }
        </>
    )

};

export default PaymentAgreement;