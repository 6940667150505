import React from 'react'
import { useState } from 'react'
import { Route, Switch, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import {Tabs,} from 'antd'
import Notfound from '../../common/notfound';
import WiresList from '../admin/wiresdetails/wireslist'
import FailedPayments from '../admin/failedpayments/failedpayments'
import DashBoard from './DashBoard'
import { useCallback } from 'react';
import { useEffect } from 'react';

const { TabPane } = Tabs;

function DashBoardContainer() {
    let history = useHistory()
    let { url } = useRouteMatch()
    let { pathname } = useLocation()
    const [activeTabKey, setActiveTabKey] = useState('dashboard')

    const onTabChanges = useCallback((tabKey) => {
        const newlocation =  tabKey === "dashboard" ? "/dashboard" : `${url}/${tabKey}`
        history.push(newlocation)
        setActiveTabKey(tabKey)
    }, [])

    useEffect(() => {
        let tabKey = ''
        if(pathname === url) {
            history.push('/dashboard')
            tabKey = 'dashboard'
        }
        else if(pathname === `${url}/payments`) {
            tabKey = 'payments'
        }
        else if(pathname === `${url}/failedPayments`) {
            tabKey = 'failedPayments'
        }
        setActiveTabKey(tabKey)
    }, [pathname])


    return (
        <div>
            <Tabs activeKey={activeTabKey} animated={false} onChange={onTabChanges} >
                    <TabPane tab="DASHBOARD" key="dashboard">

                    </TabPane>
                    <TabPane tab="PAYMENTS" key="payments">

                    </TabPane>
                    <TabPane tab="PAYMENTS FOR ACTION" key="failedPayments" >

                    </TabPane>
                </Tabs>
                {
                    <Switch>
                        <Route exact path={"/dashboard"} component={() => (<DashBoard id="dashboard" ActiveKey={activeTabKey} />)} />
                        <Route exact path={`${url}/payments`} component={() => (<WiresList id="paymentlist" ActiveKey={activeTabKey} />)} />
                        <Route exact path={`${url}/failedPayments`} component={() => (<FailedPayments id="failedpayments" ActiveKey={activeTabKey} />)} />
                        <Route path="*" component={Notfound} />
                    </Switch>
                }
        </div>
    )
}

export default DashBoardContainer
