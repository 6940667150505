

export const MsgForNoData = 'No data found';
export const errorMsg = "We could not process your request at this time. Please try again or contact your Advisor for assistance.";
export const MsgForFailedData = "Failed to fetch data, please try again.";
export const NoClientFound = "You need to choose client from dashboard to view vendors list.";
export const NoClientFoundSetupBill = "Please select client from dashboard to setup bill.";
export const UnAuthorizedClientAccess = "You do not have access to this client, please select valid client from dashboard.";

export const MessageType = {
    'SUCCESS': 'Success',
    'ERROR': 'Error',
    'INFO': 'Info',
    'WARNING': 'Warning',
    'LABEL':'Label',
    'PREFERENCE_WARNING':'PREFERENCE_WARNING'
    
}

export const MessageClass = {
    'SUCCESS': 'alert alert-success alert-dismissible fade show',
    'ERROR': 'RCM_validation_error_text',
    'INFO': 'alert alert-info alert-dismissible fade show',
    'WARNING': 'alert alert-warning alert-dismissible fade show',
    'LABEL':"",
    'PREFERENCE_WARNING':''
}

export const dateFormat = "MM/DD/YYYY";
export const alphanumericFormat = /^[a-zA-Z 0-9'/]+$/;

export function arrayFilterByValue(array, string) {
    let filteredArr = [];

    if (array && Array.isArray(array) && array.length > 0) {
        filteredArr = array.filter(o => {
            return Object.keys(o).some(k => {
                if (o[k] !== null && o[k] !== undefined && typeof o[k] === 'string')
                    return o[k].toLowerCase().includes(string.toLowerCase());
            });
        });
    }


    return filteredArr;
}
export const validateEmailId = (emailid) => {
    var isValid = true;
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;
    if (emailid && pattern.test(emailid)) {
        isValid = true;
    }
    else {
        isValid = false;
    }
    return isValid;
}

export const getDateString = (date, format = 'mm/dd/yyyy') => {

    let newDate = (date instanceof Date) ? date : new Date(date);
    var dd = newDate.getDate();
    var mm = newDate.getMonth() + 1;

    var yyyy = newDate.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var returnString = '';
    if (format === 'yyyy-mm-dd') {
        returnString = yyyy + '-' + mm + '-' + dd;
    } else {
        returnString = mm + '/' + dd + '/' + yyyy;
    }
    return returnString;
}

export const basicAlphaNumeric = /^[a-zA-Z0-9 -]+$/;
