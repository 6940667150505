export class DiscretionDistributionRequest{
    DistributionID = '';
    RequestDate = '';
    TrustTitle = '';
    EntityNumber = '';
    CurrentValuation = '';
    EstdAnnualNetIncome = '';
    DistributionAmount = '';
    Grantor = '';
    Trustees = '';
    TrustCompanyName = '';
    TrusteeAction = 'The Trustee approves the recommendation set forth above.';
    PrimaryBeneficiaries = '';
    Termination = '';
    DiscretionaryDistProvision = '';
    RequestedDistribution = '';
    Recommendation = '';
    SkippingTaxableEvent = '';
    ApprovedText = '';
    Approvers = [];
}

class DistributionApprover{
    EmailAddress = '';
    FullName = '';
    FirstName=  '';
    MiddleName = '';
    LastName = '';
    IsTrustCompanyTrustee ='';
    SignatureOrder= '';
    IndInclude = '';
}


export function requestApproversList(approvers){
    let list = [];
    if(approvers && Array.isArray(approvers) && approvers.length > 0){
        //convert the request to approvers list
        for(let i=0; i< approvers.length; i++){
            let distributionAppr = new DistributionApprover();
            distributionAppr.EmailAddress = approvers[i].emailAddress;
            distributionAppr.FullName = approvers[i].fullName;
            distributionAppr.IndInclude = (approvers[i].emailAddress) ? approvers[i].indInclude === 'Y' ? 'Y' : 'N' : 'N';
            distributionAppr.IsTrustCompanyTrustee = approvers[i].isTrustCompanyTrustee;
            distributionAppr.SignatureOrder = approvers[i].signatureOrder;
            distributionAppr.FirstName = approvers[i].firstName;
            distributionAppr.MiddleName = approvers[i].middleName;
            distributionAppr.LastName = approvers[i].lastName;

            list.push(distributionAppr);
        }
    }

    return list;
}