import React from 'react';
import { Menu, Dropdown } from 'antd';
import {store} from '../../index'

const { SubMenu } = Menu;


function openWindow(_url, matrixItem) {
    let url = _url;
    if(matrixItem){
        url = _url.replace('[$cmp$]', matrixItem.dynamicsLegalEntity)
    }
    window.open(url, 'menuwindow', 'toolbar=0,location=0,menubar=0,width=' + window.screen.availWidth + ",height=" + window.screen.availHeight);
}

function formatMenuItems(items, matrixItem) {
   return (
     <Menu>
       <Menu.ItemGroup title="Navigations">
         <SubMenu title={`Dynamics`}>
             <Menu.Item onClick={() => openWindow(store.getState().app.env.REACT_APP_DYNAMICS_ACCOUNTING_URL)}>Accounting 365</Menu.Item>
             {
                 matrixItem && matrixItem.dynamicsLegalEntity &&
                    items.map(item => {
                    return <Menu.Item onClick={() => openWindow(item.url, matrixItem)}>{item.feature}</Menu.Item>
                    })
             }
         </SubMenu>
       </Menu.ItemGroup>
       <Menu.Item>Marketing Material</Menu.Item>
       <Menu.Item onClick={() => openWindow(store.getState().app.env.REACT_APP_REPORTING_URL)}>Reporting</Menu.Item>
       <Menu.Item>Trading</Menu.Item>
     </Menu>
   );
}

 export function ContextualMenu({items, matrixItem}) {
    return (
      <div
        style={{
          color: "aqua",
          cursor: "pointer",
          letterSpacing: "0.3rem",
          paddingLeft: "0.3rem",
        }}
      >
        <Dropdown overlay={() => formatMenuItems(items, matrixItem)}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <b>...</b>
          </a>
        </Dropdown>
      </div>
    );
 }


export function getDashBoardViewGridColumn(viewInvoice, uploadInvoice, goToVendors, goToPayments, openInboxWindow, goToSubAccounts, contextualMenu){
    const column = [
        {
            dataField: '',
            text: '',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: false,
            formatter: (cell, row) => {
                return <ContextualMenu items={contextualMenu} matrixItem={row}/>
            }
        },
        {
            dataField: 'name',
            text: 'Client Name',
            headerClasses: 'RCM_noWrap',
            headerAlign: 'left',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (row.deeplinkInbox)
                    return (<div onClick={() => openInboxWindow(row.deeplinkInbox, row.ssOlinkInbox)} className="RCM_icon_hand RCM_Table_link">
                                {cell}
                            </div>);
                else
                    return <div>{cell}</div>;
            }
        },
        {
            dataField: 'inboxCnt',
            text: 'Inbox',
            headerClasses: 'RCM_noWrap',
            //classes: 'RCM_common_table_column_text RCM_common_table_column_text_right',
            classes: 'RCM_common_table_column_number',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (row.deeplinkInbox)
                    return (<div onClick={() => openInboxWindow(row.deeplinkInbox, row.ssOlinkInbox)} className="RCM_icon_hand">
                                {cell}
                            </div>);
                else
                    return <div>{cell}</div>;
            }
        },
        {
            dataField: 'pendingApprovalCnt',
            text: 'Approvals',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (row.deeplinkBill)
                    return (<div onClick={() => openInboxWindow(row.deeplinkBill, row.ssOlinkBill)} className="RCM_icon_hand">
                                {cell}
                            </div>);
                else
                    return <div>{cell}</div>;
            }
        },
        {
            dataField: 'pendingPayCnt',
            text: 'Payments',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number ',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (row.noOfPaymentsFailed > 0)
                {
                    if(row.deeplinkPay){
                        return( <div><span onClick={() => openInboxWindow(row.deeplinkPay, row.ssOlinkPay)} className="RCM_icon_hand">{cell}</span>
                             <span class="RCM_payments_link" onClick={() => goToPayments(row)}>&nbsp;{row.noOfPaymentsFailed} Failed</span>
                        </div>);
                    }else{
                        return (
                            <div>{cell}<span class="RCM_payments_link" onClick={() => goToPayments(row)}>&nbsp;{row.noOfPaymentsFailed} Failed</span></div>
                       );
                    }
                }  
                else{
                    if(row.deeplinkPay){
                       return(<div onClick={() => openInboxWindow(row.deeplinkPay, row.ssOlinkPay)} className="RCM_icon_hand">{cell}</div>);
                    }else{
                        return <div>{cell}</div>;
                    }
                }
                    
            },
        },
        {
            dataField: 'scheduledPayCnt',
            text: 'Schedule Payment',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (row.deeplinkBill)
                    return (<div onClick={() => openInboxWindow(row.deeplinkBill, row.ssOlinkBill)} className="RCM_icon_hand">
                                {cell}
                            </div>);
                else
                    return <div>{cell}</div>;
            }
        },
        {
            dataField: 'partialPayCnt',
            text: 'Payment Due Soon',
            headerClasses: 'RCM_noWrap',
            classes: 'RCM_common_table_column_number',
            headerAlign: 'right',
            sort: true,
            sortCaret: (order, column) => customCaretSort(order, column),
            formatter: (cell, row) => {
                if (row.deeplinkBill)
                    return (<div onClick={() => openInboxWindow(row.deeplinkBill, row.ssOlinkBill)} className="RCM_icon_hand">
                                {cell}
                            </div>);
                else
                    return <div>{cell}</div>;
            }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            isDummyField: true,
            headerAlign: 'right',
            formatter: (cell, row) => {
                    return(<div align="right">
                            <button type="button" className="RCM_billpay_btn" onClick={() => uploadInvoice(row)}>UPLOAD INVOICES</button>
                        <button type="button" className="RCM_billpay_btn" onClick={() => goToVendors(row)}>MANAGE VENDORS</button>
                        {/*<button type="button" className="RCM_billpay_btn" onClick={() => goToSubAccounts(row)}>MANAGE SUB ACCOUNTS</button>*/}
                        </div>);
            }
        }
    ]

    return column;
}
function customCaretSort(order, column) {
    if (!order) return (<span className="RCM_Sort_arrow_icon"></span>);
    else if (order === 'asc') return (<span className="RCM_Sort_arrow_down_icon"></span>);
    else if (order === 'desc') return (<span className="RCM_Sort_arrow_up_icon"></span>);
    return null;
}

