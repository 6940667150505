import React  from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';

export default class PdfViwerModal extends React.PureComponent {

    constructor(props){
        super(props);
        this.modalHeight = window.innerHeight - 200;
    }


    render() {

        return (
            
            <div>
                 <Modal
                    isOpen={this.props.modalState}
                    toggle={this.props.toggleModal}
                    backdrop={false}
                    size='extralg'
                    zIndex='9999'
                    className="RCM_CM_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content"
                    centered>
                    <ModalHeader toggle={this.props.toggleModal} charCode="X" ></ModalHeader>
                    <ModalBody>
                        <>
                        <div>DOCUMENT VIEWER</div>
                        {
                            this.props.children
                        }
                        {
                            this.props.pdfdata ?
                            <iframe src={`data:application/pdf;base64, ${this.props.pdfdata}`} 
                                height={this.modalHeight} width="100%" 
                                frameBorder="0" title="Pdf Viewer" className="mt-2" />
                            : <div align="center" className="mt-4">Loading Document
                                &nbsp;<Spinner animation="grow" size="sm" variant="info" key='PA002'></Spinner>
                              </div>
                        }
                        </>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

