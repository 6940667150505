import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { VendorsView } from './vendorsView.jsx';
import { searchVendorList } from '../../services/vendorService/vendorService.js';
import { ViewWireInstructions, UpdateClientVendorsList } from '../../actions/vendorsAction';
import {UPDATE_SSO_WINDOW_INSTANCE} from '../../actions/appActions';
import HowToPayModal from './howToPayModal.jsx';
import { animateViewTable } from '../../common/animation.js';
import { arrayFilterByValue } from '../../common/utilities.js';
import { Spinner } from 'reactstrap';
import { BackTop, message } from 'antd';
import { NoClientFound } from '../../common/constants.js';

export class Vendors extends Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.state = {
            openUploadInvoice: false,
            openVendors: false,
            vendorList: [],
            originalVendorList: [],
            searchText: '',
            howToPayModalState: false,
            showloading: false,
            selectClientModalState: false,
            genericModalMsg: ''
        };

        this.vendorId = (this.props.selectedClient && this.props.selectedClient.orgId ? this.props.selectedClient.orgId: '');

        this.fetchVendorList = this.fetchVendorList.bind(this);
    }

    componentDidMount = () => {
        //call get vendor api
        
        if(this.vendorId  === ''){
            //show modal for select client 
            this.setState({selectClientModalState: true, genericModalMsg: NoClientFound});
        }else{
            this.fetchVendorList();
        }

    }

    componentDidUpdate(prevProps){
        if (prevProps.refresh !== this.props.refresh) {
            this.fetchVendorList();
        }
    }

    fetchVendorList(){
        this.setState({showloading: true}, () => {
            //gsap.to('.RCM_two_level_table1', {duration: 0 , opacity: 0});
            searchVendorList(this.vendorId).then(res => {
                if (res && Array.isArray(res) && res.length > 0) {
                    this.setState({ vendorList: res, originalVendorList: res, showloading: false }, () => {
                        animateViewTable();
                        this.props.UpdateClientVendorsList(res);
                    });
                }else{
                    this.setState({showloading: false}, () => {
                        animateViewTable();
                    })
                }
            })
            .catch(err => {
                this.setState({showloading: false, vendorList: [], originalVendorList: []}, () => {
                    animateViewTable();
                    message.error('We are unable to pull vendors list, you can retry by using refresh option.');
                })
                console.log(err);
            })
        })
    }


    search = (evt) => {
        let searchString = evt.target.value;
        var updatedVendorList = [...this.state.originalVendorList];
        let searchKeyList = ['name', 'payBy', 'addressCity', 'addressState', 'addressZip', 'accNumber'];
        updatedVendorList = arrayFilterByValue(updatedVendorList, searchString, searchKeyList);
        this.setState({ vendorList: updatedVendorList, searchText: searchString });
    }
    backFromVendors() {
        if (this.props.history) {
            this.props.history.push('/');
        }
    }

    openWireInstruction(row) {
        //put the row value in store
        this.props.ViewWireInstructions(row, 'UPDATE').then(afterSettingWireInstruction => {
            if (this.props.history) {
                this.props.history.push('/setupbills');
            }
        });
    }

    viewInvoice(row) {
        //put the invoice value in store
    }

    addVendor() {
        this.payUsingOtherPayment();
    }

    payUsingWire(){
        this.props.ViewWireInstructions(null, 'ADD').then(afterSettingWireInstruction => {
            if (this.props.history) {
                this.props.history.push('/setupbills');
            }
        });
    }

    payUsingOtherPayment(){

        //ge the url from environment variables.
        let url =  this.props.env && this.props.env.REACT_APP_OTHER_PAYMENT_METHOD_URL ? this.props.env.REACT_APP_OTHER_PAYMENT_METHOD_URL  : '';
        let windowObj = window.open(url, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width='+window.screen.availWidth+",height="+window.screen.availHeight);
        if(windowObj){
            windowObj.focus();
        }

        this.setState({howToPayModalState: false});
    }

    toggleHowToPayAcceptModal(){
        this.setState({howToPayModalState: !this.state.howToPayModalState});
    }

    openBilldotcomVendor(row){
        //console.log(row);
        //ge the url from environment variables.

        let windowObj = this.props.ssoWindow;

        if(windowObj){
            if (windowObj.closed){
                let url = row && row.billdotComSso ?  row.billdotComSso : '';
                windowObj = window.open(url, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width='+window.screen.availWidth+",height="+window.screen.availHeight);
                this.props.UPDATE_SSO_WINDOW_INSTANCE(windowObj);
            }
            else{
                let url = row && row.deepLink ?  row.deepLink : '';
                windowObj = window.open(url, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width='+window.screen.availWidth+",height="+window.screen.availHeight);
                this.props.UPDATE_SSO_WINDOW_INSTANCE(windowObj);
            }
       }else{
            let url = row && row.billdotComSso ?  row.billdotComSso : '';
            windowObj = window.open(url, 'billpaywindow', 'toolbar=0,location=0,menubar=0,width='+window.screen.availWidth+",height="+window.screen.availHeight);
            this.props.UPDATE_SSO_WINDOW_INSTANCE(windowObj);
       }

       
        if(windowObj){
            windowObj.focus();
        }
    }


    render() {

        if(this.state.showloading){
            return (<div align="center" className="mt-4">Loading Vendors List  &nbsp;<Spinner animation="grow" size="sm" variant="info" ></Spinner></div>)
        }
        else{
            return(
                <>
                    <BackTop></BackTop>
                    <VendorsView
                        state={this.state}
                        backFromVendors={() => this.backFromVendors()}
                        openWireInstruction={(row) => this.openWireInstruction(row)}
                        openBilldotcomVendor={(row) => this.openBilldotcomVendor(row)}
                        addVendor={() => this.addVendor()}
                        viewInvoice={(row) => this.viewInvoice(row)}
                        selectedClient={this.props.selectedClient}
                        search={this.search}
                    />

                    <HowToPayModal 
                        howToPayModalState={this.state.howToPayModalState} 
                        toggleHowToPayAcceptModal={() => this.toggleHowToPayAcceptModal()}
                        payUsingWire={() => this.payUsingWire()}
                        payUsingOtherPayment={() => this.payUsingOtherPayment()}
                    />
                </>
            );
        }
    }

}
const mapStateToProps = (store) => {
    return {
        selectedClient: store.dashboard.selectedClient,
        refresh: store.app.refresh,
        ssoWindow: store.app.ssoWindow,
        env:  store.app.env
    };
}

const mapDispatchToProps = {
    ViewWireInstructions,
    UpdateClientVendorsList,
    UPDATE_SSO_WINDOW_INSTANCE
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Vendors));

