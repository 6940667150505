import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';

const ConfirmationPopup = ({ openConfirmationModelPopup, toggleConfirmationModelPopup, confirmationMessage, confirmationPopupSource, confirmAction}) => {

    return (
        <>
            <Modal isOpen={openConfirmationModelPopup} toggle={toggleConfirmationModelPopup}
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                zIndex='9999'
                centered >
                <ModalHeader toggle={toggleConfirmationModelPopup} className="RCM_externalAssetModel_header RCM_manage_group_header" key="ConfirmationModelPopup01">

                    <label className="RCM_tile_heading" key="ConfirmationModelPopup02">CONFIRMATION</label>
                </ModalHeader>
                <ModalBody key="ConfirmationModelPopup03">

                    <div key="ConfirmationModelPopup04"><Label for="groupname" key="ConfirmationModelPopup05">{confirmationMessage}</Label></div>

                </ModalBody>
                <ModalFooter className="RCM_manage_group_footer" key="ConfirmationModelPopup06">
                    <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={() => toggleConfirmationModelPopup(confirmationPopupSource)} key="ConfirmationModelPopup07">CANCEL</button>
                    <button type="button" className="btn btn-primary RCM_button_primary" onClick={() => confirmAction(confirmationPopupSource)} key="ConfirmationModelPopup08">CONFIRM</button>
                </ModalFooter>
            </Modal>
            
        </>
    )
}
export default ConfirmationPopup;