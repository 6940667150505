import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
//import { routerReducer, routerMiddleware } from 'react-router-redux';
import { AppReducer } from "../reducers/appReducer";
import { VendorsReducer } from "../reducers/vendorsReducer";
import { DashBoardReducer } from '../reducers/dashboardReducer';
import { AdminClientVisibilityReducer } from '../reducers/adminClientVisibilityReducer';
import { DiscretionaryDistributionReducer } from '../reducers/discretionaryDistributionReducer';
import { AccountingReducer } from '../reducers/accountingReducer';
export default function configureStore(history, initialState) {
    const reducers = {
        "app": AppReducer,
        "vendors": VendorsReducer,
        "dashboard": DashBoardReducer,
        "adminClientVisibility": AdminClientVisibilityReducer,
        "discretionaryDistribution": DiscretionaryDistributionReducer,
        "accounting": AccountingReducer
    };

    const middleware = [
        thunk,
        //routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined') {
        if(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
            {   
                enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
            };
    }

    const rootReducer = combineReducers({
        ...reducers,
        //routing: routerReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
