import React from 'react';
import { Form, FormGroup } from "reactstrap";

const VendorAddEdit = ({ mode, handleFormChanges, activeVendor, cancel, save }) => {

    return (
        <>
            {
                mode ? <div>
                    <div className="row">
                        {
                            mode === "edit"
                                ?
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading">
                                    Edit Payee - {activeVendor.name}
                                </div>
                                :
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading">
                                    Add New Payee
                                                </div>
                        }

                    </div>
                    <div>
                        <Form>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label>Payee Name</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="name"
                                            className="RCM_form_control"
                                            value={activeVendor.name}
                                            onChange={evt => handleFormChanges(evt)}

                                        />
                                    </FormGroup>
                                </div>
                                
                                {/*<div className="col-sm-6 col-xs-12 col-md-8 col-lg-8">
                                   <FormGroup>
                                        <label>Description</label>
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            placeholder=""
                                            name="description"
                                            className="RCM_form_control"
                                            value={activeVendor.description}
                                            onChange={evt => handleFormChanges(evt)}
                                        />
                                    </FormGroup>
                                </div>*/}
                                
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12" align="right">
                                    <button type="button" className="btn btn-btn RCM_button_secondary RCM_accounting_btn" onClick={() => cancel()}>CANCEL</button> &nbsp;
                                    <button type="button" className="btn btn-btn RCM_button_primary RCM_accounting_btn" onClick={() => save()}>
                                        {mode === "edit" ? 'SAVE' : 'ADD'}
                                    </button>
                                </div>
                            </div>

                        </Form>
                    </div>
                   
                </div>
                    :
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 RCM_subAccounts_subHeading">
                            Please select a payee
                        </div>
                    </div>

            }
        </>
    )
}
export default VendorAddEdit;