import { UPDATE_SELECTED_SUB_ACCOUNT } from "./actionTypes";

export const UpdateSelectedSubAccount = (row) => (dispatch) => {
    return Promise.resolve(
        dispatch({
            type: UPDATE_SELECTED_SUB_ACCOUNT,
            payload: row,
        })
    )
}

//export const SetContextKey = (key) => (dispatch) => {
//    return Promise.resolve(
//        dispatch({
//            type: SET_CONTEXT_KEY,
//            payload: key,
//        })
//    )
//}