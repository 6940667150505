import { UPDATE_CLIENT_VISIBILITY_SELECTED_USER } from "../actions/actionTypes";

const initialState = {
    selectedUser: null,
}

export function AdminClientVisibilityReducer(state=initialState, action){

    switch(action.type)
    {

        case UPDATE_CLIENT_VISIBILITY_SELECTED_USER:
        {
            return {
                ...state, 
                selectedUser : action.payload,
            }
        }

        default:
        return state;
    }
}