import moment from "moment";
import React from "react";
import { formatInCurrency } from "../../../common/utilities";

export function getFailedPaymentColumns(retryFailedPayment) {
  const column = [
    {
      dataField: "dueDate",
      text: "Due Date",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      sortFunc: (a, b, order) => {
        try {
          if (order === "asc") {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b); // desc
        } catch (err) {
          console.error(err);
          if (order === "asc") {
            return b - a;
          }
          return a - b;
        }
      },
      formatter: (cell, row) => {
        return <div align="left">{cell ? moment(cell).format("L") : "-"}</div>;
      },
    },
    {
      dataField: "legalEntityId",
      text: "Entity Number",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
    },
    {
      dataField: "clientName",
      text: "Client",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
    },
    {
      dataField: "vendorName",
      text: "Vendor Name",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
    },
    {
      dataField: "paymentItems",
      text: "Amount",
      headerClasses: "RCM_noWrap",
      headerAlign: "right",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b[0].amount - a[0].amount;
        }
        return a[0].amount - b[0].amount; // desc
      },
      formatter: (cell, row) => {
        return (
          <div align="right">
            {row &&
            row.paymentItems &&
            Array.isArray(row.paymentItems) &&
            row.paymentItems.length > 0
              ? formatInCurrency(row.paymentItems[0].amount)
              : "-"}
          </div>
        );
      },
    },
    {
      dataField: "paynmentType",
      text: "Payment Type",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
    },
    {
      dataField: "statusCode",
      text: "Approval Status",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
    },
    {
      dataField: "statusDesc",
      text: "Description",
      headerClasses: "RCM_noWrap",
      sort: true,
      sortCaret: (order, column) => customCaretSort(order, column),
    },
    {
      dataField: "nextActions",
      text: "Action",
      headerClasses: "RCM_noWrap",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div>
            {cell &&
              Array.isArray(cell) &&
              cell[0].actionCode &&
              cell[0].actionCode !== "NOUI" && (
                <input
                  type="button"
                  className="RCM_billpay_btn"
                  value={`${cell[0].actionName}`}
                  onClick={() => retryFailedPayment(row)}
                />
              )}
          </div>
        );
      },
    },
  ];

  return column;
}
function customCaretSort(order, column) {
  if (!order) return <span className="RCM_Sort_arrow_icon"></span>;
  else if (order === "asc")
    return <span className="RCM_Sort_arrow_down_icon"></span>;
  else if (order === "desc")
    return <span className="RCM_Sort_arrow_up_icon"></span>;
  return null;
}
