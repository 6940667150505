import React from 'react'
import 'antd/dist/antd.css';
import { Upload, Icon } from 'antd';
import { UploadPrivateEquityFile } from '../../services/privateEquityService/privateEquityService';
const { Dragger } = Upload;

function UploadPrivateEquityView({email}) {

    const uploadProps = {
        name: 'file',
        accept:'.xlsx',
        multiple: false,
        customRequest: ({ onSuccess, onError, onProgress, file }) => onFileUpload({ onSuccess, onError, onProgress, file }),
        onChange: (info) => onFileUploadStatusChange(info),
        listType: 'text'
      };
     

    const onFileUpload = ({ onSuccess, onError, onProgress, file }) => {

        UploadPrivateEquityFile(file, email, (progressEvent) => onUploadProgress(progressEvent, file, onProgress)).then(uploadRes => {
            console.log('upload response', uploadRes);
            onSuccess(null, file);
          })
          .catch(err => {
            console.log('file upload error', err);
            onError();
        })
        
    }
    
    const onUploadProgress = (progressEvent, file, onProgress) => {
        onProgress({ percent: Math.round(progressEvent.loaded / progressEvent.total * 100).toFixed(2) }, file)
      }

    const onFileUploadStatusChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
          //do something 
        }
        else if (status === 'done') {
          //do something for done

        } else if (status === 'error') {
          //do something for error
          console.error(info);
        }
      }
      

    return (
        <div>
            <div className="RCM_Toast">
                <div className="row">
                    <div className="col-sm-12 RCM_tile_heading RCM_payments_toast_heading">
                         UPLOAD PRIVATE EQUITIES
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                            
                        <Dragger {...uploadProps} className="RCM_File_Drop">
                            <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                            <p className="ant-upload-text">Click or drag file(s) to this area to upload private equities </p>
                        </Dragger>

                    </div>

                </div>
            </div>    
        </div>
    )
}

export default UploadPrivateEquityView;
